import { useEffect, useState } from "react";

export const ResponsiveImage = ({ src, alt, ...props }) => {
  const [isLandscape, setIsLandscape] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setIsLandscape(img.width > img.height);
    };
  }, [src]);

  return (
    <img
      src={src}
      alt={alt}
      style={{
        maxHeight: "90vh",
        maxWidth: "90vw",
        width: isLandscape ? "100%" : "auto",
        height: isLandscape ? "auto" : "100%",
        display: "block",
      }}
      {...props}
    />
  );
};
