import { Button } from "@mui/material";
import { FilterChip, FilterStack } from "./styled";
import { TABLE_BUTTON_OBJ } from "./constants";

const defaultRejectPrefix = "not ";

export const AppliedFilters = ({
  appliedFilters,
  handleRemoveFilter,
  handleClearFilters,
}) => {
  const hasAnyValidFilter = appliedFilters.some((filter) => filter);
  return (
    <FilterStack direction="row" spacing={1}>
      <div className="filters_container">
        {appliedFilters.map((filter, idx) => {
          return (
            filter &&
            filter.value !== null && (
              <FilterChip
                key={idx}
                label={`${filter.name}: ${
                  filter?.value === 0
                    ? filter.rejectPrefix || defaultRejectPrefix
                    : " "
                }${filter.valueName}`}
                onDelete={() => handleRemoveFilter(filter.queryKey)}
                variant="outlined"
              />
            )
          );
        })}
      </div>
      {appliedFilters?.length > 0 && hasAnyValidFilter && (
        <Button
          variant="outlined"
          onClick={handleClearFilters}
          sx={{ whiteSpace: "nowrap", height: "fit-content" }}
        >
          {TABLE_BUTTON_OBJ.clearAll}
        </Button>
      )}
    </FilterStack>
  );
};
