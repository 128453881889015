import { useEffect, useState } from "react";

import { CONSTANTS, EXPLANATION } from "constants/general";
import * as DOMPurify from "dompurify";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

export const ExplanationsView = ({ content, type }) => {
  const [cleanDescription, setCleanDescription] = useState();

  useEffect(() => {
    if (type === CONSTANTS.TEXT_KEY && content) {
      setCleanDescription(
        DOMPurify.sanitize(content, { USE_PROFILES: { html: true } })
      );
    }
  }, [content, type]);

  return (
    <div className="explanation_field">
      <div className="explanation_type title">{`${EXPLANATION} ${type}`}</div>
      <div className="explanation_content">
        {type === CONSTANTS.TEXT_KEY && (
          <div dangerouslySetInnerHTML={{ __html: cleanDescription }}></div>
        )}
        {type === CONSTANTS.IMAGE_KEY && (
          <img
            src={`${image_url}${content}`}
            className="explanation_file"
            alt="Explanation"
          />
        )}
        {type === CONSTANTS.VIDEO_KEY && (
          <video
            src={`${image_url}${content}`}
            className="explanation_file"
            alt="Explanation"
            controls
          />
        )}
        {type === CONSTANTS.AUDIO_KEY && (
          <audio controls className="explanation_file">
            <source src={`${image_url}${content}`} alt="Explanation"></source>
          </audio>
        )}
      </div>
    </div>
  );
};
