import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import clsx from "clsx";

import { TextField } from "@mui/material";

import { SEARCH_KEY } from "constants/general";

import searchIcon from "assets/images/search.svg";
import usePrevious from "hooks/usePrevious";

const HeaderSearch = () => {
  const [search, setSearch] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const previousPathname = usePrevious(pathname);

  const query = new URLSearchParams(useLocation().search);
  const searchParam = query.get(SEARCH_KEY);

  const { headerSearchHover } = useSelector((state) => state.header);

  const timeoutRef = useRef();

  const updateSearch = (e) => {
    e.preventDefault();
    setIsTyping(true);
    const { value } = e.target;

    setSearch(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      query.set("search", value);
      navigate(`?${query.toString()}`);
    }, 300);
  };

  useEffect(() => {
    if (searchParam && !isTyping) {
      const decodedSearchParam = decodeURIComponent(searchParam);
      setSearch(decodedSearchParam);
    }
  }, [searchParam, isTyping]);

  useEffect(() => {
    if (pathname !== previousPathname && !!previousPathname) {
      setSearch("");
    }
  }, [pathname]);

  return (
    <form onSubmit={updateSearch} className="header-search-wrapper">
      <div
        className={clsx("search-wrapper", {
          "is-active": headerSearchHover,
        })}
      >
        <label className="icon-wrapper" htmlFor="header-search-input">
          <img src={searchIcon} alt="Search" className="search-icon" />
        </label>
        <TextField
          value={search}
          onChange={updateSearch}
          id="header-search-input"
          name="header-search-input"
          type="search"
          placeholder="Search terms..."
          variant="outlined"
        />
      </div>
    </form>
  );
};

export default HeaderSearch;
