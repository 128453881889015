import React from "react";
import { CompareArrows } from "@mui/icons-material";
import { QUESTION_POSITION } from "constants/general";
import { sanitize } from "dompurify";

export const PairingAnswers = ({ answers }) => {
  const left = answers.filter(
    (answer) => answer.position === QUESTION_POSITION.LEFT
  );
  const right = answers.filter(
    (answer) => answer.position === QUESTION_POSITION.RIGHT
  );

  const getPairedAnswer = (id) =>
    right.find((answer) => answer.id === id)?.answer;

  const getHighlight = (str) => {
    const htmlText = str?.replaceAll("{{", "<b>")?.replaceAll("}}", "</b>");
    return { __html: sanitize(htmlText) };
  };

  return (
    <div className="answer_container">
      {left?.map((item, i) => (
        <div key={item.id} className="pairing_answer_container">
          <span
            className="answer mr-1"
            dangerouslySetInnerHTML={getHighlight(`${i + 1}. ${item.answer}`)}
          ></span>
          <CompareArrows />
          <span
            className="answer mr-1"
            dangerouslySetInnerHTML={getHighlight(
              getPairedAnswer(item.pairedId)
            )}
          ></span>
        </div>
      ))}
    </div>
  );
};
