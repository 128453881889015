import { Button, CircularProgress, Tab, Tabs, TextField } from "@mui/material";
import { StatusLabel } from "components/StatusLable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_STATUS } from "service/constants";

import { styled } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CONSTANTS } from "constants/general";
import { formErrorHelper } from "helpers/formikValidation";
import { Close } from "@mui/icons-material";
import {
  addTestCount,
  attachSubscriptionPlan,
  getSubscriptionPlans,
} from "actions/usersActions";
import FilterHelpers from "helpers/filter";

const StyleTextField = styled(TextField)(() => ({
  width: "100px",
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  width: "24px !important",
  height: "24px !important",
  marginLeft: "5px",
  color: "white",
}));

const imageBase = process.env.REACT_APP_API_IMAGE_URL;
const NUMBER_REGEX = /[^0-9]/g;

export const AddSubscriptionPlans = ({
  setOpen,
  quizType,
  userId,
  page,
  search,
  sort,
  appliedFilters,
}) => {
  const subscriptionPlans = useSelector(
    (state) => state.users.subscriptionPlans
  );
  const userSubscriptionPlans = useSelector(
    (state) => state.users.userSubscriptionPlans
  );
  const plansLoading = useSelector((state) => state.users.subscriptionsLoading);

  const showPlans = subscriptionPlans.filter(
    (elem) => elem.quiz_type === quizType
  );

  const isLoading = useSelector((state) => state.users.actionLoading);

  const [value, setValue] = useState(showPlans[0]?.id ?? 0);

  const dispatch = useDispatch();

  const { appliedFiltersToParams } = FilterHelpers();
  const appliedFilterParams = appliedFiltersToParams(appliedFilters);

  const initialValues = {
    user_id: userId,
    plan_id: "",
    count: "",
  };

  const validationSchema = Yup.object().shape({
    user_id: Yup.number().required(CONSTANTS.REQUIRED),
    plan_id: Yup.number().required(CONSTANTS.REQUIRED),
    count: Yup.string().required(CONSTANTS.REQUIRED),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        addTestCount({
          data: values,
          navigate: handleClose,
          params: { page, search, ...sort, ...appliedFilterParams },
        })
      );
    },
  });

  const handleCountChange = (e, planId) => {
    let value = e.target.value;

    if (value !== " " && value !== "0" && value.length < 3) {
      value = value.replace(NUMBER_REGEX, "");
      formik.setFieldValue("count", value);
      formik.setFieldValue("plan_id", planId);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActivatePlan = (planId) => {
    dispatch(
      attachSubscriptionPlan({
        data: { user_id: userId, plan_id: planId },
        navigate: handleClose,
        params: { page, search, ...sort, ...appliedFilterParams },
      })
    );
  };

  const handleChange = (event, newValue) => {
    formik.setFieldValue("count", "");
    formik.setFieldValue("plan_id", "");
    formik.setErrors({});
    formik.setTouched({});
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getSubscriptionPlans());
  }, []);

  return (
    <div className="subscription_plans">
      {plansLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className="cancel_button">
            <Button onClick={() => setOpen(false)}>
              <Close />
            </Button>
          </div>
          <div>
            <Tabs value={value} onChange={handleChange} centered>
              {showPlans?.map((plan) => (
                <Tab
                  label={plan.title}
                  value={plan.id}
                  key={plan.id}
                  disabled={quizType !== plan.quiz_type}
                />
              ))}
            </Tabs>

            <div className="plan_section">
              {showPlans?.map((plan) => {
                const userActivePlan = userSubscriptionPlans?.find(
                  (elem) => elem.id === plan.id
                );
                return (
                  <div
                    key={plan.id}
                    className={
                      plan.id === value ? "plan_container" : "hide_plan_body"
                    }
                  >
                    {userActivePlan && (
                      <div className="active_label">
                        <StatusLabel
                          className="completed_label hover_label"
                          onClick={() => {}}
                          name={USER_STATUS.active_arm}
                        ></StatusLabel>
                      </div>
                    )}
                    <div className="plan_icon">
                      <img
                        src={`${imageBase}/${plan?.icon}`}
                        width={100}
                        height={100}
                        alt="Plan"
                        className="image"
                      />
                    </div>
                    <div className="plan_name">
                      <p className="name">{plan?.title}</p>
                    </div>
                    <div className="line" />
                    <div className="plan_description">
                      <div className="description_item">
                        <p className="description_text">{`${plan?.question_count} հարց`}</p>
                      </div>
                      <div className="description_item">
                        <p className="description_text">{`${
                          userActivePlan
                            ? `${
                                userActivePlan.pivot.test_count +
                                userActivePlan.pivot.test_count_by_admin
                              }/`
                            : ""
                        }${
                          userActivePlan
                            ? plan?.test_count +
                              userActivePlan?.pivot?.total_test_count_by_admin
                            : plan?.test_count
                        } թեստ`}</p>
                      </div>
                    </div>
                    {userActivePlan ? (
                      <form
                        className="choose_button_content"
                        onSubmit={formik.handleSubmit}
                      >
                        <StyleTextField
                          id="count"
                          name="count"
                          label="Count"
                          variant="outlined"
                          type="text"
                          size="small"
                          value={formik.values.count}
                          onChange={(event) =>
                            handleCountChange(event, plan?.id)
                          }
                          error={formErrorHelper({
                            formik,
                            elementName: "count",
                            isBoolean: true,
                          })}
                        />
                        <button
                          disabled={isLoading}
                          type="submit"
                          className="choose_button"
                        >
                          {isLoading ? <StyledCircularProgress /> : "Add"}
                        </button>
                      </form>
                    ) : (
                      <button
                        disabled={isLoading}
                        className="activate_button"
                        onClick={() => handleActivatePlan(plan?.id)}
                      >
                        {isLoading ? <StyledCircularProgress /> : "Activate"}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
