import instance from "./baseRequest";

export const departmentApi = {
  getTopics: async (search) =>
    await instance.get("/admin/topics", {
      params: {
        search,
      },
    }),
  getTopicById: async (id) => await instance.get(`/admin/topics/${id}`),
  createTopic: async (body) => await instance.post("/admin/topics", body),
  updateTopic: async (body, id) =>
    await instance.post(`/admin/topics/${id}`, body),
  deleteTopic: async (id) => await instance.delete(`/admin/topics/${id}`),
};
