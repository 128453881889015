import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const questionSchema = [
  {
    id: "media",
    name: "media",
    label: CONSTANTS.IMAGE,
    type: "mediaFile",
    placeholder: CONSTANTS.ENTER_IMAGE,
    backend_field: "media",
    validation: Yup.array().of(Yup.string()),
    input_name: CONSTANTS.MULTIMEDIA_CONTENT,
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().nullable(),
    input_name: CONSTANTS.DESCRIPTION,
  },
  {
    id: "topic",
    name: "topic",
    label: CONSTANTS.ENTER_SECTION,
    type: "select",
    placeholder: CONSTANTS.ENTER_SECTION,
    backend_field: "topic",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.SECTION_NAME,
  },
  {
    id: "subject",
    name: "subject",
    label: CONSTANTS.SUBJECT,
    type: "select",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "subject",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.SUBJECT_NAME,
  },
  {
    id: "category_id",
    name: "category_id",
    label: CONSTANTS.PRIVATE_SUBJECT,
    type: "select",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "category_id",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.PRIVATE_SUBJECT_NAME,
  },
  {
    id: "level",
    name: "level",
    label: CONSTANTS.LEVEL,
    type: "select",
    placeholder: CONSTANTS.ENTER_LEVEL,
    backend_field: "level",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.LEVEL_NAME,
  },
  {
    id: "type",
    name: "type",
    label: CONSTANTS.TYPE,
    type: "select",
    placeholder: CONSTANTS.ENTER_TYPE,
    backend_field: "type",
    validation: Yup.string(),
    input_name: CONSTANTS.TYPE_NAME,
  },
  {
    id: "question",
    name: "question",
    label: CONSTANTS.QUESTION_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.QUESTION_DESCRIPTION,
    backend_field: "question",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.QUESTION_NAME,
  },
  {
    id: "answers",
    name: "answers",
    label: CONSTANTS.ITEMS,
    type: "questionArray",
    placeholder: CONSTANTS.ENTER_ITEMS,
    backend_field: "answers",
    validation: Yup.array(),
    input_name: CONSTANTS.ANSWERS_NAME,
    labels: {
      answer: "Write down the option",
      position: "Position",
      is_correct: "Is Correct",
      order: "Order",
    },
  },
  {
    id: "explanations",
    name: "explanations",
    label: CONSTANTS.EXPLANATION_TYPE,
    type: "file",
    placeholder: CONSTANTS.EXPLANATION_TYPE,
    backend_field: "explanations",
    validation: Yup.object().nullable().shape({
      type: Yup.string(),
      content: Yup.string(),
    }),
    input_name: CONSTANTS.EXPLANATION_FILE,
  },
];

export const fixedQuizQuestionSchema = [
  {
    id: "media",
    name: "media",
    label: CONSTANTS.IMAGE,
    type: "mediaFile",
    placeholder: CONSTANTS.ENTER_IMAGE,
    backend_field: "media",
    validation: Yup.array().of(Yup.string()),
    input_name: CONSTANTS.MULTIMEDIA_CONTENT,
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().nullable(),
    input_name: CONSTANTS.DESCRIPTION,
  },
  {
    id: "topic",
    name: "topic",
    label: CONSTANTS.ENTER_SECTION,
    type: "select",
    placeholder: CONSTANTS.ENTER_SECTION,
    backend_field: "topic",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.SECTION_NAME,
  },
  {
    id: "subject",
    name: "subject",
    label: CONSTANTS.SUBJECT,
    type: "select",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "subject",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.SUBJECT_NAME,
  },
  {
    id: "type",
    name: "type",
    label: CONSTANTS.TYPE,
    type: "select",
    placeholder: CONSTANTS.ENTER_TYPE,
    backend_field: "type",
    validation: Yup.string(),
    input_name: CONSTANTS.TYPE_NAME,
  },
  {
    id: "question",
    name: "question",
    label: CONSTANTS.QUESTION_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.QUESTION_DESCRIPTION,
    backend_field: "question",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    input_name: CONSTANTS.QUESTION_NAME,
  },
  {
    id: "answers",
    name: "answers",
    label: CONSTANTS.ITEMS,
    type: "questionArray",
    placeholder: CONSTANTS.ENTER_ITEMS,
    backend_field: "answers",
    validation: Yup.array(),
    input_name: CONSTANTS.ANSWERS_NAME,
    labels: {
      answer: "Write down the option",
      position: "Position",
      is_correct: "Is Correct",
      order: "Order",
    },
  },
  {
    id: "explanations",
    name: "explanations",
    label: CONSTANTS.EXPLANATION_TYPE,
    type: "file",
    placeholder: CONSTANTS.EXPLANATION_TYPE,
    backend_field: "explanations",
    validation: Yup.object().nullable().shape({
      type: Yup.string(),
      content: Yup.string(),
    }),
    input_name: CONSTANTS.EXPLANATION_FILE,
  },
];
