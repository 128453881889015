import React, { useState, memo } from "react";
import { Delete, UploadFile } from "@mui/icons-material";
import { isVideo } from "utiles";
import { IconFullScreen } from "components/icons";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

const ImageSection = ({
  imageArray,
  setImageArray,
  handleAddFile,
  handleDeleteArrayItem,
  handleClickFullScreen,
}) => {
  const [currentCard, setCurrentCard] = useState();

  const dragStartHandler = (e, value) => {
    setCurrentCard(value);
  };
  const dragLeaveHandler = (e) => {
    e.target.style.opacity = "1";
  };
  const dragEndHandler = (e) => {
    e.target.style.opacity = "1";
  };
  const dragOverHandler = (e) => {
    e.preventDefault();
    e.target.style.opacity = "0.5";
  };
  const dropHandler = (e, value) => {
    e.preventDefault();
    e.target.style.opacity = "1";
    const updatedImageArray = [...imageArray];
    const dragIndex = imageArray.indexOf(currentCard);
    const dropIndex = imageArray.indexOf(value);
    const removedElement = updatedImageArray.splice(dragIndex, 1);
    updatedImageArray.splice(dropIndex, 0, ...removedElement);
    setImageArray(updatedImageArray);
  };

  return (
    <div className="array_wrapper_image">
      <label className="create_edit_image" htmlFor="upload_file">
        <UploadFile />
      </label>
      <input
        id="upload_file"
        name="upload_file"
        type="file"
        multiple="multiple"
        className="create_edit_image_input"
        onChange={(event) => handleAddFile(event, imageArray?.length)}
      />
      <div className="uploaded_images_field">
        {imageArray?.map((value, index) => (
          <div
            onDragStart={(e) => dragStartHandler(e, value)}
            onDragLeave={(e) => dragLeaveHandler(e)}
            onDragEnd={(e) => dragEndHandler(e)}
            onDragOver={(e) => dragOverHandler(e)}
            onDrop={(e) => dropHandler(e, value)}
            draggable
            className="image_wrapper"
            key={index}
          >
            {!!value && (
              <div className="uploaded_image_wrapper">
                <video
                  className="uploaded_image"
                  poster={
                    !isVideo(value)
                      ? typeof value === "string"
                        ? `${image_url}${value}`
                        : `${URL.createObjectURL(value)}#t=0.1`
                      : null
                  }
                  src={
                    typeof value === "string"
                      ? `${image_url}${value}`
                      : `${URL.createObjectURL(value)}#t=0.1`
                  }
                ></video>
              </div>
            )}
            <div className="delete_image_wrapper">
              <Delete
                className="delete_icon"
                onClick={() => handleDeleteArrayItem(index)}
              />
              <div
                onClick={() => handleClickFullScreen(index)}
                className="full_screen_icon_wrapper"
              >
                <IconFullScreen />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps?.imageArray?.length === nextProps?.imageArray?.length;
};

export default memo(ImageSection, areEqual);
