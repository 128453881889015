import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { questionSchema, fixedQuizQuestionSchema } from "./questionSchema";
import { getTopicById, getTopics } from "actions/departmentAcions";
import {
  getCategoriesByTopicId,
  getCategoriesByParentId,
} from "actions/categoryActions";
import { FILTER_CONSTANTS } from "../../pages/Questions/components/constants";
import { v4 as uuidv4 } from "uuid";

import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Button,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Tooltip,
} from "@mui/material";

import {
  CONSTANTS,
  TABLE_MIN_PAGE,
  QUESTION_TYPES,
  QUESTION_LEVELS,
  QUESTION_TYPES_VALUE,
  QUESTIONS_HINT_TEXT,
  ANSWERS,
  TOPIC_KEY,
  SUBJECT_KEY,
  CATEGORY_KEY,
  LEVEL_KEY,
  TYPE_KEY,
  QUESTION_KEY,
  MIN_LENGTH_ANSWERS,
  CATEGORY_ID_KEY,
  EXPLANATION,
  DESCRIPTION_KEY,
  MEDIA_KEY,
  HELPER_TEXT,
} from "constants/general";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { InfiniteScrollAutocomplete } from "components";
import { SaveButton } from "components/Button";
import { BackIcon, DeleteIcon } from "components/icons";
import { useNavigate, useParams } from "react-router";
import { FORM_ITEM_TYPES } from "constants/pageName";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { CompareArrows } from "@mui/icons-material";
import { clearQuestionById } from "features/questionsSlice";

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";
import CardDrag from "components/dragComponent";
import { StyledInputAdornment } from "pages/About/styled";
import {
  BUTTON_NAMES_OBJ,
  FORM_ITEM_NAMES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import {
  createQuestion,
  editQuestion,
  getQuestionById,
} from "actions/questionsActions";
import ModalWindow from "components/ModalWindow";
import { answersCheck, isEmptyAllAnswers } from "helpers/answersCheck";
import {
  checkMultipleAnswers,
  removeCorrectChecked,
  setDataValue,
  setEmptyValues,
  typeChangedHelper,
} from "helpers/questionTypesHelper";
import { getCategoriesById } from "actions/categoryActions";
import {
  answersArray,
  answersMatchingArray,
  answersPairingArray,
} from "./constants";
import { deleteHtmlFromString } from "helpers/deleteHtmlFromString";
import { questionFormData } from "helpers/formikDataCollect";
import { ExplanationContent } from "./components/explanationContent";
import { ImageArrayInput } from "./ImageArrayInput";

export const CreateEdit = ({
  isCreate,
  isFixedQuiz,
  isDiagnosticQuiz,
  topic_id,
  category_id,
  isGenerated,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const topics = useSelector((state) => state.topics.data);
  const topicById = useSelector((state) => state.topics.topic);
  const categoryById = useSelector((state) => state.category.categoryById);
  const categories = useSelector((state) => state.category.categoriesByTopic);
  const categoriesByParent = useSelector(
    (state) => state.category.categoriesByParentId
  );
  const editContent = useSelector((state) => state.questions.question);

  const savedData = JSON.parse(localStorage.getItem(QUESTION_KEY));

  const [editorValue, setEditorValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [privateSubjects, setPrivateSubjects] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(TABLE_MIN_PAGE);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [items, setItems] = useState(
    answersMatchingArray.map((value) => ({
      id: uuidv4(),
      value: value.answer,
      canDrag: true,
    }))
  );
  const [activeId, setActiveId] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredDragItem, setHoveredDragItem] = useState(null);
  const [isDragActive, setIsDragActive] = useState(true);
  const [type, setType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [changeType, setChangeType] = useState(false);
  const [typeValue, setTypeValue] = useState("");
  const [confirmTypeChange, setConfirmTypeChange] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showExplanations, setShowExplanations] = useState(false);
  const [imageArray, setImageArray] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasChangedManuallyRef = useRef(null);

  const { id, quiz_id } = useParams();

  const questionValidationSchema = isFixedQuiz
    ? fixedQuizQuestionSchema
    : questionSchema;

  const [initialValues, validation] = formikValidation(
    questionValidationSchema,
    false,
    type
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);

      const checkAnswers = answersCheck(
        values.type,
        values.answers,
        setShowModal
      );

      if (checkAnswers && !isConfirmed) {
        setShowModal(checkAnswers);
        setIsSubmitting(false);
        return;
      }

      if (isDiagnosticQuiz && !isFixedQuiz) {
        values.diagnostic_quiz_id = quiz_id;
      }

      if (isFixedQuiz) {
        values.fixed_quiz_id = quiz_id;
        values.quiz_type = "fixed";
        values.level = "medium";
      }

      if (checkAnswers) {
        values.answers = [];
      }

      if (!values.description) {
        values.description = "";
      }

      const explanations = [];

      if (deleteHtmlFromString(editorValue).trim() !== "") {
        explanations.push({ type: CONSTANTS.VALID_TEXT, content: editorValue });
      }
      if (values.explanations.content) {
        explanations.push(values.explanations);
      }

      const data = { ...values, explanations };
      const formData = questionFormData(data);

      const localData = {
        topic: values.topic,
        subject: values.subject,
        category_id: values.category_id,
        level: values.level,
      };

      localStorage.setItem(QUESTION_KEY, JSON.stringify(localData));

      try {
        if (isCreate) {
          await dispatch(createQuestion({ data: formData, navigate }));
        } else {
          await dispatch(editQuestion({ id, data: formData, navigate }));
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const fetchCategoriesByTopicId = () => {
    dispatch(
      getCategoriesByTopicId({
        id: selectedTopic,
        params: {
          page,
          quiz_type: isGenerated ? "generated" : null,
        },
      })
    );
  };

  const fetchCategoriesByParentId = () => {
    dispatch(
      getCategoriesByParentId({
        id: categoryId,
      })
    );
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const handleChangeSelectBoxValue = (e, elem) => {
    const value =
      elem.name === FILTER_CONSTANTS.SUBJECT ? e.value : e.target?.value;

    if (
      elem.name === TOPIC_KEY ||
      elem.name === SUBJECT_KEY ||
      elem.name === CATEGORY_ID_KEY
    ) {
      hasChangedManuallyRef.current = true;
    }

    if (elem.name === FILTER_CONSTANTS.SUBJECT) {
      setCategoryId(value);
      formik.setFieldValue(CATEGORY_ID_KEY, "");
      setPrivateSubjects([]);
    }

    if (elem.name === FILTER_CONSTANTS.TOPIC) {
      setSelectedTopic(value);
      setCategoryId(null);
      setPrivateSubjects([]);
      formik.setFieldValue(CATEGORY_ID_KEY, "");
    }

    if (elem.name === TYPE_KEY && !isCreate) {
      const isOpenPopup = typeChangedHelper(
        formik.values[TYPE_KEY],
        value,
        formik.values.answers
      );
      const isAnswersEmpty = !isEmptyAllAnswers({
        type: formik.values[TYPE_KEY],
        data: formik.values.answers,
      });
      if (isAnswersEmpty) {
        formik.setFieldValue(elem.name, value);
      }
      setTypeValue(value);
      setChangeType(isOpenPopup);
      if (!isOpenPopup) {
        formik.setFieldValue(elem.name, value);
        removeCorrectChecked(
          formik.setFieldValue,
          ANSWERS,
          formik.values.answers
        );
      }
    } else if (elem.name === EXPLANATION) {
      formik.setFieldValue(elem.name, {
        content: "",
        type: value,
      });
      setUploadedFile(null);
    } else {
      formik.setFieldValue(elem.name, value);
    }
  };

  const [disabledIndex, isDisable] = checkMultipleAnswers(
    formik.values.answers
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id && over && over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const activeItem = items.find((item) => item.id === active.id);
      const overedItem = items.find((item) => item.id === over.id);

      setItems((prevItems) => {
        return prevItems
          .toSpliced(oldIndex, 1, overedItem)
          .toSpliced(newIndex, 1, activeItem);
      });
    }
    setActiveId(null);
    setHoveredId(null);
    setHoveredIndex(null);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === event.active.id ? { ...item, isActive: true } : item
      )
    );
  };

  const handleDragOver = (event) => {
    const { over } = event;
    if (over) {
      setHoveredId(over.id);
      const hoveredIdx = items.findIndex((dragItem) => dragItem.id === over.id);
      const hoveredItem = items.find((dragItem) => dragItem.id === over.id);
      setHoveredIndex(hoveredIdx);
      setHoveredDragItem(hoveredItem);
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === over.id
            ? { ...item, isHovered: true }
            : { ...item, isHovered: false }
        )
      );
    } else {
      setHoveredId(null);
      setHoveredIndex(null);
      setItems((prevItems) =>
        prevItems.map((item) => ({ ...item, isHovered: false }))
      );
    }
  };

  const onChangeDragItem = async (id, newValue) => {
    setItems((prev) =>
      prev.map((item) =>
        item.id === id ? { id, value: newValue, canDrag: true } : item
      )
    );
    const index = items?.findIndex((item) => item?.id === id);
    if (index !== -1) {
      await formik.setFieldValue(`answers[${index}]`, { answer: newValue });
    }
  };

  const deactivateDragAndDrop = () => {
    setItems(items.map((item) => ({ ...item, canDrag: false })));
  };

  const handleCanDrag = (id) => {
    setItems(
      items.map((item) => (item.id === id ? { ...item, canDrag: true } : item))
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(TABLE_MIN_PAGE);
  };

  const handleClearSubject = () => {
    setCategoryId(null);
  };

  const handleDeleteFormFieldArrayItem = (key, index) => {
    setItems((state) => state.filter((val, ind) => ind !== index));
    const newArray = [...formik.values[key]];
    newArray.splice(index, 1);
    formik.setFieldValue(key, [...newArray]);
  };

  const handleAddFormFieldArrayItem = (key) => {
    setItems((prev) => [...prev, { value: "", id: uuidv4(), canDrag: true }]);
    if (
      formik.values.type === QUESTION_TYPES_VALUE.one_answer ||
      formik.values.type === QUESTION_TYPES_VALUE.multiple_answers
    ) {
      formik.setFieldValue(key, [...formik.values[key], answersArray[0]]);
    } else if (formik.values.type === QUESTION_TYPES_VALUE.pairing) {
      formik.setFieldValue(key, [
        ...formik.values[key],
        answersPairingArray[0],
      ]);
    } else if (formik.values.type === QUESTION_TYPES_VALUE.matching) {
      formik.setFieldValue(key, [
        ...formik.values[key],
        answersMatchingArray[0],
      ]);
    }
  };

  const handleCheckboxChange = ({ event, isOneAnswer, elem, index }) => {
    formik.setFieldValue(
      `${elem.backend_field}[${index}].is_correct`,
      event.target.checked
    );
    if (isOneAnswer) {
      formik.values[elem.backend_field]?.forEach((value, idx) => {
        if (index !== idx) {
          formik.setFieldValue(
            `${elem.backend_field}[${idx}].is_correct`,
            false
          );
        }
      });
    }
  };

  const handleDeleteExplanation = () => {
    formik.setFieldValue("explanations", "");
  };

  const handleFileChange = (event, name) => {
    setUploadedFile(event.target.files[0]);
    formik.setFieldValue(name, {
      ...formik.values[name],
      content: event.target.files[0],
    });
  };

  const handleShowHideExplanations = (e) => {
    setShowExplanations(e.target.checked);
  };

  useEffect(() => {
    if (categories.data) {
      setFilteredOptions(() => categories.data);
      setHasMore(!(page === categories?.meta?.last_page));
    }
  }, [categories]);

  useEffect(() => {
    if (!!categoriesByParent) {
      setPrivateSubjects(() => categoriesByParent);
      setHasMore(!(page === categories?.meta?.last_page));
    }
  }, [categoriesByParent]);

  useEffect(() => {
    setFilteredOptions([]);
    setPrivateSubjects([]);
  }, []);

  useEffect(() => {
    if (selectedTopic) {
      fetchCategoriesByTopicId(searchTerm ?? "");
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (categoryId) {
      fetchCategoriesByParentId();
    }
  }, [categoryId]);

  useEffect(() => {
    if (isDiagnosticQuiz) {
      dispatch(getTopicById(topic_id));
      dispatch(getCategoriesById(category_id));
    }
    dispatch(getTopics({ search: "" }));
  }, []);

  useEffect(() => {
    setType(formik.values.type);
    if (!editContent?.answers?.length) {
      if (
        formik.values.type === QUESTION_TYPES_VALUE.one_answer ||
        formik.values.type === QUESTION_TYPES_VALUE.multiple_answers
      ) {
        formik.setFieldValue(ANSWERS, [...answersArray]);
        setItems(
          answersMatchingArray.map((value) => ({
            id: uuidv4(),
            value: value.answer,
            canDrag: true,
          }))
        );
      } else if (formik.values.type === QUESTION_TYPES_VALUE.pairing) {
        formik.setFieldValue(ANSWERS, [...answersPairingArray]);
        setItems(
          answersMatchingArray.map((value) => ({
            id: uuidv4(),
            value: value.answer,
            canDrag: true,
          }))
        );
      } else if (formik.values.type === QUESTION_TYPES_VALUE.matching) {
        formik.setFieldValue(ANSWERS, [...answersMatchingArray]);
      }
    }
  }, [formik.values.type, editContent, isCreate]);

  useEffect(() => {
    if (formik.values.type === QUESTION_TYPES_VALUE.matching) {
      formik.setFieldValue(
        ANSWERS,
        items.map(({ value }) => ({ answer: value }))
      );
    }
  }, [items]);

  useEffect(() => {
    if (id) {
      dispatch(getQuestionById({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (editContent && !isCreate) {
      setDataValue({
        editContent,
        questionSchema: questionValidationSchema,
        formik,
        setItems,
      });
      formik.setFieldValue(TOPIC_KEY, editContent?.category?.topic_id);
      formik.setFieldValue(SUBJECT_KEY, editContent?.category?.id);
      formik.setFieldValue(CATEGORY_KEY, editContent.category_id);
      formik.setFieldValue(LEVEL_KEY, editContent.level);
      formik.setFieldValue(TYPE_KEY, editContent.type);
      const media = editContent.media?.map((elem) => elem.media);
      formik.setFieldValue(MEDIA_KEY, media);
      setImageArray(media);

      if (!!editContent.explanations?.length) {
        setShowExplanations(true);
        editContent.explanations?.forEach((elem) => {
          if (elem?.type === CONSTANTS.VALID_TEXT) {
            setEditorValue(elem?.content);
          } else {
            formik.setFieldValue(EXPLANATION, {
              content: elem.content,
              type: elem.type,
            });
            setUploadedFile(elem?.content);
          }
        });
      }
      setSelectedTopic(editContent?.category?.topic_id);
      setCategoryId(
        isFixedQuiz
          ? editContent?.category?.id
          : editContent?.category?.parent_id
      );
    } else {
      setEmptyValues({
        formik,
        type: formik?.values?.type,
        answersArray,
        setItems,
        answersMatchingArray,
        answersPairingArray,
      });
      formik.setFieldValue(LEVEL_KEY, "");
      formik.setFieldValue(TYPE_KEY, "");
      formik.setFieldValue(MEDIA_KEY, []);
      setImageArray([]);
      if (!hasChangedManuallyRef.current) {
        if (!!savedData && !topicById?.id) {
          for (let key in savedData) {
            if (key === TOPIC_KEY) {
              if (topics.find((elem) => elem.id === savedData[key])) {
                setSelectedTopic(savedData[key]);
                formik.setFieldValue(key, savedData[key]);
              }
            } else if (key === SUBJECT_KEY) {
              if (
                categories?.data?.find((elem) => elem.id === savedData[key])
              ) {
                setCategoryId(savedData[key]);
                formik.setFieldValue(key, savedData[key]);
              }
            } else {
              if (
                categoriesByParent?.find((elem) => elem.id === savedData[key])
              ) {
                formik.setFieldValue(key, savedData[key]);
              }
            }
          }
        }
      }
    }
  }, [
    editContent,
    isCreate,
    topics,
    categories,
    categoriesByParent,
    topicById,
  ]);

  useEffect(() => {
    if (editContent.description && !formik?.values?.description) {
      formik.setFieldValue(DESCRIPTION_KEY, editContent.description || "");
    }
  }, [editContent?.description]);

  useEffect(() => {
    if (editContent.question && !formik?.values?.question) {
      formik.setFieldValue(QUESTION_KEY, editContent.question || "");
    }
  }, [editContent?.question]);

  useEffect(() => {
    if (isCreate) dispatch(clearQuestionById());
  }, [isCreate]);

  useEffect(() => {
    if (!!topicById && isDiagnosticQuiz) {
      formik.setFieldValue(TOPIC_KEY, topicById.id);
      setSelectedTopic(topicById.id);
    }
  }, [topicById, isDiagnosticQuiz]);

  useEffect(() => {
    if (!!categoryById && isDiagnosticQuiz) {
      formik.setFieldValue(SUBJECT_KEY, categoryById.id);
      setCategoryId(categoryById.id);
    }
  }, [categoryById, isDiagnosticQuiz]);

  useEffect(() => {
    if (confirmTypeChange && !isCreate) {
      formik.setFieldValue(TYPE_KEY, typeValue);
      setEmptyValues({
        formik,
        type: typeValue,
        answersArray,
        setItems,
        answersMatchingArray,
        answersPairingArray,
      });
      setConfirmTypeChange(false);
    }
  }, [confirmTypeChange, isCreate]);

  const handleFieldChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="form_container padding">
      {showModal && (
        <ModalWindow
          open={showModal}
          setOpen={setShowModal}
          message={CONSTANTS.WARNING_MESSAGE}
          isQuestion={true}
          setIsConfirmed={setIsConfirmed}
          submit={formik.handleSubmit}
          title={CONSTANTS.WARNING}
        />
      )}
      {changeType && !isCreate && (
        <ModalWindow
          open={changeType}
          setOpen={setChangeType}
          message={CONSTANTS.TYPE_WARNING_MESSAGE}
          isTypeChange={true}
          setIsConfirmed={setConfirmTypeChange}
          title={CONSTANTS.WARNING}
        />
      )}
      <div className="form_back_btn_field back_btn_question">
        <Button
          variant="contained"
          className="form_back_btn"
          onClick={() => navigate(-1)}
        >
          <BackIcon width="16" height="16" className="back_icon" />
          {CONSTANTS.BACK_BUTTON}
        </Button>
      </div>
      <form
        onSubmit={formik.handleSubmit}
        className="create_edit_form width_90"
      >
        {questionValidationSchema.map((elem, index) => {
          return (
            <Fragment key={elem.id}>
              {elem.id === TOPIC_KEY && (
                <>
                  <div className="field_Container question_filed_container column_6">
                    <p className="field_title">{elem.input_name}</p>
                    <TextField
                      fullWidth
                      id={elem.id}
                      select
                      variant="outlined"
                      label={elem.label}
                      disabled={!!topic_id}
                      value={formik.values[elem.backend_field]}
                      onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                      error={formErrorHelper({
                        formik,
                        elementName: elem.name,
                        isBoolean: true,
                      })}
                      helperText={formErrorHelper({
                        formik,
                        elementName: elem.name,
                      })}
                    >
                      {topics?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className="field_Container question_filed_container column_6"></div>
                </>
              )}
              {elem.id === SUBJECT_KEY && (
                <div
                  key={elem.id + index}
                  className="field_Container question_filed_container column_6"
                >
                  <p className="field_title">{elem.input_name}</p>
                  <InfiniteScrollAutocomplete
                    hasMore={hasMore}
                    handleSearchChange={(e) => handleSearchChange(e)}
                    options={filteredOptions}
                    handleLoadMore={handleLoadMore}
                    searchTerm={searchTerm}
                    handleClear={handleClearSubject}
                    disabled={!!category_id}
                    handleChange={(_, value) =>
                      handleChangeSelectBoxValue(value, elem)
                    }
                    value={categoryId}
                    label={elem.label}
                    hasNotNone
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  />
                </div>
              )}
              {!isFixedQuiz && elem.id === CATEGORY_ID_KEY && (
                <div
                  key={elem.id + index}
                  className="field_Container question_filed_container column_6"
                >
                  <p className="field_title">{elem.input_name}</p>
                  <TextField
                    fullWidth
                    id={elem.id}
                    select
                    variant="outlined"
                    label={elem.label}
                    value={formik.values[elem.backend_field]}
                    onChange={(e) => {
                      handleChangeSelectBoxValue(e, elem);
                    }}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  >
                    {privateSubjects?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              {elem.id === TYPE_KEY && (
                <div
                  key={elem.id}
                  className="field_Container question_filed_container column_6"
                >
                  <p className="field_title">{elem.input_name}</p>
                  <TextField
                    fullWidth
                    id={elem.id}
                    select
                    variant="outlined"
                    value={formik.values[elem.backend_field]}
                    onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                    label={elem.label}
                  >
                    {QUESTION_TYPES?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              {elem.id === LEVEL_KEY && !isFixedQuiz && (
                <div
                  key={elem.id}
                  className="field_Container question_filed_container column_6"
                >
                  <p className="field_title">{elem.input_name}</p>
                  <TextField
                    fullWidth
                    id={elem.id}
                    select
                    variant="outlined"
                    label={elem.label}
                    value={formik.values[elem.backend_field]}
                    onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={formErrorHelper({
                      formik,
                      elementName: elem.name,
                    })}
                  >
                    {QUESTION_LEVELS?.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              )}
              {(elem.type === FORM_ITEM_TYPES.textarea ||
                elem.type === FORM_ITEM_TYPES.text) && (
                <div
                  className="field_Container question_filed_container column_12 width_100"
                  key={elem.id}
                >
                  <p className="field_title">{elem.input_name}</p>
                  <TextField
                    fullWidth
                    id={elem.id}
                    name={elem.name}
                    label={elem.label}
                    variant="outlined"
                    multiline={elem.type === FORM_ITEM_TYPES.textarea}
                    minRows={4}
                    maxRows={10}
                    value={
                      formik.values[elem.name] === null
                        ? ""
                        : formik.values[elem.name]
                    }
                    onChange={formik.handleChange}
                    error={formErrorHelper({
                      formik,
                      elementName: elem.name,
                      isBoolean: true,
                    })}
                    helperText={
                      elem.id === "question" &&
                      !formErrorHelper({
                        formik,
                        elementName: elem.name,
                      })
                        ? HELPER_TEXT
                        : formErrorHelper({
                            formik,
                            elementName: elem.name,
                          })
                    }
                  />
                </div>
              )}
              {elem.type === FORM_ITEM_TYPES.mediaFile && (
                <Fragment key={elem.id}>
                  <div className="field_Container width_100 column_12 direction_column">
                    <p className="field_title">{elem.input_name}</p>
                    <ImageArrayInput
                      setImageArray={setImageArray}
                      data={editContent?.media}
                      productId={editContent?.id}
                      imageArray={imageArray}
                      element={elem}
                      handleFieldChange={handleFieldChange}
                    />
                  </div>
                </Fragment>
              )}
              {elem.type === FORM_ITEM_TYPES.file && (
                <Fragment key={elem.id}>
                  <div className="explanation_checkbox">
                    <p className="active_text">{CONSTANTS.SHOW_EXPLANATIONS}</p>
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 20 },
                      }}
                      color="secondary"
                      onChange={(e) => handleShowHideExplanations(e)}
                      checked={showExplanations}
                      disabled={
                        !!formik.values[elem.backend_field].length ||
                        !!deleteHtmlFromString(editorValue).trim().length
                      }
                    />
                  </div>
                  {showExplanations && (
                    <ExplanationContent
                      formik={formik}
                      elem={elem}
                      editContent={editContent}
                      setEditorValue={setEditorValue}
                      editorValue={editorValue}
                      handleChangeSelectBoxValue={handleChangeSelectBoxValue}
                      handleFileChange={handleFileChange}
                      uploadedFile={uploadedFile}
                      deleteFile={handleDeleteExplanation}
                    />
                  )}
                </Fragment>
              )}
              {elem.type === FORM_ITEM_TYPES.questionArray &&
                (formik.values.type === QUESTION_TYPES_VALUE.one_answer ||
                  formik.values.type ===
                    QUESTION_TYPES_VALUE.multiple_answers) && (
                  <div
                    className="field_Container question_filed_container column_12"
                    key={elem.id}
                  >
                    <p className="field_title">{elem.input_name}</p>
                    <p className="hint_text">
                      {formik.values.type === QUESTION_TYPES_VALUE.one_answer
                        ? QUESTIONS_HINT_TEXT.one_answer
                        : QUESTIONS_HINT_TEXT.multiple_answers}
                    </p>
                    <p className="hint_text italic">{HELPER_TEXT}</p>
                    <div className="array_wrapper">
                      {formik?.values?.[elem.backend_field]?.map(
                        (desc, index) => (
                          <div
                            className="array_field_desc question_array_field"
                            key={index}
                          >
                            <div>
                              <TextField
                                fullWidth
                                id={`${elem.id}${index}answer`}
                                name={`${elem.backend_field}[${index}].answer`}
                                placeholder={`${elem.labels.answer}`}
                                variant="outlined"
                                value={
                                  formik.values[elem.backend_field][index]
                                    .answer
                                }
                                className="array_input spacing"
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="check_and_delete">
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id={`${elem.id}${index}is_correct`}
                                      name={`${elem.backend_field}[${index}].is_correct`}
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 20 },
                                      }}
                                      color="secondary"
                                      onChange={(e) =>
                                        handleCheckboxChange({
                                          event: e,
                                          isOneAnswer:
                                            formik.values.type ===
                                            QUESTION_TYPES_VALUE.one_answer,
                                          elem,
                                          index,
                                        })
                                      }
                                      checked={
                                        formik.values[elem.backend_field][index]
                                          .is_correct
                                      }
                                      disabled={
                                        formik.values.type ===
                                          QUESTION_TYPES_VALUE.multiple_answers &&
                                        disabledIndex === index &&
                                        isDisable
                                      }
                                    />
                                  }
                                  labelPlacement="end"
                                  label="Correct"
                                />
                              </FormGroup>
                              <Tooltip title={CONSTANTS.REMOVE} arrow>
                                <Button
                                  className="action_button min_width custom_styles mx-1 shadow-none border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                  disabled={
                                    formik?.values?.[elem.backend_field]
                                      ?.length < MIN_LENGTH_ANSWERS
                                  }
                                  onClick={() =>
                                    handleDeleteFormFieldArrayItem(
                                      elem.backend_field,
                                      index
                                    )
                                  }
                                >
                                  <DeleteIcon className="array_delete_icon about_delete_icon" />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        )
                      )}
                      <Button
                        className="add_button add_button_margin"
                        onClick={() =>
                          handleAddFormFieldArrayItem(
                            elem.backend_field,
                            elem.type
                          )
                        }
                      >
                        {`${CONSTANTS.ADD_BUTTON} ${elem.label}`}
                      </Button>
                    </div>
                  </div>
                )}
              {elem.type === FORM_ITEM_TYPES.questionArray &&
                formik.values.type === QUESTION_TYPES_VALUE.pairing && (
                  <div
                    className="field_Container question_filed_container column_12"
                    key={elem.id}
                  >
                    <p className="field_title">{elem.input_name}</p>
                    <p className="hint_text">{QUESTIONS_HINT_TEXT.pairing}</p>
                    <p className="hint_text italic">{HELPER_TEXT}</p>
                    <div className="array_wrapper">
                      {formik?.values?.[elem.backend_field]?.map(
                        (desc, index) => (
                          <div className="array_field_desc" key={index}>
                            <div className="pairing_questions_array">
                              {Array.isArray(desc) &&
                                desc?.map((element, idx) => (
                                  <Fragment key={idx}>
                                    <TextField
                                      fullWidth
                                      id={`${elem.id}${index}[${idx}]answer`}
                                      name={`${elem.backend_field}[${index}][${idx}].answer`}
                                      placeholder={`${elem.labels.answer}`}
                                      variant="outlined"
                                      value={
                                        formik.values[elem.backend_field][
                                          index
                                        ][idx].answer
                                      }
                                      className="array_input spacing"
                                      onChange={formik.handleChange}
                                    />
                                    {idx === 0 && (
                                      <span>
                                        <CompareArrows className="arrows_icon" />
                                      </span>
                                    )}
                                  </Fragment>
                                ))}
                              <Tooltip title={CONSTANTS.REMOVE} arrow>
                                <Button
                                  className="action_button min_width custom_styles mx-1 shadow-none border-0 p-0 d-inline-flex align-items-center justify-content-center"
                                  disabled={
                                    formik?.values?.[elem.backend_field]
                                      ?.length < MIN_LENGTH_ANSWERS
                                  }
                                  onClick={() =>
                                    handleDeleteFormFieldArrayItem(
                                      elem.backend_field,
                                      index
                                    )
                                  }
                                >
                                  <DeleteIcon className="array_delete_icon question_delete_icon" />
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        )
                      )}
                      <Button
                        className="add_button add_button_margin"
                        onClick={() =>
                          handleAddFormFieldArrayItem(
                            elem.backend_field,
                            elem.type
                          )
                        }
                      >
                        {`${CONSTANTS.ADD_BUTTON} ${elem.label}`}
                      </Button>
                    </div>
                  </div>
                )}
              {elem.type === FORM_ITEM_TYPES.questionArray &&
                formik.values.type === QUESTION_TYPES_VALUE.matching && (
                  <div className="field_Container direction_column drag_container column_12 width_100">
                    <div>
                      <p className="field_title">{elem.input_name}</p>
                      <p className="hint_text">
                        {QUESTIONS_HINT_TEXT.matching}
                      </p>
                      <p className="hint_text italic">{HELPER_TEXT}</p>
                    </div>
                    <DndContext
                      sensors={sensors}
                      onDragEnd={handleDragEnd}
                      onDragStart={handleDragStart}
                      onDragOver={handleDragOver}
                    >
                      {items?.map((v, index) => (
                        <div
                          key={v.id}
                          className="d-flex align-items-center w-100"
                        >
                          <CardDrag
                            index={index}
                            key={v.id}
                            id={v.id}
                            canDrag={v.canDrag}
                            customClass="array_field about_array"
                            deactivateDragAndDrop={deactivateDragAndDrop}
                            isDragActive={isDragActive}
                            isActive={v.isActive}
                            isHovered={v.isHovered}
                            isHoveredItem={v.id === hoveredId}
                          >
                            <TextField
                              fullWidth
                              id={`${elem.id}${index}answer`}
                              name={`${elem.backend_field}[${index}].answer`}
                              label={`${CONSTANTS.ITEMS} ${index + 1}`}
                              variant={TEXTFIELD_VARIANTS_OBJ.outlined}
                              value={
                                v.id === activeId
                                  ? hoveredDragItem?.value
                                  : v.value
                              }
                              className="array_input_about"
                              onChange={(e) =>
                                onChangeDragItem(v.id, e.target.value)
                              }
                              onFocus={(e) => {
                                e.stopPropagation();
                                setIsDragActive(false);
                              }}
                              onBlur={(e) => {
                                e.stopPropagation();
                                setIsDragActive(true);
                              }}
                              InputProps={{
                                startAdornment: (
                                  <StyledInputAdornment
                                    position="start"
                                    onMouseDown={(e) => {
                                      e.stopPropagation();
                                      handleCanDrag(v.id);
                                    }}
                                    onMouseUp={(e) => {
                                      e.stopPropagation();
                                      deactivateDragAndDrop(e);
                                    }}
                                  >
                                    <DragIndicatorIcon className="drag_icon" />
                                  </StyledInputAdornment>
                                ),
                              }}
                            />
                          </CardDrag>
                          <Tooltip title={CONSTANTS.REMOVE} arrow>
                            <Button
                              className="action_button custom_styles mx-1 shadow-none border-0 p-0 d-inline-flex align-items-center justify-content-center custom_height"
                              disabled={items?.length < MIN_LENGTH_ANSWERS}
                              onClick={() =>
                                handleDeleteFormFieldArrayItem(
                                  elem.backend_field,
                                  index
                                )
                              }
                            >
                              <DeleteIcon className="array_delete_icon about_delete_icon" />
                            </Button>
                          </Tooltip>
                        </div>
                      ))}
                      <DragOverlay>
                        {activeId ? (
                          <TextField
                            fullWidth
                            id={`${FORM_ITEM_NAMES_OBJ.items}${hoveredIndex}`}
                            name={`${items}${hoveredIndex}`}
                            label={`${CONSTANTS.ITEMS} ${hoveredIndex + 1}`}
                            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
                            value={
                              items.find((item) => item.id === activeId)?.value
                            }
                            className="array_input_about"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <DragIndicatorIcon className="drag_icon" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : null}
                      </DragOverlay>
                      <Button
                        className="add_button add_button_margin"
                        onClick={() =>
                          handleAddFormFieldArrayItem(
                            elem.backend_field,
                            elem.type
                          )
                        }
                      >
                        {BUTTON_NAMES_OBJ.add} {CONSTANTS.ITEMS}
                      </Button>
                    </DndContext>
                  </div>
                )}
            </Fragment>
          );
        })}
        <div className="column_12 save_button_filed questions_save_btn">
          <SaveButton isLoading={isSubmitting} />
        </div>
      </form>
    </div>
  );
};
