import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  changeActivation,
  deleteQuestion,
  getQuestions,
} from "actions/questionsActions";
import { ComponentHeader } from "components";
import ModalWindow from "components/ModalWindow";
import { QuestionTable } from "components/QuestionTable";
import { CONSTANTS, defaultSortParams } from "constants/general";
import FilterHelpers from "helpers/filter";
import { clearQuestionById, setAppliedFilters } from "features/questionsSlice";
import { useSearchParams } from "hooks/useSearchParams";
import { debounce } from "lodash";

export const Questions = () => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(state?.page || CONSTANTS.FIRST_PAGE);
  const [sort, setSort] = useState(defaultSortParams);
  const [params, setParams] = useState({});

  const { search } = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const questions = useSelector((state) => state.questions.questions);
  const totalCount = useSelector((state) => state.questions.total);
  const loading = useSelector((state) => state.questions.status);
  const appliedFiltersFromRedux = useSelector(
    (state) => state.questions.appliedFilters
  );

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFiltersToParams,
    appliedFilters,
  } = FilterHelpers(appliedFiltersFromRedux);

  const handleCreate = () => {
    dispatch(clearQuestionById());
    navigate("create");
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    dispatch(clearQuestionById());
    navigate(`edit/${id}`);
  };

  const handleToggleChange = (id, active) => {
    const data = { is_active: !active };
    dispatch(changeActivation({ id, data, params }));
  };

  useEffect(() => {
    dispatch(setAppliedFilters(appliedFilters));
  }, [appliedFilters, page, sort]);

  const debouncedFetchQuestions = debounce((params) => {
    dispatch(getQuestions({ params }));
  }, 300);

  useEffect(() => {
    const appliedFilterParams = appliedFiltersToParams(appliedFiltersFromRedux);
    const newParams = {
      search,
      page,
      ...sort,
      for_diagnostic_quiz: 0,
      filters: {
        category_id: appliedFilterParams?.category_id,
        topic_id: appliedFilterParams?.topic_id || "",
        level: appliedFilterParams?.level || "",
        type: appliedFilterParams?.type || "",
        subject_id: appliedFilterParams?.subject_id,
        completed: appliedFilterParams?.status ?? "",
        is_active: appliedFilterParams?.is_active ?? "",
      },
    };

    setParams(newParams);
  }, [appliedFiltersFromRedux, page, sort, search]);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      debouncedFetchQuestions(params);
    }
  }, [params]);

  useEffect(() => {
    setPage(state?.page || CONSTANTS.FIRST_PAGE);
  }, [search]);

  return (
    <div className="table_page_body_container">
      <ComponentHeader create handleCreateEdit={handleCreate} />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          currentPage={page}
          message={CONSTANTS.DELETE_QUESTION_MESSAGE}
          deleteRequest={deleteQuestion}
          params={params}
        />
      )}
      <QuestionTable
        data={questions}
        setDeleteId={setDeleteId}
        setOpen={setOpen}
        totalCount={totalCount}
        handleApplyFilter={handleApplyFilter}
        handleRemoveFilter={handleRemoveFilter}
        handleClearFilters={handleClearFilters}
        appliedFilters={appliedFilters}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        isQuestion={true}
        handleEdit={handleEdit}
        handleToggleChange={handleToggleChange}
        showToggle={true}
        loading={loading}
      />
    </div>
  );
};
