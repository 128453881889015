import { createAsyncThunk } from "@reduxjs/toolkit";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { departmentApi } from "service/departmentApi";

export const getTopics = createAsyncThunk(
  "/nordas/getTopics",
  async ({ search }, { rejectWithValue }) => {
    try {
      const res = await departmentApi.getTopics(search);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getTopicById = createAsyncThunk(
  "/nordas/getTopicById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await departmentApi.getTopicById(id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createTopic = createAsyncThunk(
  "/nordas/createTopic",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      await departmentApi.createTopic(data);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      dispatch(getTopics({ search: "" }));
    } catch (err) {
      return rejectWithValue(err.message)
    }
  }
)

export const updateTopic = createAsyncThunk(
  "/nordas/updateTopic",
  async ({ data, id }, { rejectWithValue, dispatch }) => {
    try {
      await departmentApi.updateTopic(data, id);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
      dispatch(getTopics({ search: "" }));
    } catch (err) {
      return rejectWithValue(err.message)
    }
  }
)

export const deleteTopic = createAsyncThunk(
  "/nordas/deleteTopic",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      await departmentApi.deleteTopic(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getTopics({ search: "" }));
    } catch (err) {
      return rejectWithValue(err.message)
    }
  }
)