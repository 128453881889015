import { v4 as uuidv4 } from "uuid";
import { ANSWERS, QUESTION_TYPES_VALUE } from "constants/general";
import { isEmptyAllAnswers } from "./answersCheck";

const setOneMultipleTypes = ({ questionSchema, editContent, formik }) => {
  questionSchema?.forEach((elem) => {
    if (elem.name === ANSWERS) {
      const answers = editContent[elem.name].map((value) => ({
        answer: value.answer,
        is_correct: value.is_correct,
      }));
      formik.setFieldValue(elem.name, answers);
    } else {
      formik.setFieldValue(elem.name, editContent[elem.name]);
    }
  });
};

const setMatchingType = ({ questionSchema, setItems, editContent, formik }) => {
  questionSchema?.forEach((elem) => {
    if (elem.name === ANSWERS) {
      setItems(
        editContent[elem.name]?.map((value) => ({
          id: value.id,
          value: value.answer,
          canDrag: true,
        }))
      );
    } else {
      formik.setFieldValue(elem.name, editContent[elem.name]);
    }
  });
};

const setPairingType = ({ questionSchema, editContent, formik }) => {
  questionSchema?.forEach((elem) => {
    if (elem.name === ANSWERS) {
      const answers = editContent?.compare_sequence;
      const answersArray = answers?.map((element) => {
        const item = element.map((value) =>
          editContent[elem.name].find(({ id }) => id === value)
        );
        return item.map((value) => ({
          answer: value?.answer,
          position: value?.position,
        }));
      });
      formik.setFieldValue(elem.name, answersArray);
    } else {
      formik.setFieldValue(elem.name, editContent[elem.name]);
    }
  });
};

export const setDataValue = ({
  editContent,
  questionSchema,
  formik,
  setItems,
}) => {
  if (
    editContent.type === QUESTION_TYPES_VALUE.one_answer ||
    editContent.type === QUESTION_TYPES_VALUE.multiple_answers
  ) {
    setOneMultipleTypes({ questionSchema, editContent, formik });
  } else if (editContent.type === QUESTION_TYPES_VALUE.matching) {
    setMatchingType({ questionSchema, setItems, editContent, formik });
  } else if (editContent.type === QUESTION_TYPES_VALUE.pairing) {
    setPairingType({ questionSchema, editContent, formik });
  }
};

export const setEmptyValues = ({
  formik,
  type,
  answersArray,
  setItems,
  answersMatchingArray,
  answersPairingArray,
}) => {
  if (
    type === QUESTION_TYPES_VALUE.one_answer ||
    type === QUESTION_TYPES_VALUE.multiple_answers
  ) {
    formik.setFieldValue(ANSWERS, [...answersArray]);
  } else if (type === QUESTION_TYPES_VALUE.pairing) {
    formik.setFieldValue(ANSWERS, [...answersPairingArray]);
  } else if (type === QUESTION_TYPES_VALUE.matching) {
    formik.setFieldValue(ANSWERS, [...answersMatchingArray]);
    setItems(
      answersMatchingArray.map((value) => ({
        id: uuidv4(),
        value: value.answer,
        canDrag: true,
      }))
    );
  }
};

export const checkMultipleAnswers = (data) => {
  let count = 0;
  let disabledIndex = null;

  data?.forEach((value) => {
    if (!value.is_correct) {
      ++count;
    }
  });

  if (count === 1) {
    data?.forEach((value, index) => {
      if (!value.is_correct) {
        disabledIndex = index;
      }
    });
  }

  return [disabledIndex, count === 1];
};

export const typeChangedHelper = (oldValue, newValue, answers) => {
  if (
    oldValue === newValue ||
    (oldValue === QUESTION_TYPES_VALUE.one_answer &&
      newValue === QUESTION_TYPES_VALUE.multiple_answers) ||
    (newValue === QUESTION_TYPES_VALUE.one_answer &&
      oldValue === QUESTION_TYPES_VALUE.multiple_answers)
  ) {
    return false;
  } else if (!isEmptyAllAnswers({ type: oldValue, data: answers })) {
    return false;
  }
  return true;
};

export const removeCorrectChecked = (setFieldValue, name, value) => {
  const newValue = value?.map((elem) => ({ ...elem, is_correct: false }));
  setFieldValue(name, newValue);
};
