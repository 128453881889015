export const getUsersWithFullName = (userList) => {
  let users = userList.data;

  let usersWithFullName = users.map((user) => ({
    ...user,
    full_name: `${user.first_name} ${user.last_name}`,
  }));

  userList.data = usersWithFullName;

  return userList;
};
