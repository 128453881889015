import React, { useEffect, useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  MenuItem,
  InputLabel,
  Popover,
  OutlinedInput,
} from "@mui/material";
import {
  ApplyButton,
  FilterButton,
  FormWrapper,
  StyledFormControl,
} from "assets/filter/styled";
import {
  CONSTANTS,
  FILTER_INPUT_SIZE_SMALL,
  TABLE_MIN_PAGE,
} from "constants/general";
import {
  FILTERS_VALUE_CHANGE,
  FILTER_CHANGE,
  FILTER_CONSTANTS,
} from "./constants";
import { InfiniteScrollAutocomplete } from "components";
import { getTopics } from "actions/departmentAcions";
import { getCategoriesByTopicId } from "actions/categoryActions";
import { makeCapitalize } from "helpers/textCapitalize";
import { FilterIcon } from "components/icons";
import { clearCategoriesList } from "features/categorySlice";

export const Filter = ({ applyFilter, appliedFilters, setTablePage }) => {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.data);
  const categories = useSelector((state) => state.category.categoriesByTopic);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(TABLE_MIN_PAGE);
  const [selectedTopic, setSelectedTopic] = useState(null);

  const isFirstMountRef = useRef(true);

  const filterList = [
    {
      column: FILTER_CONSTANTS.TOPIC,
      queryKey: FILTER_CONSTANTS.TOPIC_ID,
      values: [],
      options: topics,
    },
    {
      column: FILTER_CONSTANTS.CATEGORY,
      queryKey: FILTER_CONSTANTS.CATEGORY_ID,
      values: [],
      options: categories,
    },
    {
      column: FILTER_CONSTANTS.STATUS,
      queryKey: FILTER_CONSTANTS.STATUS_KEY,
      valueName: "complete",
      rejectPrefix: "In",
      values: [
        { name: "Incomplete", value: 0 },
        { name: "Complete", value: 1 },
      ],
      options: [
        { title: "Incomplete", id: 0 },
        { title: "Complete", id: 1 },
      ],
    },
    {
      column: FILTER_CONSTANTS.ACTIVATION,
      queryKey: FILTER_CONSTANTS.ACTIVATION_KEY,
      valueName: "active",
      rejectPrefix: "In",
      values: [
        { name: "Inactive", value: 0 },
        { name: "Active", value: 1 },
      ],
      options: [
        { title: "Inactive", id: 0 },
        { title: "Active", id: 1 },
      ],
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(1);

    if (selectedTopic) {
      fetchCategoriesByTopicId({
        search: searchTerm ?? "",
        isLoadMore: false,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPage(1);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchCategoriesByTopicId = ({ search = "", isLoadMore }) => {
    dispatch(
      getCategoriesByTopicId({
        id: selectedTopic,
        params: {
          isLoadMore,
          page,
        },
      })
    );
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(TABLE_MIN_PAGE);
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const handleFilterChange = (event, filterIndex, queryKey, rejectPrefix) => {
    setSearchTerm(null);
    const target = { ...event, name: FILTER_CHANGE[queryKey] };

    const newFilters = [...selectedFilters];
    newFilters[filterIndex] = {
      ...newFilters[filterIndex],
      ...target,
      queryKey,
      rejectPrefix,
    };

    const data = {
      [FILTER_CONSTANTS.TOPIC_ID]: topics,
      [FILTER_CONSTANTS.CATEGORY_ID]: categories.data,
    };

    newFilters[filterIndex].valueName = FILTERS_VALUE_CHANGE[queryKey]({
      target,
      setSelected: setSelectedTopic,
      setPage,
      newFilters,
      filterIndex,
      data: data[queryKey],
      event,
    });

    setSelectedFilters(newFilters);
  };

  const handleClear = (filterIndex) => {
    const newFilters = [...selectedFilters];
    newFilters[filterIndex] = null;
    setSelectedFilters(newFilters);
  };

  const handleApplyFilter = () => {
    applyFilter(selectedFilters);
    setTablePage(1);
    handleClose();
  };

  const handleFindElementByQuery = (key) => {
    const foundElement = selectedFilters?.find(
      (elem) => elem?.queryKey === key
    )?.value;
    return foundElement ?? "";
  };

  useEffect(() => {
    if (categories.data) {
      setFilteredOptions(() => categories.data);
      setHasMore(!(page === categories?.meta?.last_page));
    } else {
      setFilteredOptions(() => []);
    }
  }, [categories]);

  useEffect(() => {
    if (selectedTopic) {
      fetchCategoriesByTopicId({
        isLoadMore: page > 1 && !isFirstMountRef.current,
      });
    } else {
      dispatch(clearCategoriesList());
    }
    isFirstMountRef.current = false;
  }, [selectedTopic, page]);

  useEffect(() => {
    setSelectedFilters(appliedFilters);
    const findTopicFilter = appliedFilters.find(
      (elem) => elem?.queryKey === FILTER_CONSTANTS.TOPIC_ID
    );

    if (!findTopicFilter) {
      setSelectedTopic(null);
    }
  }, [appliedFilters]);

  useEffect(() => {
    dispatch(getTopics({ search: "" }));
  }, []);

  useEffect(() => {
    setSelectedFilters((prev) => prev.slice(0, 1));
  }, [selectedTopic]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
      }}
    >
      <FilterButton variant="outlined" onClick={handleClick}>
        <FilterIcon /> {CONSTANTS.FILTER}
      </FilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="add_gap"
      >
        <FormWrapper>
          {filterList.map((filter, idx) => {
            return (
              <Fragment key={filter.queryKey + idx}>
                {filter.column === FILTER_CONSTANTS.CATEGORY ? (
                  <StyledFormControl fullWidth size="small">
                    <InfiniteScrollAutocomplete
                      handleLoadMore={handleLoadMore}
                      hasMore={hasMore}
                      searchTerm={searchTerm}
                      options={filteredOptions}
                      handleSearchChange={handleSearchChange}
                      isFilter={true}
                      size={FILTER_INPUT_SIZE_SMALL}
                      handleChange={(_, value) =>
                        handleFilterChange(value, idx, filter.queryKey)
                      }
                      value={selectedFilters[idx]?.value ?? ""}
                      label={makeCapitalize(FILTER_CONSTANTS.CATEGORY)}
                      handleClear={() => handleClear(idx)}
                    />
                  </StyledFormControl>
                ) : (
                  <StyledFormControl fullWidth size="small">
                    <InputLabel
                      htmlFor={`outlined-age-native-simple-${idx}`}
                      className="custom-label"
                    >
                      {makeCapitalize(filter.column)}
                    </InputLabel>
                    <Select
                      name={filter.column}
                      value={handleFindElementByQuery(filter.queryKey)}
                      onChange={(e) =>
                        handleFilterChange(
                          e.target,
                          idx,
                          filter?.queryKey,
                          filter?.rejectPrefix
                        )
                      }
                      className="custom-select"
                      input={
                        <OutlinedInput
                          name={filter.column}
                          label={filter.column}
                          id={`outlined-age-native-simple-${idx}`}
                          className="custom-input"
                        />
                      }
                    >
                      {filter.options.map((filterItem) => (
                        <MenuItem key={filterItem.id} value={filterItem.id}>
                          {filterItem.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                )}
              </Fragment>
            );
          })}
          <ApplyButton onClick={handleApplyFilter}>
            {CONSTANTS.APPLY}
          </ApplyButton>
        </FormWrapper>
      </Popover>
    </div>
  );
};
