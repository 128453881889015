import React from "react";
import { sanitize } from "dompurify";

export const MatchingOrderAnswers = ({ answers }) => {
  const getHighlight = (str) => {
    const htmlText = str?.replaceAll("{{", "<b>")?.replaceAll("}}", "</b>");
    return { __html: sanitize(htmlText) };
  };

  return (
    <div className="answer_container">
      {answers?.map((item, i) => (
        <span
          key={item.id}
          className="answer mr-1"
          dangerouslySetInnerHTML={getHighlight(`${i + 1}. ${item.answer}`)}
        ></span>
      ))}
    </div>
  );
};
