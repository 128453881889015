import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const fixedQuizSchema = [
  {
    id: "topic",
    name: "topic",
    label: CONSTANTS.SECTION,
    type: "select",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "topicId",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
  },
  {
    id: "category",
    name: "category",
    label: CONSTANTS.SUBJECT_NAME,
    type: "select",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "categoryId",
    validation: Yup.number().required(CONSTANTS.REQUIRED),
  },
];
