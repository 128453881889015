import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionById } from "actions/questionsActions";

import { LOCAL } from "local/localizations";
import { QUESTION_TYPES_VALUE } from "constants/general";
import { ComponentHeader } from "components";
import {
  OneOrMoreCorrectAnswers,
  MatchingOrderAnswers,
  PairingAnswers,
  ExplanationsView,
} from "./components";
import { getTopicById } from "actions/departmentAcions";
import { getCategoriesById } from "actions/categoryActions";
import { isImage, isVideo } from "utiles";
import { ResponsiveImage } from "components/ResponsiveImage";
import { ResponsiveVideo } from "components/ResponsiveVideo";
import { clearQuestionById } from "features/questionsSlice";
import { clearSelectedTopic } from "features/departmentSlice";
import { clearSelectedCategoryById } from "features/categorySlice";
import { sanitize } from "dompurify";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

const questionTypes = {
  one_correct_answer: "One correct answer",
  multiple_correct_answers: "Multiple correct answers",
  pairing_answers: "Pairing answers",
  matching_order_answers: "Matching order answers",
};

export const ViewQuestion = ({ isFixed, isDiagnostic }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.questions.question);
  const topic = useSelector((state) => state.topics.topic);
  const subject = useSelector((state) => state.category.categoryById);

  const [editPath, setEditPath] = useState(`/questions/edit/${id}`);

  const getHighlight = (str) => {
    const htmlText = str?.replaceAll("{{", "<b>")?.replaceAll("}}", "</b>");
    return { __html: sanitize(htmlText) };
  };

  useEffect(() => {
    if (question?.category) {
      dispatch(getTopicById(question.category?.topic_id));
      if (!isFixed) dispatch(getCategoriesById(question.category?.parent_id));
    }
  }, [question]);

  useEffect(() => {
    dispatch(getQuestionById({ id }));

    return () => {
      dispatch(clearQuestionById());
      dispatch(clearSelectedTopic());
      dispatch(clearSelectedCategoryById());
    };
  }, []);

  useEffect(() => {
    if (question?.id) {
      if (isFixed) {
        setEditPath(
          `/fixed-quiz/${question?.fixed_quiz_id}/topic/${question.category?.topic_id}/fixed-quiz-questions/${question.category?.id}/edit/${question?.id}`
        );
      } else if (isDiagnostic) {
        setEditPath(
          `/diagnostic-quiz/${question?.diagnostic_quiz_id}/topic/${question.category?.topic_id}/diagnostic-questions/${question.category?.id}/edit/${question?.id}`
        );
      }
    }
  }, [isFixed, question, isDiagnostic]);

  const backPath = isDiagnostic
    ? `/diagnostic-quiz/${question?.diagnostic_quiz_id}/topic/${question.category?.topic_id}/diagnostic-questions/${question.category?.parent_id}`
    : isFixed
    ? `/fixed-quiz/${question?.fixed_quiz_id}/topic/${question.category?.topic_id}/fixed-quiz-questions/${question.category_id}`
    : "/questions";

  return (
    <div className="question_view">
      <ComponentHeader backPath={backPath} edit back editPath={editPath} />
      <div className="card">
        {!!question?.media?.length && (
          <div className="field_container">
            <p className="title">{LOCAL.QUESTION.VIEW.MULTIMEDIA_CONTENT}:</p>
            <div className="row">
              <div className="images_container">
                {question.media.map((m) => {
                  return (
                    <div className="image_item">
                      {isImage(m.media) && (
                        <ResponsiveImage
                          className="image"
                          src={image_url + m.media}
                          alt="Question content"
                        />
                      )}
                      {isVideo(m.media) && (
                        <ResponsiveVideo
                          controls
                          className="image"
                          src={image_url + m.media}
                        ></ResponsiveVideo>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {!!question?.description && (
          <div className="field_container">
            <p className="title">{LOCAL.QUESTION.VIEW.DESCRIPTION}:</p>
            <p className="content">{question?.description}</p>
          </div>
        )}
        <div className="field_container">
          <p className="title">{LOCAL.QUESTION.VIEW.QUESTION_SECTION}:</p>
          <p className="content">{topic?.title}</p>
        </div>
        <div className="row">
          <div className="field_container mr-2 w_half">
            <p className="title">{LOCAL.QUESTION.VIEW.QUESTION_SUBJECT}:</p>
            <p className="content">
              {isFixed ? question?.category?.title : subject?.title}
            </p>
          </div>
          {!isFixed && (
            <div className="field_container w_half">
              <p className="title">
                {LOCAL.QUESTION.VIEW.QUESTION_PRIVATE_SUBJECT}:
              </p>
              <p className="content">{question?.category?.title}</p>
            </div>
          )}
        </div>
        <div className="row">
          {!isFixed && (
            <div className="field_container mr-2 w_half">
              <p className="title">{LOCAL.QUESTION.VIEW.QUESTION_LEVEL}:</p>
              <p className="content capitalize">{question?.level}</p>
            </div>
          )}
          <div className="field_container w_half">
            <p className="title">{LOCAL.QUESTION.VIEW.QUESTION_TYPE}:</p>
            <p className="content">{questionTypes[question?.type]}</p>
          </div>
        </div>
        <div className="field_container w_full">
          <p className="title">{LOCAL.QUESTION.VIEW.QUESTION_DESCRIPTION}:</p>
          <p
            className="content w_60"
            dangerouslySetInnerHTML={getHighlight(question?.question)}
          ></p>
        </div>
        <div className="field_container w_full">
          <p className="title">{LOCAL.QUESTION.VIEW.QUESTION_OPTIONS}:</p>
          {(question?.type === QUESTION_TYPES_VALUE.one_answer ||
            question?.type === QUESTION_TYPES_VALUE.multiple_answers) && (
            <OneOrMoreCorrectAnswers answers={question.answers} />
          )}
          {question?.type === QUESTION_TYPES_VALUE.matching && (
            <MatchingOrderAnswers answers={question.answers} />
          )}
          {question?.type === QUESTION_TYPES_VALUE.pairing && (
            <PairingAnswers answers={question.answers} />
          )}
        </div>
        <div className="field_container explanation_container">
          {question?.explanations?.map((explanation) => (
            <ExplanationsView
              key={explanation?.id}
              type={explanation?.type}
              content={explanation?.content}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
