import instance from "./baseRequest";

export const fixedQuizzesApi = {
  getFixedQuizzes: async (params) =>
    await instance.get("/admin/fixed-quizzes", { params }),
  // getDiagnosticQuizById: async ({ id }) =>
  //   await instance.get("/admin/diagnostic-quiz", { params: { id } }),
  createFixedQuiz: async ({ topic_id, category_id }) =>
    await instance.post("/admin/fixed-quizzes", { topic_id, category_id }),
  // updateDiagnosticQuiz: async ({ topicId, categoryId }) =>
  //   await instance.post("/admin/diagnostic-quiz", { topicId, categoryId }),
  deleteFixedQuiz: async ({ id }) =>
    await instance.delete(`/admin/fixed-quizzes/${id}`),
  changeFixedQuizActivation: async ({ id, body }) =>
    await instance.put(`/admin/fixed-quizzes-change-status/${id}`, body),
  changeFixedQuizStatus: async (id, body) =>
    await instance.put(`/admin/fixed-quizzes-complete/${id}`, body),
};
