import { createSlice } from "@reduxjs/toolkit";
import { getSubjects, getCoSubjects } from "actions/subjectsActions";

const initialState = {
  data: [],
  co_subjects: [],
  co_subject_edit: {},
  edit: { title: "", quiz_type: "" },
  status: null,
  total: null,
  co_total: null,
  title: "",
};

export const subjectSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {
    setEditSubject: (state, { payload }) => {
      state.edit = {
        title: payload.title || "", // WHEN VALUE UNDEFINED FORMIK VALIDATION ERR NOT WORKS
        quiz_type: payload.quiz_type || "",
        description: payload.description,
        topic_id: payload.topic_id,
        parent_id: payload.parent_id,
        id: payload.id,
      };
    },
    setEditCoSubject: (state, { payload }) => {
      state.co_subject_edit = {
        title: payload.title || "",
        description: payload.description,
        topic_id: payload.topic_id,
        parent_id: payload.parent_id,
        id: payload.id,
      };
    },
    setTableTitle: (state, { payload }) => {
      state.title = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSubjects.pending, (state) => {
        state.status = true;
        state.data = [];
        state.total = null;
      })
      .addCase(getSubjects.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.total = payload.meta?.total;
        state.status = false;
      })
      .addCase(getSubjects.rejected, (state) => {
        state.status = false;
      })
      .addCase(getCoSubjects.pending, (state) => {
        state.status = true;
        state.co_subjects = [];
        state.co_total = null;
      })
      .addCase(getCoSubjects.fulfilled, (state, { payload }) => {
        state.co_subjects = payload.data;
        state.co_total = payload.meta.total;
        state.status = false;
      })
      .addCase(getCoSubjects.rejected, (state) => {
        state.status = false;
      });
  },
});

export const { setEditSubject, setEditCoSubject, setTableTitle } =
  subjectSlice.actions;

export default subjectSlice.reducer;
