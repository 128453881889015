import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router";

import { getCoSubjects, deleteSubject } from "actions/subjectsActions";
import { ComponentHeader, TableComponent } from "components";
import { setEditCoSubject } from "features/subjectSlice";
import {
  CONSTANTS,
  TABLE,
  TABLE_MIN_PAGE,
  TABLE_PER_PAGE_COUNT,
} from "constants/general";
import ModalWindow from "components/ModalWindow";
import CreateEditCoSubject from "./createEditCoSubject";
import { clearQuestions } from "features/questionsSlice";
import { useSearchParams } from "hooks/useSearchParams";

export const CoSubjects = () => {
  const [page, setPage] = useState(TABLE_MIN_PAGE);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [goToPage, setGoToPage] = useState("");
  const [isOpenCreateEdit, setIsOpenCreateEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { search } = useSearchParams();
  const [backPath, setBackPath] = useState("");

  const navigate = useNavigate();

  const coSubjects = useSelector((state) => state.subjects.co_subjects);
  const status = useSelector((state) => state.subjects.status);
  const total = useSelector((state) => state.subjects.co_total);
  const title = coSubjects?.[0]?.parent?.title;

  const { subject_id, id } = useParams();
  const dispatch = useDispatch();

  const handleCreate = () => {
    dispatch(setEditCoSubject({}));
    setIsOpenCreateEdit(true);
    setIsEdit(false);
  };

  const handleOpenChild = (elem) => {
    dispatch(clearQuestions());
    navigate(`department-questions/${elem?.id}`);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= total) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getCoSubjects({ id: subject_id, page, search }));
  }, [page, subject_id, search]);

  useEffect(() => {
    setBackPath(`/departments/subject/${id}`);
  }, [id]);

  return (
    <div className="table_page_body_container">
      <ComponentHeader
        create
        handleCreateEdit={handleCreate}
        back
        backPath={backPath}
      />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          message={CONSTANTS.DELETE_DEPARTMENT_MESSAGE}
          deleteRequest={deleteSubject}
          currentPage={page}
          getId={subject_id}
          isChild={true}
        />
      )}
      <div className="create_edit">
        {isOpenCreateEdit && (
          <div className="popup">
            <CreateEditCoSubject
              isEdit={isEdit}
              close={() => setIsOpenCreateEdit(false)}
              page={page}
            />
          </div>
        )}
      </div>
      <TableComponent
        page={page}
        setPage={setPage}
        perPageCount={TABLE_PER_PAGE_COUNT}
        data={coSubjects}
        title={title}
        total={total}
        subtitle={TABLE.private_subject}
        setEditContent={setEditCoSubject}
        setDeleteId={setDeleteId}
        setOpen={setOpen}
        setIsOpenCreateEdit={setIsOpenCreateEdit}
        setIsEdit={setIsEdit}
        goToSpecificPage={goToSpecificPage}
        handleGoToPageChange={handleGoToPageChange}
        goToPage={goToPage}
        status={status}
        handleOpenChild={handleOpenChild}
      />
    </div>
  );
};
