import { useState } from "react";

import { PrimaryTable } from "components/PrimaryTable";
import {
  TABLE_PER_PAGE_COUNT,
  TABLE_MIN_PAGE,
  DEFAULT_DATE_REVERSED_SORT_PARAMS,
  defaultReversedSortParams,
  ACTIVATION,
} from "constants/general";
import { useNavigate } from "react-router";
import { getColumns } from "./columns";
import { Filter } from "./components/filter";
import { DEFAULT_DATE_SORT_PARAMS } from "constants/general";
import { sanitize } from "dompurify";

export const QuestionTable = ({
  data,
  setDeleteId,
  setOpen,
  totalCount,
  handleApplyFilter,
  handleRemoveFilter,
  handleClearFilters,
  appliedFilters,
  page,
  setPage,
  sort,
  setSort,
  isQuestion,
  handleEdit,
  isDiagnosticQuiz,
  isDepartment,
  handleToggleChange,
  showToggle,
  loading,
  handleClickToRow,
  isFixedQuiz,
}) => {
  const [goToPage, setGoToPage] = useState("");
  const [sortModelState, setSortModelState] = useState(
    DEFAULT_DATE_SORT_PARAMS
  );

  const navigate = useNavigate();

  const totalPageNumber = Math.ceil(totalCount / TABLE_PER_PAGE_COUNT);

  const handleCreate = () => {};

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= totalPageNumber) {
      setPage(newPage);
    }
  };

  const handleRowClick = (param) => {
    const link = isDiagnosticQuiz
      ? `/diagnostic-quiz/diagnostic-question/${param.id}`
      : isDepartment
      ? `/departments/question/${param.id}`
      : `/questions/${param.id}`;
    navigate(link, { state: { page } });
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length) {
      setSortModelState(sortModel);
      setSort({ order_by: sortModel[0].field, order: sortModel[0].sort });
    } else {
      setSortModelState(DEFAULT_DATE_REVERSED_SORT_PARAMS);
      setSort(defaultReversedSortParams);
    }
  };

  const openDeleteModal = (row) => {
    setDeleteId(row.id);
    setOpen(true);
  };

  const getHighlight = (str) => {
    const htmlText = str?.replaceAll("{{", "<b>")?.replaceAll("}}", "</b>");
    return { __html: sanitize(htmlText) };
  };

  const highlightedData = data?.map((row) => {
    return {
      ...row,
      question: <span dangerouslySetInnerHTML={getHighlight(row.question)} />, // Assuming the first column is 'firstColumn'
    };
  });

  const columns = getColumns(
    openDeleteModal,
    handleEdit,
    handleToggleChange,
    showToggle
  );

  const newColumns = columns.filter((column) => {
    if ((isDiagnosticQuiz || isFixedQuiz) && column.field === ACTIVATION) {
      return false;
    }
    return true;
  });

  return (
    <div>
      <PrimaryTable
        filterComponent={
          <Filter
            applyFilter={handleApplyFilter}
            appliedFilters={appliedFilters}
            isQuestion={isQuestion}
            isDiagnosticQuiz={isDiagnosticQuiz}
            setTablePage={setPage}
            isFixedQuiz={isFixedQuiz}
          />
        }
        loading={loading}
        rows={highlightedData?.length ? highlightedData : []}
        columns={newColumns}
        handlePageChange={handlePageChange}
        handleGoToPageChange={handleGoToPageChange}
        totalPageNumber={totalPageNumber}
        handleCreate={handleCreate}
        page={page}
        goToPage={goToPage}
        goToSpecificPage={goToSpecificPage}
        handleRowClick={handleClickToRow ? handleClickToRow : handleRowClick}
        handleSortModelChange={handleSortModelChange}
        sortModel={sortModelState}
        handleClearFilters={handleClearFilters}
        handleRemoveFilter={handleRemoveFilter}
        appliedFilters={appliedFilters}
        hasPagination={totalCount > TABLE_PER_PAGE_COUNT}
      />
    </div>
  );
};
