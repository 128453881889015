import { createAsyncThunk } from "@reduxjs/toolkit";
import { aboutApi } from "service/aboutApi";

export const getAboutContent = createAsyncThunk(
  "/nordas/getAboutContent",
  async (_, { rejectWithValue }) => {
    try {
      const res = await aboutApi.getAboutContent();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateAboutPage = createAsyncThunk(
  "/nordas/updateAboutContent",
  async ({ body }, { rejectWithValue, dispatch }) => {
    try {
      await aboutApi.updateAboutPage(body);
      return dispatch(getAboutContent());
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)