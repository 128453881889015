import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  MenuItem,
  InputLabel,
  Popover,
  OutlinedInput,
} from "@mui/material";
import {
  ApplyButton,
  FilterButton,
  FormWrapper,
  StyledFormControl,
} from "assets/filter/styled";
import {
  CONSTANTS,
  FILTER_INPUT_SIZE_SMALL,
  TABLE_MIN_PAGE,
} from "constants/general";
import {
  FILTERS_VALUE_CHANGE,
  FILTER_CHANGE,
  FILTER_CONSTANTS,
} from "./constants";
import { InfiniteScrollAutocomplete } from "components";
import { getTopics } from "actions/departmentAcions";
import {
  getCategoriesByTopicId,
  getCategoriesByParentId,
} from "actions/categoryActions";
import { FilterIcon } from "components/icons";
import { useParams } from "react-router";
import { usePathPattern } from "hooks/useCurrentPath";
import { PRIVATE_SUBJECT_QUESTIONS_PATH } from "constants/pathnames";
import { clearCategoriesList } from "features/categorySlice";

export const Filter = ({
  applyFilter,
  appliedFilters,
  isQuestion,
  isDiagnosticQuiz,
  setTablePage,
  isFixedQuiz,
}) => {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.data);
  const categories = useSelector((state) => state.category.categoriesByTopic);
  const categoriesByParent = useSelector(
    (state) => state.category.categoriesByParentId
  );
  const params = useParams();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [privateSearchTerm, setPrivateSearchTerm] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(TABLE_MIN_PAGE);
  const [filteredPrivateOptions, setFilteredPrivateOptions] = useState([]);
  const [privateHasMore, setPrivateHasMore] = useState(true);
  const [selectedSubjects, setSelectedSubjects] = useState({
    topic: null,
    subject: null,
  });

  const pathPattern = usePathPattern([PRIVATE_SUBJECT_QUESTIONS_PATH]);

  const isPrivateSubjectQuestionsPage =
    pathPattern === PRIVATE_SUBJECT_QUESTIONS_PATH;

  const list = [
    {
      column: FILTER_CONSTANTS.TOPIC,
      queryKey: FILTER_CONSTANTS.TOPIC_ID,
      values: [],
      options: topics,
    },
    {
      column: FILTER_CONSTANTS.SUBJECT,
      queryKey: FILTER_CONSTANTS.SUBJECT_ID,
      values: [],
      options: categories,
    },
    {
      column: FILTER_CONSTANTS.CATEGORY,
      queryKey: FILTER_CONSTANTS.CATEGORY_ID,
      values: [],
      options: categoriesByParent,
      hide: isFixedQuiz,
    },
    {
      column: FILTER_CONSTANTS.LEVEL,
      queryKey: FILTER_CONSTANTS.LEVEL_KEY,
      valueName: "level",
      rejectPrefix: "In",
      values: [
        { name: "Medium", value: "medium" },
        { name: "Difficult", value: "difficult" },
      ],
      options: [
        { title: "Medium", id: "medium" },
        { title: "Difficult", id: "difficult" },
      ],
      hide: isFixedQuiz,
    },
    {
      column: FILTER_CONSTANTS.TYPE,
      queryKey: FILTER_CONSTANTS.TYPE_KEY,
      valueName: "type",
      rejectPrefix: "In",
      values: [
        { name: "One correct answer", value: "one_correct_answer" },
        { name: "Multiple correct answers", value: "multiple_correct_answers" },
        { name: "Pairing answers", value: "pairing_answers" },
        { name: "Matching order answers", value: "matching_order_answers" },
      ],
      options: [
        { title: "One correct answer", id: "one_correct_answer" },
        { title: "Multiple correct answers", id: "multiple_correct_answers" },
        { title: "Pairing answers", id: "pairing_answers" },
        { title: "Matching order answers", id: "matching_order_answers" },
      ],
    },
    {
      column: FILTER_CONSTANTS.STATUS,
      queryKey: FILTER_CONSTANTS.STATUS_KEY,
      valueName: "complete",
      rejectPrefix: "In",
      values: [
        { name: "Incomplete", value: false },
        { name: "Complete", value: true },
      ],
      options: [
        { title: "Incomplete", id: 0 },
        { title: "Complete", id: 1 },
      ],
    },
    {
      column: FILTER_CONSTANTS.ACTIVATION,
      queryKey: FILTER_CONSTANTS.ACTIVATION_KEY,
      valueName: "active",
      rejectPrefix: "In",
      values: [
        { name: "Active", value: false },
        { name: "Inactive", value: true },
      ],
      options: [
        { title: "Inactive", id: 0 },
        { title: "Active", id: 1 },
      ],
      hide: isFixedQuiz,
    },
  ];

  const filterList = list.filter((element) => {
    if (element.hide) return false;
    if (!isQuestion) {
      if (
        element.column === FILTER_CONSTANTS.TOPIC ||
        element.column === FILTER_CONSTANTS.SUBJECT ||
        (isDiagnosticQuiz && element.column === FILTER_CONSTANTS.ACTIVATION)
      ) {
        return false;
      }
      if (
        isPrivateSubjectQuestionsPage &&
        element.column === FILTER_CONSTANTS.CATEGORY
      ) {
        return false;
      }
    }
    return true;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchCategoriesByTopicId = (search = "") => {
    dispatch(
      getCategoriesByTopicId({
        id: selectedSubjects.topic,
        params: {
          page,
        },
      })
    );
  };

  const fetchCategoriesByParentId = () => {
    dispatch(
      getCategoriesByParentId({
        id: selectedSubjects.subject || params.category_id,
        page,
        searchTerm,
      })
    );
  };

  const handleSearchChange = (event, name) => {
    if (name === FILTER_CONSTANTS.SUBJECT) {
      setSearchTerm(event.target.value);
    } else {
      setPrivateSearchTerm(event.target.value);
    }
    setPage(TABLE_MIN_PAGE);
    setFilteredOptions([]);
    setFilteredPrivateOptions([]);
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const handleFilterChange = (event, filterIndex, queryKey, rejectPrefix) => {
    setPrivateSearchTerm(null);
    setSearchTerm(null);
    const target = { ...event, name: FILTER_CHANGE[queryKey] };

    const newFilters = [...selectedFilters];
    newFilters[filterIndex] = {
      ...newFilters[filterIndex],
      ...target,
      queryKey,
      rejectPrefix,
    };

    const data = {
      [FILTER_CONSTANTS.TOPIC_ID]: topics,
      [FILTER_CONSTANTS.SUBJECT_ID]: categories.data,
      [FILTER_CONSTANTS.CATEGORY_ID]: categoriesByParent,
    };

    newFilters[filterIndex].valueName = FILTERS_VALUE_CHANGE[queryKey]({
      target,
      setSelected: setSelectedSubjects,
      newFilters,
      filterIndex,
      data: data[queryKey],
      event,
    });
    setSelectedFilters(newFilters);
  };

  const handleApplyFilter = () => {
    applyFilter(selectedFilters);
    setTablePage(CONSTANTS.FIRST_PAGE);
    handleClose();
  };

  const handleClear = (filterIndex) => {
    const newFilters = [...selectedFilters];
    newFilters[filterIndex] = null;
    setSelectedFilters(newFilters);
  };

  const handleFindElementByQuery = (key) => {
    const foundElement = selectedFilters?.find(
      (elem) => elem?.queryKey === key
    )?.value;
    return foundElement ?? "";
  };

  useEffect(() => {
    if (categories.data) {
      setFilteredOptions(() => categories.data);
      setHasMore(!(page === categories?.meta?.last_page));
    } else {
      setFilteredOptions(() => []);
    }
  }, [categories]);

  useEffect(() => {
    if (!!categoriesByParent) {
      setFilteredPrivateOptions(() => categoriesByParent);
      setPrivateHasMore(!(page === categoriesByParent?.meta?.last_page));
    }
  }, [categoriesByParent]);

  useEffect(() => {
    if (selectedSubjects.topic) {
      fetchCategoriesByTopicId(searchTerm ?? "");
    } else {
      dispatch(clearCategoriesList());
    }
  }, [searchTerm, selectedSubjects.topic, page]);

  useEffect(() => {
    if (selectedSubjects.subject || params.topic_id) {
      fetchCategoriesByParentId(privateSearchTerm ?? "");
    }
  }, [privateSearchTerm, selectedSubjects.subject, page]);

  useEffect(() => {
    setSelectedFilters(appliedFilters);

    const findTopicFilter = appliedFilters.find(
      (elem) => elem?.queryKey === FILTER_CONSTANTS.TOPIC_ID
    );

    if (!findTopicFilter) {
      setSelectedSubjects((prev) => ({ ...prev, topic: null }));
    }
  }, [appliedFilters]);

  useEffect(() => {
    dispatch(getTopics({ search: "" }));
  }, []);

  useEffect(() => {
    setSelectedFilters((prev) => prev?.slice(0, 1));
  }, [selectedSubjects.topic]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
      }}
    >
      <FilterButton variant="outlined" onClick={handleClick}>
        <FilterIcon /> {CONSTANTS.FILTER}
      </FilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="add_gap"
      >
        <FormWrapper>
          {filterList.map((filter, idx) => {
            return (
              <Fragment key={filter.queryKey + idx}>
                {filter.column === FILTER_CONSTANTS.SUBJECT ||
                filter.column === FILTER_CONSTANTS.CATEGORY ? (
                  <StyledFormControl fullWidth size="small">
                    <InfiniteScrollAutocomplete
                      handleLoadMore={handleLoadMore}
                      hasMore={
                        filter.column === FILTER_CONSTANTS.SUBJECT
                          ? hasMore
                          : privateHasMore
                      }
                      searchTerm={
                        filter.column === FILTER_CONSTANTS.SUBJECT
                          ? searchTerm
                          : privateSearchTerm
                      }
                      options={
                        filter.column === FILTER_CONSTANTS.SUBJECT
                          ? filteredOptions
                          : filteredPrivateOptions
                      }
                      handleClear={() => handleClear(idx)}
                      handleSearchChange={(event) =>
                        handleSearchChange(event, filter.column)
                      }
                      isFilter={true}
                      size={FILTER_INPUT_SIZE_SMALL}
                      handleChange={(_, value) =>
                        handleFilterChange(value, idx, filter.queryKey)
                      }
                      value={selectedFilters?.[idx]?.value ?? ""}
                      label={filter.column}
                    />
                  </StyledFormControl>
                ) : (
                  <StyledFormControl fullWidth size="small">
                    <InputLabel
                      htmlFor={`outlined-age-native-simple-${idx}`}
                      className="custom-label"
                    >
                      {filter.column}
                    </InputLabel>
                    <Select
                      name={filter.column}
                      value={handleFindElementByQuery(filter.queryKey)}
                      onChange={(e) =>
                        handleFilterChange(
                          e.target,
                          idx,
                          filter?.queryKey,
                          filter?.rejectPrefix
                        )
                      }
                      className="custom-select"
                      input={
                        <OutlinedInput
                          name={filter.column}
                          label={filter.column}
                          id={`outlined-age-native-simple-${idx}`}
                          className="custom-input"
                        />
                      }
                    >
                      {filter.options.map((filterItem) => (
                        <MenuItem key={filterItem.id} value={filterItem.id}>
                          {filterItem.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                )}
              </Fragment>
            );
          })}
          <ApplyButton onClick={handleApplyFilter}>
            {CONSTANTS.APPLY}
          </ApplyButton>
        </FormWrapper>
      </Popover>
    </div>
  );
};
