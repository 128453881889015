import React, { useLayoutEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import { TextFieldNormalColorsDisabled } from "./styled";
import {
  CONSTANTS,
  FILTER_INPUT_SIZE_MEDIUM,
  FILTER_INPUT_TOOLTIP_REQUIRED_CHARS_COUNT,
} from "constants/general";

export const InfiniteScrollAutocomplete = ({
  hasMore,
  options,
  handleLoadMore,
  handleSearchChange,
  value,
  handleChange,
  label = "Options",
  searchTerm,
  error,
  helperText,
  handleClear,
  hasNotNone,
  notClearable,
  disabled,
  isFilter,
  size = FILTER_INPUT_SIZE_MEDIUM,
}) => {
  const [state, setState] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showOptionTooltips, setShowOptionTooltips] = useState({});

  let timeout;

  const handleSearch = (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => handleSearchChange(e), 700);
  };

  const autoCompleteOptions = options.length
    ? hasNotNone
      ? options.map((el) => ({ value: el.id, name: el.title }))
      : [...options.map((el) => ({ value: el.id, name: el.title }))]
    : [];

  useLayoutEffect(() => {
    const selectedOption = options.find((el) => el.id === value);
    const selectedValue = {
      value,
      name: selectedOption?.title,
    };
    setState(selectedOption ? selectedValue : null);

    // Update tooltip visibility based on selected value
    if (window !== "undefined") {
      const label = document.getElementById("autocomplete-input");
      if (label) {
        setShowTooltip(label.scrollWidth > label.clientWidth);
      }
    }
  }, [value, options]);

  useLayoutEffect(() => {
    if (searchTerm !== null) {
      const selectedValue = {
        value: "",
        name: searchTerm,
      };
      setState(selectedValue);
    }
  }, [searchTerm]);

  const checkOptionTooltip = (optionId) => {
    if (window !== "undefined") {
      const optionElement = document.getElementById(`option-${optionId}`);
      if (optionElement) {
        setShowOptionTooltips((prev) => ({
          ...prev,
          [optionId]: optionElement.scrollWidth > optionElement.clientWidth,
        }));
      }
    }
  };

  return (
    <Autocomplete
      fullWidth
      value={state}
      options={autoCompleteOptions}
      disabled={disabled}
      className={isFilter && "custom-infinity-select"}
      size={size}
      onInput={(e) => handleSearch(e)}
      onChange={(event, newValue, reason) => {
        if (reason === CONSTANTS.CLEAR) {
          setState(null);
        }
        if (newValue === null) {
          handleClear(); // Clear selection
        } else {
          handleChange(event, newValue);
        }
      }}
      disablePortal
      isOptionEqualToValue={(option, value) => option.value === value.value}
      freeSolo={false}
      renderInput={(params) => (
        <Tooltip
          title={
            state?.name?.length > FILTER_INPUT_TOOLTIP_REQUIRED_CHARS_COUNT
              ? state?.name
              : ""
          }
          arrow
        >
          <TextFieldNormalColorsDisabled
            {...params}
            id="autocomplete-input"
            label={label}
            isselected={(!!state).toString()}
            error={error}
            size={size}
            disabled={disabled}
            helperText={helperText}
            variant="outlined"
            InputLabelProps={{
              shrink: !!state || params.inputProps.value ? true : undefined,
              className: isFilter && "custom-infinity-label",
            }}
            InputProps={{
              ...params.InputProps,
              className: `${
                isFilter ? "custom-infinity-input" : ""
              } truncated-text`,
            }}
          />
        </Tooltip>
      )}
      getOptionLabel={(option) => option.name}
      disableClearable={notClearable}
      clearable="true"
      ListboxProps={{
        onScroll: (event) => {
          const listbox = event.target;
          if (
            listbox.scrollTop + listbox.clientHeight === listbox.scrollHeight &&
            hasMore
          ) {
            handleLoadMore();
          }
        },
      }}
      renderOption={(props, option) => (
        <Tooltip
          className="select-tooltip-option"
          title={showOptionTooltips[option.value] ? option.name : ""}
          arrow
        >
          <li
            {...props}
            id={`option-${option.value}`}
            className="select-option-truncated-text"
            onMouseEnter={() => checkOptionTooltip(option.value)}
          >
            {option.name}
          </li>
        </Tooltip>
      )}
    />
  );
};
