import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";

import { coSubjectsSchema } from "./coSubjectSchema";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { useActionHelper } from "hooks/useActionHelper";
import { CONSTANTS } from "constants/general";
import { updateSubject, createSubject } from "actions/subjectsActions";
import { submitForm } from "helpers/formikDataCollect";
import { Button, TextField } from "@mui/material";
import {
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { SaveButton } from "components/Button";
import { Close } from "@mui/icons-material";
import useOutsideClick from "hooks/useOutsideClick";

const CreateEditCoSubject = ({ close, isEdit, page }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const subjectContent = useSelector((state) => state.subjects.co_subject_edit);

  const [initialValues, validation] = formikValidation(coSubjectsSchema);

  const modalRef = useRef();

  useOutsideClick(modalRef, close);

  const [createCoSubject, editSubject] = useActionHelper({
    createNewItemApiCall: createSubject,
    updateItemApiCall: updateSubject,
    editId: subjectContent?.id,
    isEdit: true,
    topicId: params.id,
    subjectId: params.subject_id,
    page,
    close,
  });

  const apiCall = isEdit ? editSubject : createCoSubject;

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      submitForm({
        values,
        dispatch,
        apiCall,
        editContent: editSubject,
      });
    },
  });

  useEffect(() => {
    if (subjectContent) {
      coSubjectsSchema.forEach((elem) => {
        formik.setFieldValue(elem.name, subjectContent[elem.name]);
      });
    }
    formik.setFieldValue("id", subjectContent?.id);
    formik.setFieldValue("parent_id", params.subject_id);
    formik.setFieldValue("topic_id", subjectContent?.topic_id || params?.id);
  }, [subjectContent]);

  return (
    <div ref={modalRef} className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="cancel_button">
          <Button onClick={close}>
            <Close />
          </Button>
        </div>
        <div className="field_Container">
          <TextField
            autoFocus
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.title}
            name={FORM_ITEM_NAMES_OBJ.title}
            label={CONSTANTS.TITLE}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.text}
            value={formik.values[FORM_ITEM_NAMES_OBJ.title]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
            })}
          />
        </div>
        <SaveButton />
      </form>
    </div>
  );
};

export default CreateEditCoSubject;
