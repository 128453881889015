import { styled } from "@mui/material";
import {
  Box,
  Table,
  TableCell,
  TableRow,
} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "16px 24px",
}));

export const TableCellWithoutBorder = styled(TableCell)(({ theme }) => ({
  padding: "16px 24px",
  border: "0",
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  marginLeft: theme.spacing(2.5),
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 500 ,
}));

export const SubtitleRow = styled(TableRow)(({ TableRow }) => ({
  backgroundColor: "#A55AA01A",
}));

export const ElementRow = styled(TableRow)(({ TableRow }) => ({
  cursor: "pointer",
}));