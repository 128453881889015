import { createAsyncThunk } from "@reduxjs/toolkit";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { fixedQuizzesApi } from "service/fixedQuizzesApi";
import { defaultSortParams } from "constants/general";
import { questionsApi } from "service/questionsApi";
import { getFixedQuizQuestions } from "./questionsActions";

export const getFixedQuizzes = createAsyncThunk(
  "/nordas/getFixedQuizzes",
  async (params, { rejectWithValue }) => {
    try {
      const res = await fixedQuizzesApi.getFixedQuizzes(params);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createFixedQuiz = createAsyncThunk(
  "/nordas/createFixedQuiz",
  async ({ data, appliedFilterParams }, { rejectWithValue, dispatch }) => {
    const params = {
      topic_id: data.topic,
      category_id: data.category,
    };
    try {
      await fixedQuizzesApi.createFixedQuiz(params);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      dispatch(
        getFixedQuizzes({ ...defaultSortParams, ...appliedFilterParams })
      );
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteFixedQuiz = createAsyncThunk(
  "/nordas/deleteFixedQuiz",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      await fixedQuizzesApi.deleteFixedQuiz({ id });
      dispatch(getFixedQuizzes({ ...defaultSortParams, page }));
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const changeFixedQuizActivation = createAsyncThunk(
  "/nordas/changeFixedQuizActivation",
  async ({ id, page, body }, { rejectWithValue, dispatch }) => {
    try {
      await fixedQuizzesApi.changeFixedQuizActivation({ id, body });
      dispatch(getFixedQuizzes({ ...defaultSortParams, page }));
      Notify(NOTIFY.type.success, NOTIFY.message.update);
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const deleteFixedQuizQuestion = createAsyncThunk(
  "/nordas/deleteFixedQuestion",
  async ({ id, params, page }, { dispatch, rejectWithValue }) => {
    try {
      await questionsApi.deleteQuestion(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getFixedQuizQuestions({ ...defaultSortParams, page, params }));
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const changeFixedQuizStatus = createAsyncThunk(
  "changeFixedQuizStatus",
  async ({ id, data, page }, { rejectWithValue, dispatch }) => {
    try {
      await fixedQuizzesApi.changeFixedQuizStatus(id, data);
      Notify(NOTIFY.type.success, NOTIFY.message.status_change);
      dispatch(getFixedQuizzes({ ...defaultSortParams, page }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
