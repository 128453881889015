export const CONSTANTS = {
  MEDIA_CONTENT: "Media Content",
  MULTIMEDIA_CONTENT: "Multimedia Content",
  IMAGES: "Images",
  IMAGES_FIELD: "images",
  REQUIRED: "Required",
  RELATED_PROJECTS: "Related projects",
  RELATED_BLOGS: "Related blogs",
  PAGE_REMOVED_SUCCESS: "Page removed successfully",
  CATEGORY: "Category",
  MODAL_DELETE: "Delete",
  DELETE: "delete",
  WARNING: "Warning Message",
  WARNING_MESSAGE:
    "Please note: You must complete both the question options and select the correct answers before leaving this page. Exiting now will result in the incomplete data being discarded.",
  ADD: "add",
  UPDATE: "update",
  SUCCESS: "success",
  REMOVED_SOCIAL_LINK: "Removed social link",
  REMOVED_CATEGORY: "Removed category",
  ADDED_CATEGORY: "Added category",
  UPDATED_CATEGORY: "Updated category",
  TITLE: "Title",
  NAME: "Name",
  ENTER_TITLE: "Enter title",
  ICON: "Icon",
  TOPIC: "Topic",
  DESCRIPTION: "Description",
  ENTER_DESCRIPTION: "Enter description",
  META_TITLE: "Meta title",
  ENTER_META_TITLE: "Enter meta title",
  META_DESCRIPTION: "Meta description",
  ENTER_META_DESCRIPTION: "Enter meta description",
  SLUG: "Slug",
  ENTER_SLUG: "Enter slug",
  SHORT_DESCRIPTION: "Short description",
  ENTER_SHORT_DESCRIPTION: "Enter short description",
  DEADLINE: "Deadline",
  ENTER_DEADLINE: "Enter deadline",
  LOCATION: "Location",
  ENTER_LOCATION: "Enter location",
  CONTACT_INFORMATION: "Contact information",
  ENTER_CONTACT_INFORMATION: "Enter contact information",
  IMAGE_INFORMATION: "Image information",
  ENTER_TEXT_ALONGSIDE_IMAGES: "Enter text alongside images",
  IMAGE: "Image",
  ENTER_IMAGE: "Enter image",
  ENTER_IMAGES: "Enter images",
  ENTER_RELATED_BLOGS: "Enter related blogs separated by comma",
  MAIN_TITLE: "Main title",
  ENTER_MAIN_TITLE: "Enter main title",
  SERVICE_TYPE: "Service type",
  ENTER_SERVICE_TYPE: "Enter service type",
  ENTER_NAME: "Enter name",
  CLIENT: "Client",
  ENTER_CLIENT_NAME: "Enter client name",
  INDUSTRY: "Industry",
  ENTER_CLIENT_INDUSTRY: "Enter client industry",
  APP_STORE: "App store",
  ENTER_CLIENT_APP_STORE: "Enter client app store",
  GOOGLE_PLAY: "Google play",
  ENTER_CLIENT_GOOGLE_PLAY: "Enter client google play",
  WEBSITE: "Website",
  ENTER_CLIENT_WEBSITE: "Enter client website",
  CATEGORIES: "Categories",
  ENTER_CATEGORIES: "Enter categories separated by comma",
  SERVICES: "Services",
  ENTER_SERVICES: "Enter services separated by comma",
  OTHER_SERVICES: "Other services",
  ENTER_OTHER_SERVICES: "Enter other services",
  ENTER_RELATED_PROJECTS: "Enter related projects separated by comma",
  LAST_NAME: "Last name",
  ENTER_LAST_NAME: "Enter last name",
  TEXT: "Text",
  ENTER_TEXT: "Enter text",
  POSITION: "Position",
  ENTER_POSITION: "Enter position",
  PROJECT: "Project",
  PROJECT_TYPE: "Project type custom",
  PROJECT_NAME: "Project name",
  ENTER_PROJECT_NAME: "Enter project name",
  ENTER_PROJECT: "Enter project",
  ADDRESS: "Address",
  ENTER_ADDRESS: "Enter address",
  PHONE_NUMBER: "Phone number",
  ENTER_PHONE_NUMBER: "Enter phone number",
  EMAIL: "Email",
  ENTER_EMAIL_ADDRESS: "Enter email address",
  WHAT_WE_DO: "What We Do",
  ENTER_WHAT_WE_DO: "Enter What We Do",
  WHAT_WE_DONT: "What We Don't",
  ENTER_WHAT_WE_DONT: "Enter What We Don't",
  SURNAME: "Surname",
  ENTER_SURNAME: "Enter surname",
  OLD_IMAGES: "Old Images",
  LINK: "Link",
  ENTER_LINK: "Enter link",
  FAILED_TO_DETECT: "failed to detect the orientation",
  OTHER_SERVICE: "Other",
  VALID_TEXT: "text",
  YEREVAN_KIMITAS: "Yerevan, Komitas 15/2",
  CELL_PHONE_NUMBER: "+37477123456",
  BLOG_CREATED_SUCCESSFULLY: "Blog created successfully",
  ADD_BUTTON: "+ Add",
  CHANGE_PASSWORD: "Change password",
  LOG_OUT: "Log out",
  OLD_PASSWORD: "Old password",
  PASSWORD: "Password",
  CURRENT_PASSWORD: "Current password",
  NEW_PASSWORD: "New password",
  CONFIRM_PASSWORD: "Confirm password",
  CANCEL: "Cancel",
  CONFIRM: "Confirm",
  ARE_YOU_SURE_DELETE: "Are you sure you want to delete this?",
  SEARCH: "search",
  SAVE: "Save",
  PASSWORD_CHANGE_SUCCESS: "Password changed successfully",
  ABOUT_NEXTSTACK: "About NextStack",
  CONTACT_DETAILS: "Contact Details",
  SUBTITLE: "Subtitle",
  ENTER_SUBTITLE: "Enter Subtitle",
  SUBTITLE_DESCRIPTION: "Subtitle Description",
  ENTER_SUBTITLE_DESCRIPTION: "Enter Subtitle Description",
  ITEMS: "Item",
  ENTER_ITEMS: "Enter Items",
  DELETE_DEPARTMENT_MESSAGE:
    "Deleting this section will permanently remove all associated data. Are you sure you want to proceed?",
  DELETE_DIAGNOSTIC_QUIZ:
    "Deleting this quiz will permanently remove all associated data. Are you sure you want to proceed?",
  DELETE_QUESTION_MESSAGE:
    "Deleting this question will permanently remove all associated data. Are you sure you want to proceed?",
  EMAIL_VALIDATION_MESSAGE: "Please enter valid email",
  INVALID_PASSWORD_MESSAGE: "Invalid password",
  BACK_BUTTON: "Back",
  APPLY: "Apply",
  FILTER: "Filter",
  QUESTION_DESCRIPTION: "Enter Question Description",
  TYPE: "Select Question Type",
  ENTER_TYPE: "Enter Type",
  LEVEL: "Select Question Difficulty",
  ENTER_LEVEL: "Enter Level",
  OPTIMAL_TIME: "Optimal Time",
  ENTER_OPTIMAL_TIME: "Enter Optimal Time",
  QUESTION: "Question",
  ENTER_QUESTION: "Enter Question",
  ANSWERS: "Answers",
  ENTER_ANSWERS: "Enter Answers",
  SECTION: "Section",
  ENTER_SECTION: "Select Question Section",
  SUBJECT: "Select Question Subject",
  PRIVATE_SUBJECT: "Select Question Private Subject",
  SECTION_NAME: "Section",
  SUBJECT_NAME: "Subject",
  PRIVATE_SUBJECT_NAME: "Private Subject",
  TYPE_NAME: "Type",
  DIFFICULTY_NAME: "Difficulty",
  LEVEL_NAME: "Level",
  QUESTION_NAME: "Question",
  ANSWERS_NAME: "Answer Options",
  STATUS_NAME: "Status",
  COMPLETE_STATUS: "Complete",
  INCOMPLETE_STATUS: "Incomplete",
  ACTIVE_VALUE: 1,
  INACTIVE_VALUE: 0,
  MAX_QUESTIONS_COUNT_MESSAGE:
    "The maximum question limit for the diagnostic quiz is set at 20.",
  MAX_FIXED_QUIZ_QUESTIONS_COUNT_MESSAGE:
    "The maximum question limit for the fixed quiz is set at 70.",
  MAX_QUESTIONS_COUNT: 20,
  MAX_FIXED_QUESTIONS_COUNT: 70,
  CREATE: "Create",
  EDIT: "Edit",
  REMOVE: "Remove",
  FIRST_PAGE: 1,
  TYPE_WARNING_MESSAGE:
    "You're attempting to modify the question type, which will result in the removal of previously added options. Are you sure you want to proceed?",
  CLEAR: "clear",
  EXPLANATION_TYPE: "Explanation Type",
  EXPLANATION_FILE: "Explanation File",
  EXPLANATION_TEXT: "Explanation Text",
  SHOW_EXPLANATIONS: "Show Explanations",
  VIDEO_KEY: "video",
  AUDIO_KEY: "audio",
  IMAGE_KEY: "image",
  TEXT_KEY: "text",
  STRING_TYPE: "string",
  UPLOAD: "Upload",
  SUBSCRIPTIONS: "Subscriptions",
};

export const USER_WARNING_MESSAGES = {
  delete:
    "Deleting the user will permanently remove all associated data. Are you sure you want to proceed?",
  block:
    "Blocking the user will permanently block the access of the user to the platform. Are you sure you want to proceed?",
  unblock:
    "Unblocking the user will permanently unblock the access of the user to the platform. Are you sure you want to proceed?",
};

export const fieldNamesObj = {
  projectId: "project_id",
  projectName: "project_name",
};

export const errMessagesObj = {
  requiredProjectId: "Project ID is required",
  requiredProjectName: "Project name is required",
};

export const ADD_ONE_TO_INDEX = 1;
export const HELPER_TEXT =
  "If you want a word or phrase to be highlighted in color, please put it in {{ }}";

export const DRAG_ITEM_INDEX = 1;
export const DRAG_HOVER_INDEX = 0;

export const LOGIN_PAGE = {
  welcome: "Welcome to Nor Das Admin!",
  sign_in_text: "Sign in to your account to get started",
  sign_in: "Sign in",
  email_address: "Email address",
  password: "Password",
  remember_me: "Remember me",
};

export const ACCESS_TOKEN = "access_token";

export const TABLE = {
  subject: "Subject",
  private_subject: "Private Subject",
  zero_value: 0,
  changed_page_count: 1,
  rtl: "rtl",
};

export const CREATE_PATHNAME = "create";
export const EDIT_PATHNAME = "edit";

export const PASSWORD_MESSAGES = {
  minCharacter: "Password must be at least 8 characters long",
  lowerCase: "Password must contain at least one lowercase letter",
  upperCase: "Password must contain at least one uppercase letter",
  number: "Password must contain at least one number",
  password_match: "Passwords must match",
};

export const TABLE_PER_PAGE_COUNT = 15;
export const TABLE_MIN_PAGE = 1;
export const SEARCH_KEY = "search";

export const SORT_MODE = {
  ASC: "asc",
  DESC: "desc",
};

export const defaultSortParams = {
  order_by: "created_at",
  order: SORT_MODE.DESC,
};

export const defaultReversedSortParams = {
  order_by: "created_at",
  order: SORT_MODE.ASC,
};

export const DEFAULT_DATE_SORT_PARAMS = [
  {
    field: "created_at",
    sort: SORT_MODE.DESC,
  },
];

export const DEFAULT_DATE_REVERSED_SORT_PARAMS = [
  {
    field: "created_at",
    sort: SORT_MODE.ASC,
  },
];

export const QUESTION_TYPES_VALUE = {
  one_answer: "one_correct_answer",
  multiple_answers: "multiple_correct_answers",
  pairing: "pairing_answers",
  matching: "matching_order_answers",
};

export const QUESTION_POSITION = {
  LEFT: "left",
  RIGHT: "right",
};

export const QUESTIONS_HINT_TEXT = {
  one_answer: "Choose the one correct answer from the options provided.",
  multiple_answers: "Select all correct answers from the options provided.",
  pairing: "Pair each option with its correct matching partner.",
  matching: "Arrange the options in their correct order of sequence.",
};

export const QUESTION_TYPES = [
  {
    id: 1,
    name: "One correct answer",
    value: QUESTION_TYPES_VALUE.one_answer,
  },
  {
    id: 2,
    name: "Multiple correct answers",
    value: QUESTION_TYPES_VALUE.multiple_answers,
  },
  {
    id: 3,
    name: "Pairing answers",
    value: QUESTION_TYPES_VALUE.pairing,
  },
  {
    id: 4,
    name: "Matching order answers",
    value: QUESTION_TYPES_VALUE.matching,
  },
];

export const QUESTION_LEVELS = [
  {
    id: 1,
    name: "Medium",
    value: "medium",
  },
  {
    id: 2,
    name: "Difficult",
    value: "difficult",
  },
];

export const QUIZ_TYPES = [
  {
    id: 1,
    name: "Fixed",
    value: "fixed",
  },
  {
    id: 2,
    name: "Generated",
    value: "generated",
  },
];

export const EXPLANATION_TYPES = [
  {
    id: 1,
    name: "Video",
    value: "video",
  },
  {
    id: 2,
    name: "Audio",
    value: "audio",
  },
  {
    id: 3,
    name: "Image",
    value: "image",
  },
];

export const EXPLANATION = "explanations";
export const ANSWER = "answer";
export const ANSWERS = "answers";
export const TOPIC_KEY = "topic";
export const SUBJECT_KEY = "subject";
export const CATEGORY_KEY = "category_id";
export const CATEGORY_ID_KEY = "category_id";
export const LEVEL_KEY = "level";
export const TYPE_KEY = "type";
export const QUESTION_KEY = "question";
export const MIN_LENGTH_ANSWERS = 5;
export const IS_ACTIVE_KEY = "is_active";
export const OPTIMAL_TIME_KEY = "optimal_time";
export const STATUS_INCOMPLETE_VALUE = 0;
export const STATUS_COMPLETE_VALUE = 1;
export const MIN_ANSWERS = 4;
export const MEDIA_KEY = "media";
export const DESCRIPTION_KEY = "description";

export const ROUTE_NAMES = {
  questions: "/questions",
};

export const FILTER_INPUT_SIZE_SMALL = "small";
export const FILTER_INPUT_SIZE_MEDIUM = "medium";
export const FILTER_INPUT_TOOLTIP_REQUIRED_CHARS_COUNT = 17;
export const ACTIVE_TOOLTIP_MESSAGE =
  "To activate the question, you need to fill it out";
export const ACTIVE_TOOLTIP_MESSAGE_QUIZ =
  "To activate the quiz, you need to fill it out";
export const DEACTIVATE = "Off";
export const ACTIVATE = "On";
export const ACTIVATION = "activation";
