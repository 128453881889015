import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "450px",
  },
}));

export const BootstrapDialogActions = styled(DialogActions)(() => ({
  border: "none !important",
  padding: "8px 21px 19px 16px !important",
}));

export const BootstrapDialogContent = styled(DialogContent)(() => ({
  border: "none !important",
}));

export const ConfirmButton = styled(Button)(() => ({
  border: "1px solid #F58C69",
  padding: "5px 15px",
  fontSize: "13px",
  fontWeight: 400,
  color: "#F58C69 !important",
}));

export const RefuseButton = styled(Button)(() => ({
  backgroundColor: "#F58C69",
  border: "1px solid #F58C69",
  padding: "5px 15px",
  fontSize: "13px",
  fontWeight: 400,
  color: "white !important",

  ":hover": {
    backgroundColor: "#F58C69 !important",
  },
}));

export const StyledDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: "18px",
  textTransform: "uppercase",
  border: "none !important",
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "8px",
  top: "8px",
  color: theme.palette.grey[500],
}));
