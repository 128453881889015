import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material/styles";
import { dataGridTheme } from "./dataGridTheme";
import { PrimaryPagination } from "components/PrimaryPagination";
import { AppliedFilters } from "./AppliedFilters";
import {
  ColumnUnsortedIcon,
  ColumnSortedAscendingIcon,
  ColumnSortedDescendingIcon,
} from "components/icons";
import { EmptyOverlayBlock } from "./styled";

function EmptyRowsOverlayDefault() {
  return <EmptyOverlayBlock>No results available.</EmptyOverlayBlock>;
}

export const PrimaryTable = ({
  rows,
  columns,
  handlePageChange,
  hasPagination,
  handleGoToPageChange,
  totalPageNumber,
  page,
  goToPage,
  goToSpecificPage,
  handleRowClick,
  handleSortModelChange,
  sortModel,
  appliedFilters,
  handleClearFilters,
  handleRemoveFilter,
  filterComponent,
  EmptyRowsOverlay,
  handleCreate,
  loading = false,
}) => {
  const isHtmlElement = (value) => {
    return typeof value === "string" && /<\/?[a-z][\s\S]*>/i.test(value);
  };

  const updatedColumns = columns.map((col, index) => {
    // Apply the custom renderCell function only for the first column
    if (index === 0) {
      return {
        ...col,
        renderCell: (params) => {
          const value = params.value;
          if (isHtmlElement(value)) {
            return <span dangerouslySetInnerHTML={{ __html: value }} />;
          }
          return value;
        },
      };
    }
    return col; // Other columns remain the same
  });
  return (
    <>
      <div>
        <ThemeProvider theme={dataGridTheme}>
          {filterComponent}
          <AppliedFilters
            appliedFilters={appliedFilters}
            handleRemoveFilter={handleRemoveFilter}
            handleClearFilters={handleClearFilters}
          />
          <DataGrid
            rows={rows}
            columns={updatedColumns}
            onRowClick={handleRowClick}
            hideFooterPagination
            onPageChange={handlePageChange}
            onSortModelChange={handleSortModelChange}
            sortModel={sortModel}
            sortingMode="server"
            loading={loading}
            slots={{
              columnSortedAscendingIcon: ColumnSortedDescendingIcon,
              columnSortedDescendingIcon: ColumnSortedAscendingIcon,
              columnUnsortedIcon: ColumnUnsortedIcon,
              noRowsOverlay: EmptyRowsOverlay
                ? () => <EmptyRowsOverlay handleCreate={handleCreate} />
                : EmptyRowsOverlayDefault,
            }}
          />
        </ThemeProvider>
      </div>
      {!!hasPagination && (
        <PrimaryPagination
          totalPageNumber={totalPageNumber}
          page={page}
          handlePageChange={handlePageChange}
          goToPage={goToPage}
          handleGoToPageChange={handleGoToPageChange}
          goToSpecificPage={goToSpecificPage}
        />
      )}
    </>
  );
};
