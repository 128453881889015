import { createSlice } from "@reduxjs/toolkit";
import { getDiagnosticQuiz } from "actions/diagnosticQuizActions";

const initialState = {
  data: {},
  status: null,
  totalCount: 0,
  appliedFilters: [],
};

export const diagnosticQuizSlice = createSlice({
  name: "diagnosticQuiz",
  initialState,
  reducers: {
    setAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDiagnosticQuiz.pending, (state) => {
        state.status = true;
      })
      .addCase(getDiagnosticQuiz.fulfilled, (state, { payload }) => {
        state.data = payload.data.data;
        state.totalCount = payload.data.total;
      })
      .addCase(getDiagnosticQuiz.rejected, (state) => {
        state.status = false;
      });
  },
});

export const { setAppliedFilters } = diagnosticQuizSlice.actions;

export default diagnosticQuizSlice.reducer;
