import {
  CATEGORIES_FILTER_KEYS,
  FILTER_CONSTANTS,
} from "pages/DiagnosticQuiz/components/constants";
import { useState } from "react";

export default function FilterHelpers(initialFilters = []) {
  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const handleApplyFilter = (filters) => {
    setAppliedFilters(filters);
  };

  const handleRemoveFilter = (key) => {
    const appliedFiltersAfterRemove = appliedFilters.filter(
      (filter) => filter?.queryKey !== key
    );

    const filterRemoveData =
      key === FILTER_CONSTANTS.TOPIC_ID
        ? appliedFiltersAfterRemove.filter(
            (filter) => !CATEGORIES_FILTER_KEYS.includes(filter?.queryKey)
          )
        : appliedFiltersAfterRemove;

    setAppliedFilters(filterRemoveData);
  };

  const handleClearFilters = () => {
    setAppliedFilters([]);
  };

  const appliedFiltersToParams = (filters) => {
    let params = {};
    for (let i = 0; i < filters.length; i++) {
      if (filters[i]) {
        params[filters[i].queryKey] = filters[i].value;
      }
    }
    return params;
  };

  return {
    appliedFiltersToParams,
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFilters,
  };
}
