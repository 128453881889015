import styled from "@emotion/styled";
import { Button, FormControl } from "@mui/material";
import { COLOR } from "constants/styleConstants";

export const FormWrapper = styled("div")({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

export const FilterButton = styled(Button)({
  display: "flex",
  gap: "4px",
  borderColor: `${COLOR.primaryPurple}`,
  color: `${COLOR.primaryPurple}`,
  "&:hover": {
    borderColor: `${COLOR.primaryPurple}`,
  },
});

export const ApplyButton = styled(Button)({
  marginTop: "10px",
  border: `1px solid ${COLOR.primaryPurple}`,
  padding: "6px 20px",
});

export const StyledFormControl = styled(FormControl)({
  minWidth: "200px",
  paddingBottom: "10px",
});
