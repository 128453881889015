import { makeCapitalize } from "helpers/textCapitalize";

export const FILTER_CONSTANTS = {
  TOPIC_ID: "topic_id",
  TOPIC: "Section",
  LEVEL: "Level",
  LEVEL_KEY: "level",
  SUBJECT: "Subject",
  SUBJECT_ID: "subject_id",
  STATUS: "Status",
  TYPE: "Type",
  STATUS_KEY: "status",
  TYPE_KEY: "type",
  CATEGORY: "Private Subject",
  CATEGORY_ID: "category_id",
  ACTIVATION: "Activation",
  ACTIVATION_KEY: "is_active",
};

export const FILTER_VALUE_NAMES = {
  complete: "complete",
  level: "level",
  type: "type",
  active: "active"
};

export const FILTER_CHANGE = {
  topic_id: "Section",
  subject_id: "Subject",
  category_id: "Private Subject",
  status: "Status",
  level: "Level",
  type: "Type",
  is_active: "Activation"
}

export const FILTERS_VALUE_CHANGE = {
  topic_id: 
    ({ target, setSelected, newFilters, filterIndex, data }) => {
      setSelected((state) => ({
        ...state,
        topic: target.value,
      }));
      const value = data.find(
        (topic) => topic.id === newFilters[filterIndex]?.value
      ).title  ||
      "";
      return value;
    },
  category_id: 
    ({ newFilters, filterIndex, data }) => {
      const value =
        (data &&
          data.find(
            (category) => category.id === newFilters[filterIndex]?.value
          )?.title) ||
        "";
      return value
    },
  subject_id: 
    ({ target, setSelected, newFilters, filterIndex, data }) => {
      setSelected((state) => ({
        ...state,
        subject: target.value,
      }));
      const value =
        (data &&
          data.find(
            (category) => category.id === newFilters[filterIndex]?.value
          )?.title) ||
        "";
      return value;
    },
  status: () =>  FILTER_VALUE_NAMES.complete,
  level: ({ event }) => makeCapitalize(event.value),
  type: ({ event }) => makeCapitalize(event.value),
  is_active: () => FILTER_VALUE_NAMES.active,
}