import instance from "./baseRequest";

export const aboutApi = {
  getAboutContent: async () => {
    const res = await instance.get('/admin/page/about');
    return res.data;
  },
  updateAboutPage: async (body) => {
    return await instance.put('/admin/page/about', body)
  },
};

