import { createAsyncThunk } from "@reduxjs/toolkit";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { usersApi } from "service/usersApi";
import { getUsersWithFullName } from "./adapters/adaptUsersData";

export const getUsers = createAsyncThunk(
  "/nordas/getUsers",
  async (params, { rejectWithValue }) => {
    try {
      const res = await usersApi.getUsers(params);
      return getUsersWithFullName(res.data);
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const blockUnblockUser = createAsyncThunk(
  "/nordas/blockUnblockUser",
  async (
    { id, page, search, sort, appliedFilterParams },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await usersApi.blockUnblockUser(id);
      Notify(NOTIFY.type.success, data.message);
      await dispatch(
        getUsers({ page, search, ...sort, ...appliedFilterParams })
      );
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "/nordas/deleteUser",
  async (
    { id, page, search, sort, appliedFilterParams },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await usersApi.deleteUser(id);
      Notify(NOTIFY.type.success, data.message);
      await dispatch(
        getUsers({ page, search, ...sort, ...appliedFilterParams })
      );
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const getSubscriptionPlans = createAsyncThunk(
  "getSubscriptionPlans",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await usersApi.getSubscriptionPlans();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const attachSubscriptionPlan = createAsyncThunk(
  "attachSubscriptionPlan",
  async ({ data, navigate, params }, { rejectWithValue, dispatch }) => {
    try {
      await usersApi.attachSubscriptionPlan(data);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      dispatch(getUsers(params));
      navigate();
    } catch (error) {
      Notify(NOTIFY.type.error, error?.response?.data?.errors);
      return rejectWithValue(error);
    }
  }
);

export const addTestCount = createAsyncThunk(
  "addTestCount",
  async ({ data, navigate, params }, { rejectWithValue, dispatch }) => {
    try {
      await usersApi.addTestCount(data);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      dispatch(getUsers(params));
      navigate();
    } catch (error) {
      Notify(NOTIFY.type.error, error?.response?.data?.errors);
      return rejectWithValue(error);
    }
  }
);
