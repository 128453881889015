import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { diagnosticQuizSchema } from "./diagnosticQuizSchema";
import { getTopics } from "actions/departmentAcions";
import { getCategoriesByTopicId } from "actions/categoryActions";
import { clearCategoriesList } from "features/categorySlice";
import { FILTER_CONSTANTS } from "./components/constants";

import * as Yup from "yup";
import { useFormik } from "formik";

import { Button, TextField, MenuItem } from "@mui/material";

import { Close } from "@mui/icons-material";
import { createDiagnosticQuiz } from "actions/diagnosticQuizActions";

import { CONSTANTS, TABLE_MIN_PAGE } from "constants/general";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { InfiniteScrollAutocomplete } from "components";
import { SaveButton } from "components/Button";

export const CreateDiagnosticQuiz = ({
  setIsCreate,
  appliedFilterParams,
  isFixed,
  isDiagnostic,
}) => {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.data);
  const categories = useSelector((state) => state.category.categoriesByTopic);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState(null);
  const [page, setPage] = useState(TABLE_MIN_PAGE);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const isFirstMountRef = useRef(true);

  const [initialValues, validation] = formikValidation(diagnosticQuizSchema);

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(createDiagnosticQuiz({ data: values, appliedFilterParams }));
      dispatch(clearCategoriesList());
      setIsCreate(false);
    },
  });

  const fetchCategoriesByTopicId = ({ search = "", isLoadMore }) => {
    dispatch(
      getCategoriesByTopicId({
        id: selectedTopic,
        params: {
          isLoadMore,
          is_diagnostic_quiz: true,
          page,
        },
      })
    );
  };

  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };

  const handleChangeSelectBoxValue = (e, elem) => {
    const value =
      elem.name === FILTER_CONSTANTS.CATEGORY_KEY ? e.value : e.target.value;
    if (elem.name === FILTER_CONSTANTS.CATEGORY_KEY) {
      setCategoryId(value);
    }

    if (elem.name === FILTER_CONSTANTS.TOPIC_KEY) {
      setSelectedTopic(value);
    }

    if (elem.valid !== CONSTANTS.VALID_TEXT) {
      formik.setFieldValue(elem.name, value);
    } else {
      formik.setFieldValue(elem.name, value || null);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(TABLE_MIN_PAGE);
    setFilteredOptions([]);
  };

  useEffect(() => {
    if (categories.data) {
      setFilteredOptions(() => categories.data);
      setHasMore(!(page === categories?.meta?.last_page));
    }
  }, [categories]);

  useEffect(() => {
    if (selectedTopic) {
      fetchCategoriesByTopicId({
        search: searchTerm ?? "",
        isLoadMore: page > 1 && !isFirstMountRef.current,
        quiz_type: isFixed ? "fixed" : "generated",
      });
    }
    isFirstMountRef.current = false;
  }, [searchTerm, selectedTopic, page]);

  useEffect(() => {
    dispatch(getTopics({ search: "" }));
  }, []);

  useEffect(() => {
    setCategoryId(null);
  }, [selectedTopic]);

  return (
    <div className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="cancel_button">
          <Button onClick={() => setIsCreate(false)}>
            <Close />
          </Button>
        </div>
        {diagnosticQuizSchema.map((elem, index) =>
          elem.id === "topic" ? (
            <div key={elem.id} className="field_Container">
              <TextField
                fullWidth
                id={elem.id}
                select
                variant="outlined"
                label={elem.label}
                value={formik.values[elem.backend_field]}
                onChange={(e) => handleChangeSelectBoxValue(e, elem)}
                error={formErrorHelper({
                  formik,
                  elementName: elem.name,
                  isBoolean: true,
                })}
                helperText={formErrorHelper({
                  formik,
                  elementName: elem.name,
                })}
              >
                {topics?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : (
            <div key={elem.id + index} className="field_Container">
              <InfiniteScrollAutocomplete
                hasMore={hasMore}
                handleSearchChange={handleSearchChange}
                options={filteredOptions}
                handleLoadMore={handleLoadMore}
                searchTerm={searchTerm}
                handleChange={(_, value) =>
                  handleChangeSelectBoxValue(value, elem)
                }
                value={categoryId}
                label={elem.label}
                error={formErrorHelper({
                  formik,
                  elementName: elem.name,
                  isBoolean: true,
                })}
                helperText={formErrorHelper({
                  formik,
                  elementName: elem.name,
                })}
                hasNotNone={true}
                notClearable={true}
              />
            </div>
          )
        )}
        <SaveButton />
      </form>
    </div>
  );
};
