import { styled } from "@mui/system";
import { COLOR } from "constants/styleConstants";

export const DragHoveredComponent = styled("div")(() => ({
  width: "100%",
  height: "56px",
  backgroundColor: COLOR.primaryPurple,
  border: "1px dashed gray",
  opacity: 0.5,
}));
