import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";

import { subjectsSchema } from "./subjectsSchema";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { useActionHelper } from "hooks/useActionHelper";
import { CONSTANTS, QUIZ_TYPES } from "constants/general";
import { createSubject, updateSubject } from "actions/subjectsActions";
import { Button, MenuItem, TextField } from "@mui/material";
import { Close, Save } from "@mui/icons-material";
import {
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { submitForm } from "helpers/formikDataCollect";
import { SaveButton } from "components/Button";

const CreateEditSubject = ({ close, isEdit, page }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const subjectContent = useSelector((state) => state.subjects.edit);
  const modalRef = useRef();

  const [initialValues, validation] = formikValidation(subjectsSchema);

  const [createContent, editContent] = useActionHelper({
    createNewItemApiCall: createSubject,
    updateItemApiCall: updateSubject,
    editId: subjectContent?.id,
    topicId: params.id,
    page,
    close,
    isEdit: true,
  });

  const apiCall = isEdit ? editContent : createContent;

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      submitForm({
        values,
        dispatch,
        apiCall,
        editContent: subjectContent,
      });
    },
  });

  const handleChangeSelectBoxValue = (e) => {
    formik.setFieldValue(FORM_ITEM_NAMES_OBJ.quiz_type, e.target.value);
  };

  useEffect(() => {
    if (subjectContent) {
      subjectsSchema.forEach((elem) => {
        formik.setFieldValue(elem.name, subjectContent[elem.name]);
      });
    }
    formik.setFieldValue("topic_id", subjectContent.topic_id || params.id);
  }, [subjectContent]);

  return (
    <div ref={modalRef} className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="cancel_button">
          <Button onClick={close}>
            <Close />
          </Button>
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.title}
            name={FORM_ITEM_NAMES_OBJ.title}
            label={CONSTANTS.TITLE}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.text}
            value={formik.values[FORM_ITEM_NAMES_OBJ.title]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
            })}
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.quiz_type}
            select
            variant="outlined"
            label={"Type"}
            value={formik.values[FORM_ITEM_NAMES_OBJ.quiz_type]}
            onChange={(e) => {
              if (!isEdit) {
                handleChangeSelectBoxValue(e);
              }
            }}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.quiz_type,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.quiz_type,
            })}
            disabled={isEdit}
            className={isEdit && "global_disabled"}
          >
            {QUIZ_TYPES?.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <SaveButton />
      </form>
    </div>
  );
};

export default CreateEditSubject;
