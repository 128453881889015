import userManagement from "assets/images/sidebar-menu/userManagement.svg";
import about from "assets/images/sidebar-menu/about.svg";
import departments from "assets/images/sidebar-menu/departments.svg";
import diagnosticQuiz from "assets/images/sidebar-menu/diagnosticQuiz.svg";
import questions from "assets/images/sidebar-menu/questions.svg";
import { PATHNAME } from "constants/pathnames";

export const Menu = [
  {
    id: "2",
    name: "Departments",
    path: PATHNAME.departments,
    icon: <img src={departments} alt="Departments" />,
  },
  {
    id: "4",
    name: "Diagnostic Quiz",
    path: PATHNAME.diagnosticQuiz,
    icon: <img src={diagnosticQuiz} alt="Diagnostic Quiz" />,
  },
  {
    id: "4",
    name: "Fixed Quiz",
    path: PATHNAME.fixedQuiz,
    icon: <img src={diagnosticQuiz} alt="Fixed Quiz" />,
  },
  {
    id: "3",
    name: "Questions",
    path: PATHNAME.questions,
    icon: <img src={questions} alt="Questions" />,
  },
  {
    id: "5",
    name: "User Management",
    path: PATHNAME.userManagement,
    icon: <img src={userManagement} alt="User Management" />,
  },
  {
    id: "1",
    name: "About",
    path: PATHNAME.about,
    icon: <img src={about} alt="About" />,
  },
];
