import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ComponentHeader } from "components";
import ModalWindow from "components/ModalWindow";
import {
  changeFixedQuizActivation,
  changeFixedQuizStatus,
  deleteFixedQuiz,
} from "actions/fixedQuizzesActions";
import { CreateDiagnosticQuiz } from "./createFixedQuiz";
import {
  TABLE_MIN_PAGE,
  TABLE_PER_PAGE_COUNT,
  SEARCH_KEY,
  CONSTANTS,
  DEFAULT_DATE_SORT_PARAMS,
  defaultSortParams,
  DEFAULT_DATE_REVERSED_SORT_PARAMS,
  defaultReversedSortParams,
} from "constants/general";

import { getColumns } from "./columns";
import { PrimaryTable } from "components/PrimaryTable";
import { Filter } from "./components";
import FilterHelpers from "helpers/filter";
import { EmptyRowsOverlay } from "./components/emptyRowsOverlay";
import { clearQuestions } from "features/questionsSlice";
import { setAppliedFilters } from "features/diagnosticQuizSlice";
import { getFixedQuizzes } from "actions/fixedQuizzesActions";

let appliedFilterParams = {};

export const FixedQuiz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [goToPage, setGoToPage] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  const [sort, setSort] = useState(defaultSortParams);
  const [sortModelState, setSortModelState] = useState(
    DEFAULT_DATE_SORT_PARAMS
  );

  const query = new URLSearchParams(useLocation().search);
  const search = query.get(SEARCH_KEY);

  const fixedQuiz = useSelector((state) => state.fixedQuiz.data);
  const totalCount = useSelector((state) => state.fixedQuiz.totalCount);
  const appliedFiltersFromRedux = useSelector(
    (state) => state.fixedQuiz.appliedFilters
  );

  const totalPageNumber = Math.ceil(totalCount / TABLE_PER_PAGE_COUNT);

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFiltersToParams,
    appliedFilters,
  } = FilterHelpers(appliedFiltersFromRedux);

  const openDeleteModal = (row) => {
    setDeleteId(row.id);
    setOpen(true);
  };

  const changeActivation = (id, active) => {
    const body = {
      is_active: !active,
    };
    dispatch(changeFixedQuizActivation({ id, body, page }));
  };

  const changeStatus = (id, completed) => {
    const data = {
      completed: completed ? 0 : 1,
    };
    dispatch(changeFixedQuizStatus({ id, page, data }));
  };

  const columns = getColumns(openDeleteModal, changeActivation, changeStatus);

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= totalPageNumber) {
      setPage(newPage);
    }
  };
  const handleRowClick = (param) => {
    dispatch(clearQuestions());
    navigate(
      `/fixed-quiz/${param.id}/topic/${param.row.topic_id}/fixed-quiz-questions/${param.row.category_id}`
    );
  };

  const handleCreate = () => {
    setIsCreate(true);
  };

  const handleSortModelChange = (sortModel) => {
    if (sortModel.length) {
      setSortModelState(sortModel);
      setSort({ order_by: sortModel[0].field, order: sortModel[0].sort });
    } else {
      setSortModelState(DEFAULT_DATE_REVERSED_SORT_PARAMS);
      setSort(defaultReversedSortParams);
    }
  };

  useEffect(() => {
    dispatch(setAppliedFilters(appliedFilters));
  }, [appliedFilters, page, sort]);

  useEffect(() => {
    appliedFilterParams = appliedFiltersToParams(appliedFilters);
    dispatch(
      getFixedQuizzes({ page, search, ...sort, ...appliedFilterParams })
    );
  }, [page, search, sort, appliedFilters]);

  return (
    <div className="table_page_body_container">
      <ComponentHeader create handleCreateEdit={handleCreate} />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          deleteRequest={deleteFixedQuiz}
          currentPage={page}
          message={CONSTANTS.DELETE_DIAGNOSTIC_QUIZ}
        />
      )}
      <PrimaryTable
        rows={fixedQuiz.length ? fixedQuiz : []}
        columns={columns}
        filterComponent={
          <Filter
            applyFilter={handleApplyFilter}
            appliedFilters={appliedFilters}
            setTablePage={setPage}
          />
        }
        handlePageChange={handlePageChange}
        handleGoToPageChange={handleGoToPageChange}
        totalPageNumber={totalPageNumber}
        handleCreate={handleCreate}
        page={page}
        goToPage={goToPage}
        goToSpecificPage={goToSpecificPage}
        handleRowClick={handleRowClick}
        handleSortModelChange={handleSortModelChange}
        sortModel={sortModelState}
        handleClearFilters={handleClearFilters}
        handleRemoveFilter={handleRemoveFilter}
        appliedFilters={appliedFilters}
        hasPagination={totalCount > TABLE_PER_PAGE_COUNT}
        EmptyRowsOverlay={EmptyRowsOverlay}
      />
      <div className="create_edit">
        {isCreate && (
          <div className="popup">
            <CreateDiagnosticQuiz
              isFixed
              appliedFilterParams={appliedFilterParams}
              setIsCreate={setIsCreate}
            />
          </div>
        )}
      </div>
    </div>
  );
};
