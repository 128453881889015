export const FILTER_CONSTANTS = {
  TOPIC_ID: "topic_id",
  TOPIC: "topic",
  CATEGORY: "category",
  SUBJECT: "subject",
  CATEGORY_ID: "category_id",
  STATUS: "status",
};

export const FILTER_VALUE_NAMES = {
  complete: "complete",
};
