import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { IOSSwitch } from 'pages/Questions/styledMui';
import { Tooltip } from '@mui/material';
import { ACTIVATE, DEACTIVATE } from 'constants/general';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: 14,
}))

export default function CustomizedSwitches({ checked, disabled, onChange, title }) {
  return (
    <FormGroup>
      {disabled ? (
        <Tooltip title={title} arrow>
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledTypography>{DEACTIVATE}</StyledTypography>
              <IOSSwitch 
                checked={checked}
                disabled={disabled}
                onChange={onChange}
              />
            <StyledTypography>{ACTIVATE}</StyledTypography>
          </Stack>
        </Tooltip>
      ) : (
        <Stack direction="row" spacing={1} alignItems="center">
          <StyledTypography>{DEACTIVATE}</StyledTypography>
            <IOSSwitch 
              checked={checked}
              disabled={disabled}
              onChange={onChange}
            />
          <StyledTypography>{ACTIVATE}</StyledTypography>
        </Stack>
      )}
    </FormGroup>
  );
}
