import { CreateEdit } from "components/QuestionTable/createEdit";
import { useParams } from "react-router";

export const CreateEditDiagnosticQuestion = ({ isCreate, isFixedQuiz }) => {
  const { topic_id, category_id, question_id } = useParams();
  return (
    <>
      <CreateEdit
        isFixedQuiz={isFixedQuiz}
        isCreate={isCreate}
        isDiagnosticQuiz={true}
        topic_id={topic_id}
        category_id={category_id}
        question_id={question_id}
      />
    </>
  );
};
