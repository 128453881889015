import Notify from "helpers/Notify";
import instance from "./baseRequest";

export const authApi = {
  login: async ({ email, password, rem }) => {
    const res = await instance.post("/auth/login", { email, password });

    rem
      ? localStorage.setItem("access_token", res.data.data.token)
      : sessionStorage.setItem("access_token", res.data.data.token);

    return res;
  },
  logout: async () => await instance.post("/user/logout"),

  changePassword: async ({old_password, new_password, new_password_confirmation}) => 
    await instance.post("/user/change-password",
      {old_password, new_password, new_password_confirmation}
    )
};
