import instance from "./baseRequest";

export const subjectsApi = {
  getSubject: async (id, page) =>
    await instance.get(`/categories-parent`, {
      params: {
        page,
        type: "exam",
        per_page: 1000,
        topic_id: id,
      },
    }),
  updateSubject: async ({ id, data }) =>
    await instance.post(`/admin/categories/${id}`, data),
  createSubject: async ({ data }) =>
    await instance.post("/admin/exam-subjects", data),
  deleteSubject: async (id) => await instance.delete(`/admin/categories/${id}`),
  getCoSubjects: async (id, page, search) =>
    await instance.get(`/get-exam-subjects-by-parent-id/${id}`, {
      params: {
        page,
        search,
      },
    }),
};
