import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router";

import { getSubjects, deleteSubject } from "actions/subjectsActions";
import { ComponentHeader, TableComponent } from "components";
import { setEditSubject, setTableTitle } from "features/subjectSlice";
import {
  TABLE,
  TABLE_MIN_PAGE,
  TABLE_PER_PAGE_COUNT,
  CONSTANTS,
} from "constants/general";
import ModalWindow from "components/ModalWindow";
import CreateEditSubject from "./createEditSubject";
import { useSearchParams } from "hooks/useSearchParams";
import { PATHNAME } from "constants/pathnames";

export const SubjectsTable = () => {
  const [page, setPage] = useState(TABLE_MIN_PAGE);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [goToPage, setGoToPage] = useState("");
  const [isOpenCreateEdit, setIsOpenCreateEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { search } = useSearchParams();

  const subjects = useSelector((state) => state.subjects.data);
  const total = useSelector((state) => state.subjects.total);

  const totalPageNumber = Math.ceil(total / TABLE_PER_PAGE_COUNT);

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const handleCreate = () => {
    dispatch(setEditSubject({}));
    setIsOpenCreateEdit(true);
    setIsEdit(false);
  };

  const handleOpenChild = (elem) => {
    dispatch(setTableTitle(elem?.title));
    navigate(`co-subject/${elem.id}`);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const goToSpecificPage = () => {
    const newPage = Number(goToPage);
    if (newPage >= TABLE_MIN_PAGE && newPage <= total) {
      setPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getSubjects({ id, page }));
  }, [page, id, search]);

  return (
    <div className="table_page_body_container">
      <ComponentHeader
        create
        handleCreateEdit={handleCreate}
        back
        backPath={PATHNAME.departments}
      />
      <div className="create_edit">
        {isOpenCreateEdit && (
          <div className="popup">
            <CreateEditSubject
              isEdit={isEdit}
              close={() => setIsOpenCreateEdit(false)}
              page={page}
            />
          </div>
        )}
      </div>
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          deleteRequest={deleteSubject}
          currentPage={page}
          getId={id}
          message={CONSTANTS.DELETE_DEPARTMENT_MESSAGE}
        />
      )}
      <TableComponent
        hasPagination={total > TABLE_PER_PAGE_COUNT}
        page={page}
        setPage={setPage}
        perPageCount={TABLE_PER_PAGE_COUNT}
        data={subjects}
        title={state?.title}
        total={total}
        totalPageNumber={totalPageNumber}
        subtitle={TABLE.subject}
        setEditContent={setEditSubject}
        deleteRequest={deleteSubject}
        setDeleteId={setDeleteId}
        setOpen={setOpen}
        setIsOpenCreateEdit={setIsOpenCreateEdit}
        setIsEdit={setIsEdit}
        handleOpenChild={handleOpenChild}
        handleGoToPageChange={handleGoToPageChange}
        goToSpecificPage={goToSpecificPage}
        goToPage={goToPage}
      />
    </div>
  );
};
