import instance from "./baseRequest";

export const usersApi = {
  getUsers: async (params) => await instance.get("/admin/users", { params }),
  blockUnblockUser: async (id) =>
    await instance.delete(`/admin/users/block-unblock/${id}`),
  deleteUser: async (id) => await instance.delete(`/admin/users/${id}`),
  getSubscriptionPlans: async () =>
    await instance.get("/subscription-plans?all=1"),
  addTestCount: async (body) =>
    await instance.post("/admin/subscription-plans/add", body),
  attachSubscriptionPlan: async (body) =>
    await instance.post("/admin/subscription-plans/attach", body),
};
