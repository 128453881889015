export const PATHNAME = {
  about: "/about",
  diagnosticQuiz: "/diagnostic-quiz",
  fixedQuiz: "/fixed-quiz",
  departments: "/departments",
  edit: "/edit",
  create: "create",
  userManagement: "/user-management",
  questions: "/questions",
  subject: "/departments/subject/:id",
  diagnosticQuestions:
    "/diagnostic-quiz/:id/topic/:topic_id/diagnostic-questions/:category_id",
  fixedQuestions:
    "/fixed-quiz/:id/topic/:topic_id/fixed-quiz-questions/:category_id",
};

export const PATHS_WITH_SEARCH = [
  PATHNAME.userManagement,
  PATHNAME.questions,
  // PATHNAME.subject,
  PATHNAME.diagnosticQuestions,
  PATHNAME.fixedQuestions,
];

export const PRIVATE_SUBJECT_QUESTIONS_PATH =
  "/departments/subject/:id/co-subject/:subject_id/department-questions/:category_id";
