import instance from "./baseRequest";

export const diagnosticQuizApi = {
  getDiagnosticQuiz: async (params) =>
    await instance.get("/admin/diagnostic-quiz", { params }),
  getDiagnosticQuizById: async ({ id }) =>
    await instance.get("/admin/diagnostic-quiz", { params: { id } }),
  createDiagnosticQuiz: async ({ topic_id, category_id }) =>
    await instance.post("/admin/diagnostic-quiz", { topic_id, category_id }),
  updateDiagnosticQuiz: async ({ topicId, categoryId }) =>
    await instance.post("/admin/diagnostic-quiz", { topicId, categoryId }),
  deleteDiagnosticQuiz: async ({ id }) =>
    await instance.delete(`/admin/diagnostic-quiz/${id}`),
  changeQuizActivation: async ({ id, body }) =>
    await instance.post(`/admin/diagnostic-quiz/change-status/${id}`, body),
};
