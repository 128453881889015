import instance from "./baseRequest";

export const questionsApi = {
  getQuestions: async (params) =>
    await instance.get("/admin/questions", { params: { ...params } }),
  getFixedQuizQuestions: async (params) =>
    await instance.get("/admin/fixed-quizzes/questions", {
      params: { ...params },
    }),
  createQuestion: async (body) => await instance.post("/admin/questions", body),
  getQuestionById: async (id) => await instance.get(`/admin/questions/${id}`),
  updateQuestion: async (id, body) =>
    await instance.post(`/admin/questions/${id}`, body),
  deleteQuestion: async (id) => await instance.delete(`/admin/questions/${id}`),
  changeQuestionActivation: async (id, body) =>
    await instance.post(`/admin/questions/change-status/${id}`, body),
};
