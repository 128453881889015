export const getError = (err) => {
  if (err.response?.data?.errors?.length) {
    return err.response.data.errors[0];
  }
  if (err && err.response && err.response.data) {
    return err.response.data.message;
  }
  if (err && err.response && err.response.statusText) {
    return err && err.response && err.response.statusText;
  }
  if (err && err.data && err.data.message) {
    return err.data.message;
  }
  if (err && err.message) {
    return err.message;
  }
  return err;
};
