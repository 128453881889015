import React, { useEffect, useRef, useState } from "react";

export const ResponsiveVideo = ({ src, poster, ...props }) => {
  const [isLandscape, setIsLandscape] = useState(true);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleLoadedData = () => {
      if (videoElement) {
        const { videoWidth, videoHeight } = videoElement;
        setIsLandscape(videoWidth > videoHeight);
      }
    };

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleLoadedData);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, [src]);

  return (
    <video
      ref={videoRef}
      src={src}
      poster={poster}
      style={{
        width: isLandscape ? "100%" : "auto",
        maxHeight: "90vh",
        height: isLandscape ? "auto" : "100%",
        maxWidth: "90vw",
        display: "block",
      }}
      {...props}
    />
  );
};
