import { CONSTANTS, MEDIA_KEY } from "constants/general";
import { PAGE_NAME_OBJ } from "constants/pageName";

const collectFormData = (values, pageName) => {
  const formData = new FormData();

  for (let value in values) {
    if (Array.isArray(values[value])) {
      if (
        value === CONSTANTS.IMAGES_FIELD ||
        pageName === PAGE_NAME_OBJ.portfolio ||
        pageName === PAGE_NAME_OBJ.blog
      ) {
        const val = values[value];

        val.forEach((elem) => {
          formData.append(value, elem);
        });
      } else {
        const val = JSON.stringify(values[value]);
        formData.append(value, val);
      }
    } else {
      formData.append(value, values[value]);
    }
  }

  return formData;
};

export const submitData = (
  values,
  dispatch,
  checkCropperMode,
  showImage,
  editContent,
  isFormData,
  apiCall,
  setIsEdit,
  pageName
) => {
  if (values?.services?.[0] === CONSTANTS.OTHER_SERVICE) {
    values.services = [];
  }
  checkCropperMode(values, showImage, editContent);

  if (isFormData) {
    const formData = collectFormData(values, pageName);
    dispatch(apiCall(formData));
  } else {
    dispatch(apiCall(values));
  }

  if (setIsEdit) setIsEdit(false);
};

export const submitForm = async ({
  values,
  dispatch,
  checkCropperMode = () => {},
  showImage,
  editContent,
  isFormData,
  apiCall = () => {},
  setIsEdit = null,
  pageName = "",
  resetLoading,
}) => {
  checkCropperMode(values, showImage, editContent);

  if (isFormData) {
    const formData = collectFormData(values, pageName);
    await apiCall(formData);
  } else {
    await apiCall(values);
  }
  if (setIsEdit) setIsEdit(false);
};

export const questionFormData = (data) => {
  const formData = new FormData();

  const arrayRec = (element, key) => {
    element.forEach((item, index) => {
      if (Array.isArray(item)) {
        let newKey = `${key}[${index}]`;
        arrayRec(item, newKey);
      } else {
        if (typeof item === "object" && !key.includes(MEDIA_KEY)) {
          for (let val in item) {
            const value =
              typeof item[val] === "boolean" ? (item[val] ? 1 : 0) : item[val];
            formData.append(`${key}[${index}][${val}]`, value);
          }
        } else {
          formData.append(`${key}[${index}]`, item);
        }
      }
    });
  };

  for (let value in data) {
    const val = data[value];
    if (Array.isArray(val)) {
      arrayRec(val, value);
    } else {
      formData.append(value, val);
    }
  }

  return formData;
};
