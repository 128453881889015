import { memo, useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { EditorComponent } from "components/Editor";
import { CONSTANTS, EXPLANATION_TYPES } from "constants/general";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  MemoizedAudio,
  MemoizedImage,
  MemoizedVideo,
} from "components/MemoizedMedias";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const ExplanationContent = ({
  formik,
  elem,
  editContent,
  editorValue,
  setEditorValue,
  handleChangeSelectBoxValue,
  handleFileChange,
  uploadedFile,
  deleteFile,
}) => {
  const [selectedExplanationType, setSelectedExplanationType] = useState("");

  const handleDeleteFile = (e) => {
    e.stopPropagation();
    deleteFile();
  };

  useEffect(() => {
    setSelectedExplanationType(formik.values[elem.backend_field].type);
  }, [formik.values[elem.backend_field].type]);

  return (
    <>
      <div className="field_Container question_filed_container column_12 width_100">
        <p className="field_title">{CONSTANTS.EXPLANATION_TEXT}</p>
        <EditorComponent
          data={editorValue || ""}
          setEditorValue={setEditorValue}
        />
      </div>
      <div className="field_Container question_filed_container column_12">
        <p className="field_title">{elem.input_name}</p>
        <TextField
          fullWidth
          id={elem.id}
          select
          variant="outlined"
          label={elem.label}
          value={selectedExplanationType}
          onChange={(e) => handleChangeSelectBoxValue(e, elem)}
        >
          {EXPLANATION_TYPES?.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="field_Container question_filed_container column_12">
        {!!formik.values[elem.backend_field]?.type && (
          <div className="file_container">
            <input
              type="file"
              id={`${elem.id}${formik.values[elem.backend_field]?.type}`}
              accept={`${formik.values[elem.backend_field].type}/*`}
              name={elem.name}
              className="question_file"
              onChange={(event) => handleFileChange(event, elem.name)}
            />
            <div className="file_wrapper">
              {uploadedFile && (
                <img
                  onClick={handleDeleteFile}
                  className="close_icon"
                  src="/close.png"
                  alt="Close"
                />
              )}

              <label
                htmlFor={`${elem.id}${formik.values[elem.backend_field]?.type}`}
                className="question_file_label file_label_relative"
              >
                {!!uploadedFile ? (
                  formik.values.explanations?.type === CONSTANTS.VIDEO_KEY ? (
                    <MemoizedVideo
                      uploadedFile={uploadedFile}
                      imageBase={imageBase}
                    />
                  ) : formik.values.explanations?.type ===
                    CONSTANTS.IMAGE_KEY ? (
                    <MemoizedImage
                      uploadedFile={uploadedFile}
                      imageBase={imageBase}
                    />
                  ) : (
                    <MemoizedAudio
                      uploadedFile={uploadedFile}
                      imageBase={imageBase}
                    />
                  )
                ) : (
                  <>
                    <p>
                      {CONSTANTS.UPLOAD}{" "}
                      {formik.values[elem.backend_field]?.type}
                    </p>
                    <UploadFileIcon fontSize="large" />
                  </>
                )}
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
