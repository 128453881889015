import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  headerFixed: true,
  headerShadow: true,
  headerBgTransparent: true,
  headerSearchHover: true,
  headerDrawerToggle: false,
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderFixed(state, headerFixed) {
      state.headerFixed = headerFixed.payload;
    },
    setHeaderShadow(state, headerShadow) {
      state.headerShadow = headerShadow.payload;
    },
    setHeaderBgTransparent(state, headerBgTransparent) {
      state.headerBgTransparent = headerBgTransparent.payload;
    },
    setHeaderSearchHover(state, headerSearchHover) {
      state.headerSearchHover = headerSearchHover.payload;
    },
    setHeaderDrawerToggle(state, headerDrawerToggle) {
      state.headerDrawerToggle = headerDrawerToggle.payload;
    },
  },
});

export const {
  setHeaderFixed,
  setHeaderShadow,
  setHeaderBgTransparent,
  setHeaderSearchHover,
  setHeaderDrawerToggle,
} = headerSlice.actions;

export default headerSlice.reducer;
