import { createSlice } from "@reduxjs/toolkit";
import { getTopics, getTopicById } from "actions/departmentAcions";

const initialState = {
  data: [],
  dataById: {},
  topicById: {},
  status: null,
  edit: {},
  topic: {},
};

export const departmentSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    setDataById: (state, payload) => {
      state.dataById = payload;
    },
    setTopicEdit: (state, { payload }) => {
      state.edit = payload;
    },
    clearTopicIdData: (state) => {
      state.topicById = {};
    },
    clearSelectedTopic: (state) => {
      state.topic = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTopics.pending, (state) => {
        state.status = true;
      })
      .addCase(getTopics.fulfilled, (state, { payload }) => {
        state.status = false;
        state.data = payload.data;
      })
      .addCase(getTopics.rejected, (state) => {
        state.status = false;
      })
      .addCase(getTopicById.pending, (state) => {
        state.status = true;
      })
      .addCase(getTopicById.fulfilled, (state, { payload }) => {
        state.status = false;
        state.topic = payload.data;
      })
      .addCase(getTopicById.rejected, (state) => {
        state.status = false;
      });
  },
});

export const {
  setDataById,
  setTopicEdit,
  clearTopicIdData,
  clearSelectedTopic,
} = departmentSlice.actions;

export default departmentSlice.reducer;
