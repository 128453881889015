import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { CONSTANTS } from "constants/general";
import { useLocation } from "react-router";

import {
  BootstrapDialog,
  ConfirmButton,
  RefuseButton,
  BootstrapDialogActions,
  BootstrapDialogContent,
  StyledDialogTitle,
} from "./styledComponents";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import useOutsideClick from "hooks/useOutsideClick";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <StyledDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
}

const ModalWindow = ({
  open,
  setOpen,
  id,
  deleteRequest,
  pageLimit,
  currentPage,
  getId = null,
  isChild,
  message,
  isQuestion = false,
  setIsConfirmed = () => {},
  submit,
  params,
  isTypeChange = false,
  title
}) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const modalRef = useRef();

  const handleClose = () => {
    setOpen(false);
    setIsConfirmed(() => false);
  };

  useOutsideClick(modalRef, handleClose);

  const handleSubmit = async () => {
    if (isQuestion) {
      setIsConfirmed(() => true);
      setOpen(false);
      submit();
    } else if (isTypeChange) {
      setIsConfirmed(() => true);
      setOpen(false);
    } else {
      const queryParams = new URLSearchParams(location.search);
      const search = queryParams.get(CONSTANTS.SEARCH);
      setOpen(false);
      dispatch(
        deleteRequest({ 
          id,
          pageLimit,
          page: currentPage,
          search,
          getId,
          isChild,
          params
        })
      );
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        ref={modalRef}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title || CONSTANTS.DELETE}
        </BootstrapDialogTitle>
        <BootstrapDialogContent dividers={isQuestion}>
          <Typography gutterBottom>{message}</Typography>
        </BootstrapDialogContent>
        <BootstrapDialogActions>
          <RefuseButton onClick={handleClose}>{CONSTANTS.CANCEL}</RefuseButton>
          <ConfirmButton onClick={() => handleSubmit()}>
            {CONSTANTS.CONFIRM}
          </ConfirmButton>
        </BootstrapDialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ModalWindow;
