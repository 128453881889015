import React, { memo } from "react";
import { CONSTANTS } from "constants/general";

export const MemoizedVideo = memo(({ uploadedFile, imageBase }) => {
  return (
    <video
      src={
        typeof uploadedFile === CONSTANTS.STRING_TYPE
          ? `${imageBase}${uploadedFile}`
          : URL.createObjectURL(uploadedFile)
      }
      controls
      className="uploaded_file_video"
    />
  );
});

export const MemoizedAudio = memo(({ uploadedFile, imageBase }) => {
  return (
    <audio controls>
      <source
        src={
          typeof uploadedFile === CONSTANTS.STRING_TYPE
            ? `${imageBase}${uploadedFile}`
            : URL.createObjectURL(uploadedFile)
        }
      ></source>
    </audio>
  );
});

export const MemoizedImage = memo(({ uploadedFile, imageBase }) => {
  return (
    <img
      src={
        typeof uploadedFile === CONSTANTS.STRING_TYPE
          ? `${imageBase}${uploadedFile}`
          : URL.createObjectURL(uploadedFile)
      }
      className="uploaded_file"
      alt="Explanation content"
    />
  );
});
