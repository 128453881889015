import { useDialog } from "hooks/useDialog";
import ImageSection from "./ImageSection";
import { MediaDialog } from "components/MediaDialog";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

export const ImageArrayInput = ({
  imageArray,
  setImageArray,
  element,
  handleFieldChange,
  data,
}) => {
  const { isOpen, dialogData, openDialog, closeDialog } = useDialog();

  const handleDeleteArrayItem = (index) => {
    const filteredImages = imageArray?.filter((image, idx) => idx !== index);
    setImageArray(filteredImages);
    handleFieldChange(element.backend_field, filteredImages);
  };

  const handleClickFullScreen = (index) => {
    const currentMedia = imageArray[index];
    if (currentMedia instanceof File) {
      const url = URL.createObjectURL(currentMedia);
      openDialog({ url, mediaUrlType: currentMedia.name });
    } else {
      openDialog({ src: image_url + currentMedia });
    }
  };

  const handleUploadFiles = (files, existingCount) => {
    const uploaded = [...imageArray];

    files.forEach((file, index) => {
      if (uploaded[existingCount + index]) {
        uploaded[existingCount + index] = file;
      } else if (uploaded.findIndex((f) => f?.name === file?.name) === -1) {
        uploaded[existingCount + index] = file;
      }
    });
    setImageArray(uploaded);
    handleFieldChange(element.backend_field, uploaded);
  };

  const handleAddFile = (event, existingCount) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    handleUploadFiles(chosenFiles, existingCount);
  };

  return (
    <div className="array_wrapper">
      <ImageSection
        imageArray={imageArray}
        setImageArray={setImageArray}
        elem={element}
        handleAddFile={handleAddFile}
        handleDeleteArrayItem={handleDeleteArrayItem}
        handleClickFullScreen={handleClickFullScreen}
        multiFilePage={true}
      />
      <MediaDialog
        isOpen={isOpen}
        dialogData={dialogData}
        closeDialog={closeDialog}
      />
    </div>
  );
};
