import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  TouchSensor,
} from "@dnd-kit/core";

import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Delete, Save } from "@mui/icons-material";
import { aboutSchema } from "./aboutSchema";
import { formikValidation, formErrorHelper } from "helpers/formikValidation";
import { useActionHelper } from "hooks/useActionHelper";
import { getAboutContent } from "actions/aboutActions";
import { aboutApi } from "service/aboutApi";
import { PATHNAME } from "constants/pathnames";
import { Button, TextField, InputAdornment, Tooltip } from "@mui/material";
import { submitForm } from "helpers/formikDataCollect";
import CardDrag from "components/dragComponent/index";
import { CONSTANTS, HELPER_TEXT } from "constants/general";
import {
  BUTTON_NAMES_OBJ,
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  FORM_TEXTAREA_ROWS_COUNT_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { StyledDragInput, StyledInputAdornment } from "./styled";
import { DeleteIcon } from "components/icons";
import { SaveButton } from "components/Button";
import { BackIcon } from "components/icons";
import { NOTIFY } from "constants/notifyType";

const cannotEmptyTxt = "Item cannot be empty";

const EditAboutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editContent = useSelector((state) => state.about.data);
  const isFormData = false;

  const [initialValues, validation] = formikValidation(aboutSchema);
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [hoveredDragItem, setHoveredDragItem] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isDragActive, setIsDragActive] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [_, editAbout] = useActionHelper({
    updateItemApiCall: aboutApi.updateAboutPage,
    customNotify: NOTIFY.message.update,
    navigateTo: PATHNAME.about,
  });

  const apiCall = editAbout;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id && over && over.id) {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over.id);
      const activeItem = items.find((item) => item.id === active.id);
      const overedItem = items.find((item) => item.id === over.id);

      setItems((prevItems) => {
        return prevItems
          .toSpliced(oldIndex, 1, overedItem)
          .toSpliced(newIndex, 1, activeItem);
      });
    }
    setActiveId(null);
    setActiveIndex(null);
    setHoveredId(null);
    setHoveredIndex(null);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
    const activeIdx = items.findIndex((item) => item.id === event.active.id);
    setActiveIndex(activeIdx);
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === event.active.id ? { ...item, isActive: true } : item
      )
    );
  };

  const handleDragOver = (event) => {
    const { over } = event;
    if (over) {
      setHoveredId(over.id);
      const hoveredIdx = items.findIndex((dragItem) => dragItem.id === over.id);
      const hoveredItem = items.find((dragItem) => dragItem.id === over.id);
      setHoveredIndex(hoveredIdx);
      setHoveredDragItem(hoveredItem);
      setItems((prevItems) =>
        prevItems.map((item) =>
          item.id === over.id
            ? { ...item, isHovered: true }
            : { ...item, isHovered: false }
        )
      );
    } else {
      setHoveredId(null);
      setHoveredIndex(null);
      setItems((prevItems) =>
        prevItems.map((item) => ({ ...item, isHovered: false }))
      );
    }
  };

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      values.items = items.map((elem) => elem.value);
      let errorFlag = false;

      values.items.forEach((item, index) => {
        if (item === "") {
          formik.setFieldError(`items[${index}]`, cannotEmptyTxt);
          formik.validateField(`items[${index}]`);
          errorFlag = true;
        }
      });

      if (errorFlag) {
        return;
      }

      setIsSubmitting(true);

      try {
        await submitForm({
          values,
          dispatch,
          apiCall,
          editContent,
          isFormData,
        });
      } catch {
        setIsSubmitting(false);
      }
    },
  });

  const onChangeDragItem = async (id, newValue) => {
    setItems((prev) =>
      prev.map((item) => (item.id === id ? { id, value: newValue } : item))
    );
    const index = items?.findIndex((item) => item?.id === id);
    if (index !== -1) {
      await formik.setFieldValue(`items[${index}]`, newValue);
      formik.validateField(`items[${index}]`);
    }
  };

  const deactivateDragAndDrop = () => {
    setItems(items.map((item) => ({ ...item, canDrag: false })));
  };

  const handleCanDrag = (id) => {
    setItems(
      items.map((item) => (item.id === id ? { ...item, canDrag: true } : item))
    );
  };

  const handleDeleteFormFieldArrayItem = (key, index) => {
    setItems((state) => state.filter((val, ind) => ind !== index));
    const newArray = [...formik.values[key]];
    newArray.splice(index, 1);
    formik.setFieldValue(key, [...newArray]);
  };

  const handleAddFormFieldArrayItem = () => {
    setItems((prev) => [...prev, { value: "", id: uuidv4(), canDrag: true }]);
  };

  useEffect(() => {
    dispatch(getAboutContent());
  }, []);

  useEffect(() => {
    if (editContent) {
      aboutSchema.forEach((elem) => {
        formik.setFieldValue(elem.name, editContent[elem.name]);
      });
    }
    setItems(
      editContent?.items?.map((item) => ({
        value: item,
        id: uuidv4(),
        canDrag: true,
      }))
    );
  }, [editContent]);

  return (
    <div className="form_container">
      <div className="form_back_btn_field">
        <Button
          variant="contained"
          className="form_back_btn"
          onClick={() => navigate(-1)}
        >
          <BackIcon width="16" height="16" className="back_icon" />
          {CONSTANTS.BACK_BUTTON}
        </Button>
      </div>
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.title}
            name={FORM_ITEM_NAMES_OBJ.title}
            label={CONSTANTS.TITLE}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.text}
            value={formik.values[FORM_ITEM_NAMES_OBJ.title]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
              isBoolean: true,
            })}
            helperText={
              formErrorHelper({
                formik,
                elementName: FORM_ITEM_NAMES_OBJ.title,
              }) ?? HELPER_TEXT
            }
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.description}
            name={FORM_ITEM_NAMES_OBJ.description}
            label={CONSTANTS.DESCRIPTION}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.textarea}
            multiline={true}
            minRows={FORM_TEXTAREA_ROWS_COUNT_OBJ.min}
            maxRows={FORM_TEXTAREA_ROWS_COUNT_OBJ.max}
            value={formik.values[FORM_ITEM_NAMES_OBJ.description]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.description,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.description,
            })}
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.subtitle}
            name={FORM_ITEM_NAMES_OBJ.subtitle}
            label={CONSTANTS.SUBTITLE}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.text}
            value={formik.values[FORM_ITEM_NAMES_OBJ.subtitle]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.subtitle,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.subtitle,
            })}
          />
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.subtitleDescription}
            name={FORM_ITEM_NAMES_OBJ.subtitleDescription}
            label={CONSTANTS.SUBTITLE_DESCRIPTION}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.textarea}
            multiline={true}
            minRows={FORM_TEXTAREA_ROWS_COUNT_OBJ.min}
            maxRows={FORM_TEXTAREA_ROWS_COUNT_OBJ.max}
            value={formik.values[FORM_ITEM_NAMES_OBJ.subtitleDescription]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.subtitleDescription,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.subtitleDescription,
            })}
          />
        </div>
        <div className="field_Container direction_column drag_container">
          <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
          >
            {items?.map((v, index) => (
              <StyledDragInput key={v.id}>
                <CardDrag
                  index={index}
                  key={v.id}
                  id={v.id}
                  canDrag={v.canDrag}
                  customClass="array_field about_array"
                  deactivateDragAndDrop={deactivateDragAndDrop}
                  isDragActive={isDragActive}
                  isActive={v.isActive}
                  isHovered={v.isHovered}
                  isHoveredItem={v.id === hoveredId}
                >
                  <TextField
                    fullWidth
                    id={`items[${index}]`}
                    name={`items[${index}]`}
                    label={`${CONSTANTS.ITEMS} ${index + 1}`}
                    variant={TEXTFIELD_VARIANTS_OBJ.outlined}
                    value={v.id === activeId ? hoveredDragItem?.value : v.value}
                    className="array_input_about"
                    onChange={(e) => onChangeDragItem(v.id, e.target.value)}
                    onFocus={(e) => {
                      e.stopPropagation();
                      setIsDragActive(false);
                    }}
                    onBlur={(e) => {
                      e.stopPropagation();
                      setIsDragActive(true);
                    }}
                    error={
                      formik.errors.items?.[index] &&
                      formErrorHelper({
                        formik,
                        elementName: FORM_ITEM_NAMES_OBJ.items,
                        isBoolean: true,
                      })
                    }
                    helperText={
                      formik.errors.items?.[index] && CONSTANTS.REQUIRED
                    }
                    InputProps={{
                      startAdornment: (
                        <StyledInputAdornment
                          position="start"
                          onMouseDown={(e) => {
                            e.stopPropagation();
                            handleCanDrag(v.id);
                          }}
                          onMouseUp={(e) => {
                            e.stopPropagation();
                            deactivateDragAndDrop(e);
                          }}
                        >
                          <DragIndicatorIcon className="drag_icon" />
                        </StyledInputAdornment>
                      ),
                    }}
                  />
                </CardDrag>
                <Tooltip title={CONSTANTS.REMOVE} arrow>
                  <Button
                    className="action_button custom_styles mx-1 shadow-none border-0 p-0 d-inline-flex align-items-center justify-content-center height_fit_content"
                    onClick={() =>
                      handleDeleteFormFieldArrayItem(
                        FORM_ITEM_NAMES_OBJ.items,
                        index
                      )
                    }
                  >
                    <DeleteIcon className="array_delete_icon about_delete_icon" />
                  </Button>
                </Tooltip>
              </StyledDragInput>
            ))}
            <DragOverlay>
              {activeId ? (
                <TextField
                  fullWidth
                  id={`${FORM_ITEM_NAMES_OBJ.items}${hoveredIndex}`}
                  name={`${items}${hoveredIndex}`}
                  label={`${CONSTANTS.ITEMS} ${hoveredIndex + 1}`}
                  variant={TEXTFIELD_VARIANTS_OBJ.outlined}
                  value={items.find((item) => item.id === activeId)?.value}
                  className="array_input_about"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DragIndicatorIcon className="drag_icon" />
                      </InputAdornment>
                    ),
                  }}
                />
              ) : null}
            </DragOverlay>
            <Button
              className="add_button add_button_margin"
              onClick={() =>
                handleAddFormFieldArrayItem(FORM_ITEM_NAMES_OBJ.items)
              }
            >
              {BUTTON_NAMES_OBJ.add} {CONSTANTS.ITEMS}
            </Button>
          </DndContext>
        </div>
        <SaveButton isLoading={isSubmitting} />
      </form>
    </div>
  );
};

export default EditAboutPage;
