import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Button, TextField } from "@mui/material";
import { Close, UploadFile } from "@mui/icons-material";

import { useActionHelper } from "hooks/useActionHelper";

import { createTopic, updateTopic } from "actions/departmentAcions";
import { departmentsSchema } from "./departmentsSchema";
import { formErrorHelper, formikValidation } from "helpers/formikValidation";
import { PAGE_NAME_OBJ } from "constants/pageName";
import {
  FORM_ITEM_NAMES_OBJ,
  FORM_ITEM_TYPES_OBJ,
  TEXTFIELD_VARIANTS_OBJ,
} from "service/constants";
import { submitForm } from "helpers/formikDataCollect";
import { CONSTANTS } from "constants/general";
import { SaveButton } from "components/Button";
import useOutsideClick from "hooks/useOutsideClick";

const image_url = process.env.REACT_APP_API_IMAGE_URL;

export const CreateEditDepartments = ({ isEdit, setIsCreateEdit }) => {
  const editTopicId = useSelector((state) => state.topics.edit.id);
  const editContent = useSelector((state) => state.topics.edit);
  const [showIcon, setShowIcon] = useState("");

  const modalRef = useRef();

  useOutsideClick(modalRef, () => setIsCreateEdit(false));

  const dispatch = useDispatch();
  const isFormData = true;

  const [createNewCTopic, editTopic] = useActionHelper({
    createNewItemApiCall: createTopic,
    updateItemApiCall: updateTopic,
    editId: editTopicId,
    navigateTo: PAGE_NAME_OBJ.departments,
    isEdit: true,
    setIsEdit: setIsCreateEdit,
  });

  const apiCall = !isEdit ? createNewCTopic : editTopic;

  const [initialValues, validation] = formikValidation(departmentsSchema);

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      submitForm({
        values,
        dispatch,
        apiCall,
        editContent,
        isFormData,
      });
    },
  });

  useEffect(() => {
    if (editContent) {
      departmentsSchema.forEach((elem) => {
        formik.setFieldValue(elem.name, editContent[elem.name]);
      });
    }
  }, [editTopicId]);

  return (
    <div ref={modalRef} className="form_container">
      <form onSubmit={formik.handleSubmit} className="create_edit_form">
        <div className="cancel_button">
          <Button onClick={() => setIsCreateEdit(false)}>
            <Close />
          </Button>
        </div>
        <div className="field_Container">
          <TextField
            fullWidth
            id={FORM_ITEM_NAMES_OBJ.title}
            name={FORM_ITEM_NAMES_OBJ.title}
            label={CONSTANTS.TITLE}
            variant={TEXTFIELD_VARIANTS_OBJ.outlined}
            type={FORM_ITEM_TYPES_OBJ.text}
            value={formik.values[FORM_ITEM_NAMES_OBJ.title]}
            onChange={formik.handleChange}
            error={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
              isBoolean: true,
            })}
            helperText={formErrorHelper({
              formik,
              elementName: FORM_ITEM_NAMES_OBJ.title,
            })}
          />
        </div>
        <div className="field_Container">
          <div className="image_wrapper">
            <label
              className={`create_edit_image ${
                formErrorHelper({
                  formik,
                  elementName: FORM_ITEM_NAMES_OBJ.icon,
                  isBoolean: true,
                }) && "create_edit_image_error"
              }`}
              htmlFor={FORM_ITEM_NAMES_OBJ.icon}
            >
              {showIcon ? (
                <img src={URL.createObjectURL(showIcon)} alt="Avatar" />
              ) : !!editContent ? (
                <img
                  src={`${image_url}${editContent[FORM_ITEM_NAMES_OBJ.icon]}`}
                  alt="Avatar"
                />
              ) : (
                <UploadFile />
              )}
            </label>
            <input
              id={FORM_ITEM_NAMES_OBJ.icon}
              name={FORM_ITEM_NAMES_OBJ.icon}
              type={FORM_ITEM_TYPES_OBJ.file}
              className="create_edit_image_input"
              onChange={(event) => {
                formik.setFieldValue(
                  FORM_ITEM_NAMES_OBJ.icon,
                  event.currentTarget.files[0]
                );
                setShowIcon(event.target.files[0]);
              }}
            />
          </div>
        </div>
        <SaveButton />
      </form>
    </div>
  );
};
