import * as React from "react";
import { CONSTANTS } from "constants/general";

import {
  BootstrapDialog,
  ConfirmButton,
  RefuseButton,
  BootstrapDialogActions,
  BootstrapDialogContent,
  StyledDialogTitle,
  StyledIconButton,
} from "./styledComponents";

import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...rest } = props;

  return (
    <StyledDialogTitle {...rest}>
      {children}
      {onClose ? (
        <StyledIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </StyledDialogTitle>
  );
}

const WarningDialogue = ({ open, close, confirmHandler, message, title }) => {
  const handleClose = () => {
    close();
  };

  const handleSubmit = () => {
    close();
    confirmHandler();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <BootstrapDialogContent dividers>
          <Typography gutterBottom>{message}</Typography>
        </BootstrapDialogContent>
        <BootstrapDialogActions>
          <RefuseButton autoFocus onClick={handleClose}>
            {CONSTANTS.CANCEL}
          </RefuseButton>
          <ConfirmButton autoFocus onClick={handleSubmit}>
            {CONSTANTS.CONFIRM}
          </ConfirmButton>
        </BootstrapDialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default WarningDialogue;
