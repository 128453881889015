import { createSlice } from "@reduxjs/toolkit";
import {
  getUsers,
  blockUnblockUser,
  deleteUser,
  getSubscriptionPlans,
  attachSubscriptionPlan,
  addTestCount,
} from "actions/usersActions";

const initialState = {
  data: [],
  totalCount: 0,
  isUsersFetching: false,
  isUpdatingUserStatus: false,
  isDeletingUser: false,
  subscriptionPlans: [],
  subscriptionsLoading: false,
  userSubscriptionPlans: [],
  actionLoading: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserSubscriptionPlans: (state, { payload }) => {
      state.userSubscriptionPlans = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state) => {
        state.isUsersFetching = true;
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.totalCount = payload?.meta?.total;
      })
      .addCase(getUsers.rejected, (state) => {
        state.isUsersFetching = false;
      })
      .addCase(blockUnblockUser.pending, (state) => {
        state.isUpdatingUserStatus = true;
      })
      .addCase(blockUnblockUser.fulfilled, (state) => {
        state.isUpdatingUserStatus = false;
      })
      .addCase(blockUnblockUser.rejected, (state) => {
        state.isUpdatingUserStatus = false;
      })
      .addCase(deleteUser.pending, (state) => {
        state.isDeletingUser = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.isDeletingUser = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.isDeletingUser = false;
      });
    builder
      .addCase(getSubscriptionPlans.pending, (state) => {
        state.subscriptionsLoading = true;
      })
      .addCase(getSubscriptionPlans.fulfilled, (state, { payload }) => {
        state.subscriptionsLoading = false;
        state.subscriptionPlans = payload?.data;
      })
      .addCase(getSubscriptionPlans.rejected, (state) => {
        state.subscriptionsLoading = false;
      });
    builder
      .addCase(attachSubscriptionPlan.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(attachSubscriptionPlan.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
      })
      .addCase(attachSubscriptionPlan.rejected, (state) => {
        state.actionLoading = false;
      });
    builder
      .addCase(addTestCount.pending, (state) => {
        state.actionLoading = true;
      })
      .addCase(addTestCount.fulfilled, (state, { payload }) => {
        state.actionLoading = false;
      })
      .addCase(addTestCount.rejected, (state) => {
        state.actionLoading = false;
      });
  },
});

export const { setUserSubscriptionPlans } = usersSlice.actions;

export default usersSlice.reducer;
