import { createSlice } from "@reduxjs/toolkit";
import { getAboutContent } from "actions/aboutActions";

const initialState = {
  data: {},
  status: null,
};

export const aboutSlice = createSlice({
  name: "about",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAboutContent.pending, (state) => {
        state.status = true;
      })
      .addCase(getAboutContent.fulfilled, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(getAboutContent.rejected, (state) => {
        state.status = false;
      })
  }
});

export default aboutSlice.reducer;
