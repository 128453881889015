import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryApi } from "service/categoryApi";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";

export const getCategories = createAsyncThunk(
  "/nordas/getCategories",
  async (_, { rejectWithValue }) => {
    try {
      const res = await categoryApi.getCategories();
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getCategoriesById = createAsyncThunk(
  "/nordas/getCategoriesById",
  async (id, { rejectWithValue }) => {
    try {
      const res = await categoryApi.getCategoriesById(id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getCategoriesByTopicId = createAsyncThunk(
  "/nordas/getCategoriesByTopicId",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const res = await categoryApi.getCategoriesByTopicId(id, params);
      return { ...res, isLoadMore: params.isLoadMore };
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getCategoriesByParentId = createAsyncThunk(
  "/nordas/getCategoriesByParentId",
  async (params, { rejectWithValue }) => {
    try {
      const res = await categoryApi.getCategoriesByParentId(params.id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  "/nordas/createCategory",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      await categoryApi.createCategory(data);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      dispatch(getCategories());
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "/nordas/deleteCategory",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      await categoryApi.deleteCategory(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getCategories());
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
