import * as Yup from "yup";
import { CONSTANTS, PASSWORD_MESSAGES } from "constants/general";

export const changePasswordSchema = [
  {
    id: "old_password",
    name: "old_password",
    label: CONSTANTS.OLD_PASSWORD,
    type: "password",
    placeholder: CONSTANTS.OLD_PASSWORD,
    backend_field: "old_password",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "new_password",
    name: "new_password",
    label: CONSTANTS.PASSWORD,
    type: "password",
    placeholder: CONSTANTS.PASSWORD,
    backend_field: "new_password",
    validation: Yup.string()
    .min(8, PASSWORD_MESSAGES.minCharacter)
    .matches(/[a-z]/, PASSWORD_MESSAGES.lowerCase)
    .matches(/[A-Z]/, PASSWORD_MESSAGES.upperCase)
    .matches(/\d/, PASSWORD_MESSAGES.number)
    .required(CONSTANTS.REQUIRED),
  },
  {
    id: "new_password_confirmation",
    name: "new_password_confirmation",
    label: CONSTANTS.CONFIRM_PASSWORD,
    type: "password",
    placeholder: CONSTANTS.CONFIRM_PASSWORD,
    backend_field: "new_password_confirmation",
    validation: Yup.string().oneOf([Yup.ref('new_password'), null], PASSWORD_MESSAGES.password_match)
    .min(8, PASSWORD_MESSAGES.minCharacter)
    .matches(/[a-z]/, PASSWORD_MESSAGES.lowerCase)
    .matches(/[A-Z]/, PASSWORD_MESSAGES.upperCase)
    .matches(/\d/, PASSWORD_MESSAGES.number)
    .required(CONSTANTS.REQUIRED),
  },
]
