import { styled } from "@mui/system";

import { InputAdornment } from "@mui/material";

export const StyledInputAdornment = styled(InputAdornment)(() => ({
  width: "40px",
  height: "60px",
  cursor: "move",
}));

export const StyledDragInput = styled("div")(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
}));
