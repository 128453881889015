export const LOCAL = {
  INVALID_LOGIN_OR_PASSWORD: "Invalid login or password",
  USER_BLOCKED: "User by this email has been blocked",
  SOMETHING_WANT_WRONG: "Something went wrong, try later",
  QUESTION: {
    VIEW: {
      QUESTION_SECTION: "Question Section",
      QUESTION_SUBJECT: "Question Subject",
      QUESTION_PRIVATE_SUBJECT: "Question Private Subject",
      QUESTION_LEVEL: "Question Level",
      QUESTION_TYPE: "Question Type",
      QUESTION_DESCRIPTION: "Question Description",
      QUESTION_OPTIONS: "Question Options",
      CORRECT: "Correct",
      DESCRIPTION: "Description",
      MEDIA_CONTENT: "Media Content",
      MULTIMEDIA_CONTENT: "Multimedia Content",
    },
  },
};
