export const answersArray = [
  {
    answer: "",
    is_correct: false,
  },
  {
    answer: "",
    is_correct: false,
  },
  {
    answer: "",
    is_correct: false,
  },
  {
    answer: "",
    is_correct: false,
  },
];

export const answersMatchingArray = [
  {
    answer: "",
  },
  {
    answer: "",
  },
  {
    answer: "",
  },
  {
    answer: "",
  },
];

export const answersPairingArray = [
  [
    {
      answer: "",
      position: "left",
    },
    {
      answer: "",
      position: "right",
    },
  ],
  [
    {
      answer: "",
      position: "left",
    },
    {
      answer: "",
      position: "right",
    },
  ],
  [
    {
      answer: "",
      position: "left",
    },
    {
      answer: "",
      position: "right",
    },
  ],
  [
    {
      answer: "",
      position: "left",
    },
    {
      answer: "",
      position: "right",
    },
  ],
];