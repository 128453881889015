import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const departmentsSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "icon",
    name: "icon",
    label: CONSTANTS.IMAGE,
    type: "file",
    placeholder: CONSTANTS.ENTER_IMAGE,
    backend_field: "icon",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
