import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { Stack } from "@mui/system";
import { COLOR } from "constants/styleConstants";

export const FilterStack = styled(Stack)({
  marginBottom: 6,
});

export const FilterChip = styled(Chip)({
  background: `${COLOR.primaryPurple} !important`,
  textTransform: "capitalize",
});

export const EmptyOverlayBlock = styled("div")({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
