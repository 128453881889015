export const PAGE_NAME_OBJ = {
  career: "career",
  about: "about",
  aboutItem: "aboutItem",
  blog: "blog",
  contacts: "contacts",
  recognitions: "recognitions",
  technologies: "technologies",
  pages: "pages",
  categories: "categories",
  portfolio: "portfolio",
  service: "service",
  serviceType: "serviceType",
  socialLinks: "socialLinks",
  teamDetails: "teamDetails",
  teamMembers: "teamMembers",
  departments: "departments",
  subjects: "subjects",
  coSubjects: "coSubjects",
};

export const FORM_ITEM_TYPES = {
  array: "array",
  text: "text",
  textarea: "textarea",
  select: "select",
  selectOfArray: "selectArray",
  projectOfArray: "projectArray",
  blogOfArray: "blogArray",
  secondSelect: "secondSelect",
  file: "file",
  oldArray: "oldArray",
  date: "date",
  textLocation: "textLocation",
  textContactInfo: "textContactInfo",
  CKEditor: "CKEditor",
  imageArray: "imageArray",
  otherServices: "other_services",
  careerArray: "careerArray",
  password: "password",
  checkbox: "checkbox",
  questionArray: "questionArray",
  mediaFile: "mediaFile",
};
