import { createSlice } from "@reduxjs/toolkit";
import {
  getQuestions,
  getFixedQuizQuestions,
  getQuestionById,
} from "actions/questionsActions";

const initialState = {
  questions: [],
  question: {},
  status: null,
  total: null,
  appliedFilters: [],
};

export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    clearQuestionById: (state) => {
      state.question = {};
    },
    setAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload;
    },
    clearQuestions: (state) => {
      state.questions = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getQuestions.pending, (state) => {
        state.status = true;
      })
      .addCase(getQuestions.fulfilled, (state, { payload }) => {
        state.status = false;
        state.questions = payload?.data;
        state.total = payload?.meta?.total;
      })
      .addCase(getQuestions.rejected, (state) => {
        state.status = false;
      })
      .addCase(getFixedQuizQuestions.pending, (state) => {
        state.status = true;
      })
      .addCase(getFixedQuizQuestions.fulfilled, (state, { payload }) => {
        state.status = false;
        state.questions = payload?.data;
        state.total = payload?.meta?.total;
      })
      .addCase(getFixedQuizQuestions.rejected, (state) => {
        state.status = false;
      })
      .addCase(getQuestionById.pending, (state) => {
        state.status = true;
      })
      .addCase(getQuestionById.fulfilled, (state, { payload }) => {
        state.status = false;
        state.question = payload?.data;
      })
      .addCase(getQuestionById.rejected, (state) => {
        state.status = false;
      });
  },
});

export const { clearQuestionById, setAppliedFilters, clearQuestions } =
  questionsSlice.actions;

export default questionsSlice.reducer;
