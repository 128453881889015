import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import { ComponentHeader } from "components";
import { deleteTopic, getTopics } from "actions/departmentAcions";
import { CreateEditDepartments } from "./createEditDepartments";
import { setTopicEdit } from "features/departmentSlice";
import ModalWindow from "components/ModalWindow";
import { DeleteIcon, EditIcon } from "components/icons";
import { useSearchParams } from "hooks/useSearchParams";
import { CONSTANTS } from "constants/general";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const Departments = () => {
  const [isCreateEdit, setIsCreateEdit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [deletedItemId, setDeletedItemId] = useState(null);
  const { search } = useSearchParams();

  const dispatch = useDispatch();
  const topics = useSelector((state) => state.topics.data);

  const handleCreate = () => {
    setIsCreateEdit(true);
    setIsEdit(false);
    dispatch(setTopicEdit(""));
  };

  const handleEdit = (e, data) => {
    e.preventDefault();
    setIsCreateEdit(true);
    setIsEdit(true);
    dispatch(setTopicEdit(data));
  };

  const openModal = (e, id) => {
    e.preventDefault();
    setDeletedItemId(id);
    setOpenWindow(true);
  };

  useEffect(() => {
    dispatch(getTopics({ search }));
  }, [search]);

  return (
    <div className="departments_container">
      <ComponentHeader create handleCreateEdit={handleCreate} />
      <div className="department_wrapper">
        {openWindow && (
          <ModalWindow
            open={openWindow}
            setOpen={setOpenWindow}
            id={deletedItemId}
            deleteRequest={deleteTopic}
            message={CONSTANTS.DELETE_DEPARTMENT_MESSAGE}
          />
        )}
        <div
          className={`mb-spacing-6 sections_department 
          ${isCreateEdit && "disable_mouse_events"}`}
        >
          <div className="grid_container">
            {topics?.map((topic) => (
              <Link
                key={topic.id}
                to={`/departments/subject/${topic?.id}`}
                state={{ title: topic?.title }}
              >
                <div className="department_grid">
                  <div className="element_header">
                    <Tooltip title={CONSTANTS.EDIT} arrow>
                      <Button
                        variant="contained"
                        className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={(e) => handleEdit(e, topic)}
                      >
                        <EditIcon className="font-size-lg edit_icon" />
                      </Button>
                    </Tooltip>
                    <Tooltip title={CONSTANTS.REMOVE} arrow>
                      <Button
                        variant="contained"
                        className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
                        onClick={(e) => openModal(e, topic?.id)}
                      >
                        <DeleteIcon className="font-size-lg edit_icon" />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="grid_image">
                    <img
                      src={imageUrl + topic?.icon}
                      alt="Department element"
                    />
                  </div>
                  <div className="grid_name">
                    <p>{topic?.title}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="create_edit">
        {isCreateEdit && (
          <div className="popup">
            <CreateEditDepartments
              isEdit={isEdit}
              setIsCreateEdit={setIsCreateEdit}
            />
          </div>
        )}
      </div>
    </div>
  );
};
