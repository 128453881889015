import { createAsyncThunk } from "@reduxjs/toolkit";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { questionsApi } from "service/questionsApi";

export const getQuestions = createAsyncThunk(
  "/nordas/getQuestions",
  async ({ params }, { rejectWithValue }) => {
    try {
      const res = await questionsApi.getQuestions(params);
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const getFixedQuizQuestions = createAsyncThunk(
  "/nordas/getFixedQuizQuestions",
  async ({ params }, { rejectWithValue }) => {
    try {
      const res = await questionsApi.getFixedQuizQuestions(params);
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const createQuestion = createAsyncThunk(
  "/nordas/createQuestion",
  async ({ data, navigate }, { rejectWithValue }) => {
    try {
      await questionsApi.createQuestion(data);
      navigate(-1);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const getQuestionById = createAsyncThunk(
  "/nordas/getQuestionsById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await questionsApi.getQuestionById(id);
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const editQuestion = createAsyncThunk(
  "/nordas/createQuestion",
  async ({ id, data, navigate }, { rejectWithValue }) => {
    try {
      await questionsApi.updateQuestion(id, data);
      navigate(-1);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "/nordas/deleteQuestion",
  async ({ id, params }, { dispatch, rejectWithValue }) => {
    try {
      await questionsApi.deleteQuestion(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(getQuestions({ params }));
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const changeActivation = createAsyncThunk(
  "/nordas/questionActivation",
  async ({ id, data, params }, { dispatch, rejectWithValue }) => {
    try {
      await questionsApi.changeQuestionActivation(id, data);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
      dispatch(getQuestions({ params }));
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);
