import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAboutContent } from "actions/aboutActions";
import { ComponentHeader } from "components";
import { ADD_ONE_TO_INDEX } from "constants/general";

export const AboutPage = () => {
  const dispatch = useDispatch();
  const aboutContent = useSelector((state) => state.about.data);
  const replacedText = aboutContent?.title?.replace(/{{(.*?)}}/g, '<span>$1</span>');

  useEffect(() => {
    dispatch(getAboutContent());
  }, [])

  return (
    <div className="about_container">
      <ComponentHeader edit editPath="/about/edit" />
      <div className="head_section">
        <h1 dangerouslySetInnerHTML={{__html: replacedText}} className="about_title"></h1>
        <p className="about_description">{aboutContent?.description}</p>
      </div>
      <div className="main_section">
        <h4 className="about_subtitle">{aboutContent?.subtitle}</h4>
        <p className="about_subdesc">{aboutContent?.subtitleDescription}</p>
        <div className="about_elements_container">
          {aboutContent?.items?.map((elem, index) => (
            <div key={index} className="element">
              <span className="element_number">{index + ADD_ONE_TO_INDEX}</span>
              <p className="element_name">{elem}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
