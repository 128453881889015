import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import {
  changeActivation,
  deleteQuestion,
  getQuestions,
} from "actions/questionsActions";
import ModalWindow from "components/ModalWindow";
import { QuestionTable } from "components/QuestionTable";
import { CONSTANTS, defaultSortParams } from "constants/general";
import FilterHelpers from "helpers/filter";
import { ComponentHeader } from "components";
import { useSearchParams } from "hooks/useSearchParams";
import { clearQuestionById, setAppliedFilters } from "features/questionsSlice";

export const DepartmentQuestions = () => {
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(CONSTANTS.FIRST_PAGE);
  const [sort, setSort] = useState(defaultSortParams);
  const [backPath, setBackPath] = useState("");
  const { search } = useSearchParams();

  const params = useRef({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const questions = useSelector((state) => state.questions.questions);
  const totalCount = useSelector((state) => state.questions.total);
  const appliedFiltersFromRedux = useSelector(
    (state) => state.questions.appliedFilters
  );
  const { category_id, id, subject_id } = useParams();

  let appliedFilterParams = {};

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFiltersToParams,
    appliedFilters,
  } = FilterHelpers(appliedFiltersFromRedux);

  const handleEdit = (e, id) => {
    e.stopPropagation();
    dispatch(clearQuestionById());
    navigate(`/departments/question/edit/${id}`);
  };

  const handleToggleChange = (id, active) => {
    const data = {
      is_active: !active,
    };
    dispatch(changeActivation({ id, data, params: params.current }));
  };

  useEffect(() => {
    dispatch(setAppliedFilters(appliedFilters));
  }, [appliedFilters, page, sort]);

  useEffect(() => {
    setBackPath(`/departments/subject/${id}/co-subject/${subject_id}`);
  }, [id, subject_id]);

  useEffect(() => {
    appliedFilterParams = appliedFiltersToParams(appliedFiltersFromRedux);
    params.current = {
      search,
      page,
      ...sort,
      for_diagnostic_quiz: 0,
      filters: {
        category_id,
        topic_id: id,
        level: appliedFilterParams?.level || "",
        type: appliedFilterParams?.type || "",
        completed: appliedFilterParams?.status ?? "",
        is_active: appliedFilterParams?.is_active ?? "",
      },
    };
    dispatch(getQuestions({ params: params.current }));
  }, [appliedFiltersFromRedux, page, sort, search]);

  useEffect(() => {
    setPage(CONSTANTS.FIRST_PAGE);
  }, [search]);

  return (
    <div className="table_page_body_container relative">
      <ComponentHeader back isDepartment backPath={backPath} />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          currentPage={page}
          message={CONSTANTS.DELETE_QUESTION_MESSAGE}
          deleteRequest={deleteQuestion}
          params={params.current}
        />
      )}
      <QuestionTable
        data={questions}
        setDeleteId={setDeleteId}
        setOpen={setOpen}
        totalCount={totalCount}
        handleApplyFilter={handleApplyFilter}
        handleRemoveFilter={handleRemoveFilter}
        handleClearFilters={handleClearFilters}
        appliedFilters={appliedFilters}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        handleEdit={handleEdit}
        isDepartment={true}
        handleToggleChange={handleToggleChange}
        showToggle={true}
      />
    </div>
  );
};
