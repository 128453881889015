import React from "react";
import { ReactComponent as Logo } from "assets/images/sidebarHeader/icon.svg";

import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot, faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { Button, Tooltip } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "features/sidebarSlice";

import miniLogo from "assets/images/sidebarHeader/miniLogo.svg";

const SidebarHeader = () => {
  const { sidebarToggleMobile, sidebarToggle } = useSelector(
    (state) => state.sidebar
  );

  const dispatch = useDispatch();

  const toggleSidebarMobile = () => {
    dispatch(setSidebarToggleMobile(!sidebarToggleMobile));
  };
  const toggleSidebar = () => {
    dispatch(setSidebarToggle(!sidebarToggle));
  };

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <NavLink
            to="/"
            title="Bamburgh React Admin Dashboard with Material-UI PRO"
            className="app-sidebar-logo"
          >
            <div className="app-sidebar-logo--icon">
              <Logo />
            </div>
          </NavLink>
        </div>
        <div className="app-sidebar-collapsed-logo" style={{ display: "none" }}>
          <img src={miniLogo} alt="logo" />
        </div>
        <Tooltip title="Collapse sidebar" placement="right" arrow>
          <Button
            onClick={toggleSidebar}
            className="btn btn-sm collapse-sidebar-btn"
          >
            <FontAwesomeIcon icon={faCircleDot} size="lg" />
          </Button>
        </Tooltip>
        <Button
          className={clsx(
            "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
            { "is-active": sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip title="Expand sidebar" placement="right" arrow>
          <Button
            onClick={toggleSidebar}
            className="expand-sidebar-btn btn btn-sm"
          >
            <FontAwesomeIcon icon={faArrowsAltH} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default SidebarHeader;
