import { Button, Tooltip } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CONSTANTS } from "constants/general";

import { BackIcon, CreateIcon, EditFillIcon } from "components/icons";

const ComponentHeader = ({
  edit,
  editPath,
  create,
  handleCreateEdit,
  back,
  noMargin,
  isDepartment,
  disableCreateButton,
  backPath,
  isFixed,
}) => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const handleBack = () => {
    navigate(backPath || -1, { state });
  };
  return (
    <div
      className={`pages_header ${noMargin && "no_margin"} ${
        isDepartment && "add_position"
      }`}
    >
      <div>
        {back && (
          <Button
            variant="contained"
            className="back_button"
            onClick={handleBack}
          >
            <BackIcon width="16" height="16" className="back_icon" />
            {CONSTANTS.BACK_BUTTON}
          </Button>
        )}
      </div>
      <div className={disableCreateButton && "width_400"}>
        {edit && (
          <Link to={editPath}>
            <Tooltip title={CONSTANTS.EDIT} arrow>
              <Button
                variant="contained"
                className="action_button create_edit_action btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              >
                <EditFillIcon width="32" height="32" className="font-size-lg" />
              </Button>
            </Tooltip>
          </Link>
        )}
        {create && (
          <Tooltip
            title={
              disableCreateButton
                ? isFixed
                  ? CONSTANTS.MAX_FIXED_QUIZ_QUESTIONS_COUNT_MESSAGE
                  : CONSTANTS.MAX_QUESTIONS_COUNT_MESSAGE
                : CONSTANTS.CREATE
            }
            arrow
          >
            <Button
              variant="outlined"
              className="action_button create_edit_action btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              onClick={handleCreateEdit}
              disabled={disableCreateButton}
            >
              <CreateIcon width="32" height="32" className="font-size-lg" />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ComponentHeader;
