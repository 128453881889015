import { createAsyncThunk } from "@reduxjs/toolkit";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { subjectsApi } from "service/subjectsApi";

export const getSubjects = createAsyncThunk(
  "/nordas/getSubjects",
  async ({ id, page }, { rejectWithValue }) => {
    try {
      const res = await subjectsApi.getSubject(id, page);
      return res.data.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const getCoSubjects = createAsyncThunk(
  "/nordas/getCoSubjects",
  async ({ id, page, search }, { rejectWithValue }) => {
    try {
      const res = await subjectsApi.getCoSubjects(id, page, search);
      return res.data;
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const createSubject = createAsyncThunk(
  "/nordas/createSubject",
  async ({ topicId, data, page, subjectId }, { rejectWithValue, dispatch }) => {
    try {
      await subjectsApi.createSubject({ data });
      if (subjectId) {
        dispatch(getCoSubjects({ id: subjectId }));
      } else {
        dispatch(getSubjects({ id: topicId, page }));
      }
      Notify(NOTIFY.type.success, NOTIFY.message.add);
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const updateSubject = createAsyncThunk(
  "/nordas/updateSubject",
  async (
    { topicId, id, data, page, subjectId },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await subjectsApi.updateSubject({ id, data });
      if (subjectId) {
        dispatch(getCoSubjects({ id: subjectId }));
      } else {
        dispatch(getSubjects({ id: topicId, page }));
      }
      Notify(NOTIFY.type.success, NOTIFY.message.update);
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "/nordas/deleteSubject",
  async ({ id, page, getId, isChild }, { rejectWithValue, dispatch }) => {
    try {
      await subjectsApi.deleteSubject(id);
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
      dispatch(
        isChild
          ? getCoSubjects({ id: getId, page })
          : getSubjects({ id: getId, page })
      );
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);
