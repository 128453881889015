import { Routes, Route, Navigate } from "react-router";
import { useSelector } from "react-redux";

import Login from "pages/login";
import LeftSidebar from "LeftSidebar";

import {
  ChangePassword,
  AboutPage,
  EditAboutPage,
  Departments,
  SubjectsTable,
  CreateEditSubject,
  CoSubjects,
  CreateEditCoSubject,
  UsersList,
  DiagnosticQuiz,
  DepartmentQuestions,
  Questions,
  CreateEditQuestion,
  ViewQuestion,
  DiagnosticQuizQuestions,
  CreateEditDiagnosticQuestion,
} from "pages";
import { FixedQuiz } from "pages/FixedQuiz/fixedQuiz";
import { FixedQuizQuestions } from "pages/FixedQuiz/questions/fixedQuestionsTable";

const PageRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {!isAuth ? (
        <Routes>
          <Route path="/*" element={<Login />} />
        </Routes>
      ) : (
        <LeftSidebar>
          <Routes>
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/about/edit" element={<EditAboutPage />} />
            <Route path="/departments" element={<Departments />} />
            <Route path="/user-management" element={<UsersList />} />
            <Route
              path="/departments/subject/:id"
              element={<SubjectsTable />}
            />
            <Route
              path="/departments/subject/:id/create"
              element={<CreateEditSubject />}
            />
            <Route
              path="/departments/subject/:id/edit"
              element={<CreateEditSubject />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id"
              element={<CoSubjects />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id/edit"
              element={<CreateEditCoSubject />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id/create"
              element={<CreateEditCoSubject />}
            />
            <Route
              path="/departments/subject/:id"
              element={<SubjectsTable />}
            />
            <Route
              path="/departments/subject/:id/create"
              element={<CreateEditSubject />}
            />
            <Route
              path="/departments/subject/:id/edit"
              element={<CreateEditSubject />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id"
              element={<CoSubjects />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id/department-questions/:category_id"
              element={<DepartmentQuestions />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id/edit"
              element={<CreateEditCoSubject />}
            />
            <Route
              path="/departments/subject/:id/co-subject/:subject_id/create"
              element={<CreateEditCoSubject />}
            />
            <Route path="/questions" element={<Questions />} />
            <Route
              path="/questions/create"
              element={<CreateEditQuestion isGenerated isCreate />}
            />
            <Route
              path="/questions/edit/:id"
              element={<CreateEditQuestion isGenerated />}
            />
            <Route
              path="/departments/question/edit/:id"
              element={<CreateEditQuestion />}
            />
            <Route path="/questions/:id" element={<ViewQuestion />} />
            <Route
              path="/departments/question/:id"
              element={<ViewQuestion />}
            />
            <Route
              path="/diagnostic-quiz/diagnostic-question/:id"
              element={<ViewQuestion isDiagnostic />}
            />
            <Route
              path="/fixed-quiz/fixed-quiz-question/:id"
              element={<ViewQuestion isFixed />}
            />
            <Route path="/diagnostic-quiz" element={<DiagnosticQuiz />} />
            <Route path="/fixed-quiz" element={<FixedQuiz />} />
            <Route
              path="/diagnostic-quiz/:id/topic/:topic_id/diagnostic-questions/:category_id"
              element={<DiagnosticQuizQuestions />}
            />
            <Route
              path="/fixed-quiz/:id/topic/:topic_id/fixed-quiz-questions/:category_id"
              element={<FixedQuizQuestions />}
            />
            <Route
              path="/diagnostic-quiz/:quiz_id/topic/:topic_id/diagnostic-questions/:category_id/create"
              element={<CreateEditDiagnosticQuestion isCreate />}
            />
            <Route
              path="/fixed-quiz/:quiz_id/topic/:topic_id/fixed-quiz-questions/:category_id/create"
              element={<CreateEditDiagnosticQuestion isCreate isFixedQuiz />}
            />
            <Route
              path="/diagnostic-quiz/:quiz_id/topic/:topic_id/diagnostic-questions/:category_id/edit/:id"
              element={<CreateEditDiagnosticQuestion />}
            />
            <Route
              path="/fixed-quiz/:quiz_id/topic/:topic_id/fixed-quiz-questions/:category_id/edit/:id"
              element={<CreateEditDiagnosticQuestion isFixedQuiz />}
            />
            <Route path="/*" element={<Navigate to="/departments" replace />} />
          </Routes>
        </LeftSidebar>
      )}
    </>
  );
};

export default PageRoutes;
