import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  Popover,
  OutlinedInput,
} from "@mui/material";
import {
  ApplyButton,
  FilterButton,
  FormWrapper,
  StyledFormControl,
} from "./styled";
import { CONSTANTS } from "constants/general";
import { makeCapitalize } from "helpers/textCapitalize";
import { FilterIcon } from "components/icons";

export const Filter = ({
  applyFilter,
  filtersList,
  appliedFilters,
  setTablePage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFilterChange = (event, filterIndex, queryKey, valueName) => {
    const { target } = event;
    const newFilters = [...selectedFilters];
    newFilters[filterIndex] = {
      ...newFilters[filterIndex],
      ...target,
      queryKey,
      valueName,
    };
    setSelectedFilters(newFilters);
  };

  const handleApplyFilter = () => {
    applyFilter(selectedFilters);
    setTablePage(1);
    handleClose();
  };

  useEffect(() => {
    setSelectedFilters(appliedFilters);
  }, [appliedFilters]);

  return (
    <div
      style={{
        width: "100%",
        padding: "20px",
        display: "flex",
        justifyContent: "flex-end",
        margin: 10,
      }}
    >
      <FilterButton variant="outlined" onClick={handleClick}>
        <FilterIcon /> {CONSTANTS.FILTER}
      </FilterButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="add_gap"
      >
        <FormWrapper>
          {filtersList.map((filter, idx) => {
            return (
              <StyledFormControl key={idx} fullWidth size="small">
                <InputLabel
                  htmlFor={`outlined-age-native-simple-${idx}`}
                  className="custom-label"
                >
                  {filter.column}
                </InputLabel>
                <Select
                  name={filter.column}
                  value={selectedFilters[idx]?.value ?? ""}
                  onChange={(e) =>
                    handleFilterChange(
                      e,
                      idx,
                      filter.queryKey,
                      filter.valueName
                    )
                  }
                  className="custom-select"
                  input={
                    <OutlinedInput
                      name={filter.column}
                      label={filter.column}
                      id={`outlined-age-native-simple-${idx}`}
                      className="custom-input"
                    />
                  }
                >
                  {filter.values.map((filterItem, index) => (
                    <MenuItem key={index} value={filterItem.value}>
                      {makeCapitalize(filterItem.name)}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            );
          })}
          <ApplyButton onClick={handleApplyFilter}>
            {CONSTANTS.APPLY}
          </ApplyButton>
        </FormWrapper>
      </Popover>
    </div>
  );
};
