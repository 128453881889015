import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Menu, List, Divider } from "@mui/material";

import { logout } from "features/auth";
import { Button } from "@mui/material";

import Logo from "assets/images/sidebarHeader/logo.svg";
import { CONSTANTS } from "constants/general";
import { MainListItem } from "components/Styled/MainListItem";

const HeaderUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const authLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="ml-2 btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
      >
        <div className="d-block p-0 avatar-icon-wrapper">
          <div className="avatar-icon rounded">
            <img src={Logo} alt="Nor Das" />
          </div>
        </div>
        <span className="pl-1 pt-1 avatar-transform">
          <FontAwesomeIcon icon={faAngleDown} className={`opacity-5 transform-rotate-0 ${anchorEl && 'transform-rotate-180'}`} />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ list: "p-0" }}
        onClose={handleClose}
      >
        <div className="dropdown-menu-lg overflow-hidden p-0">
          <List
            component="div"
            className="nav-neutral-primary text-left d-flex flex-column px-3 pb-3"
          >
            <MainListItem
              button
              className="d-block text-left"
              onClick={() => {
                handleClose();
                navigate("/change-password");
              }}
            >
              {CONSTANTS.CHANGE_PASSWORD}
            </MainListItem>
            <MainListItem
              button
              className="d-block text-left"
              onClick={authLogout}
            >
              {CONSTANTS.LOG_OUT}
            </MainListItem>
          </List>
          <Divider className="w-100" />
        </div>
      </Menu>
    </>
  );
};

export default HeaderUserbox;
