import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Notify from "helpers/Notify";
import { CONSTANTS } from "constants/general";
import { NOTIFY } from "constants/notifyType";
import { getError } from "helpers/getError";

export const useActionHelper = ({
  createNewItemApiCall,
  updateItemApiCall,
  editId,
  navigateTo,
  isEdit = false,
  setIsEdit,
  customNotify = "",
  close,
  topicId,
  page,
  subjectId,
  resetLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const createNewItem = async (data) => {
    if (isEdit) {
      dispatch(createNewItemApiCall({ data, topicId, subjectId }));
      if (!navigateTo && !close) {
        navigate(-1);
      } else {
        if (close) close();
      }
      if (setIsEdit) setIsEdit(false);
      if (resetLoading) resetLoading();
    } else {
      createNewItemApiCall({ ...data, topicId, subjectId })
        .then((res) => {
          Notify(CONSTANTS.SUCCESS, customNotify ?? CONSTANTS.SUCCESS);
          if (resetLoading) resetLoading();
          return res;
        })
        .then(() => {
          if (!navigateTo && !close) {
            navigate(-1);
          } else {
            if (close) close();
          }
          if (resetLoading) resetLoading();
        })
        .catch((err) => {
          Notify(NOTIFY.type.error, getError(err));
          if (resetLoading) resetLoading();
        });
    }
  };

  const editItem = (data) => {
    if (isEdit) {
      dispatch(updateItemApiCall({ id: editId, topicId, data, subjectId }));
      if (!navigateTo && !close) {
        navigate(-1);
      } else {
        if (close) close();
      }
      if (setIsEdit) setIsEdit(false);
    } else {
      updateItemApiCall(data)
        .then((res) => {
          Notify(CONSTANTS.SUCCESS, customNotify ?? CONSTANTS.SUCCESS);
          return res;
        })
        .then(() => {
          navigateTo ? navigate(navigateTo) : navigate(-1);
          if (setIsEdit) setIsEdit(false);
        })
        .catch((err) => Notify(NOTIFY.type.error, getError(err)));
    }
  };

  return [createNewItem, editItem];
};
