import Button from "@mui/material/Button";
import { DIALOGUE } from "./constants";
import { StatusLabel } from "components/StatusLable";
import { BlockedIcon, UnBlockedIcon, DeleteIcon } from "components/icons";
import { USER_STATUS } from "service/constants";
import { Tooltip } from "@mui/material";
import { CONSTANTS } from "constants/general";
import { SubscriptionsOutlined } from "@mui/icons-material";

export const getColumns = (openWarningDialogue, openSubscription) => [
  {
    field: "full_name",
    headerName: "Full Name",
    sortable: false,
    filterable: false,
    flex: 1,
    width: 130,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    filterable: false,
    flex: 1,
    width: 130,
  },
  {
    field: "created_at",
    headerName: "Registered Date",
    filterable: false,
    flex: 1,
    width: 80,
    renderCell: (params) => {
      if (!params.value) return "-";
      const date = new Date(params.value);
      return date.toLocaleDateString();
    },
  },
  {
    headerName: "Success / Total Tests",
    filterable: false,
    flex: 1,
    width: 60,
    renderCell: ({ row }) => {
      return row.total_count_user_result
        ? `${row.success_count_user_result ?? "-"}/${
            row.total_count_user_result ?? "-"
          }`
        : "-";
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 180,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const handleDelete = async (event) => {
        event.stopPropagation();
        openWarningDialogue({ type: DIALOGUE.delete, userId: row.id });
      };

      const handleBlockUnblock = async (event) => {
        event.stopPropagation();
        openWarningDialogue({
          type: row.deleted_at ? DIALOGUE.unblock : DIALOGUE.block,
          userId: row.id,
        });
      };

      const handleOpenSubscription = async (event) => {
        event.stopPropagation();
        openSubscription({
          id: row.id,
          plans: row.subscription_plans,
          quizType: row.quiz_type,
        });
      };

      return (
        <div className="user_actions_container flex_center">
          {row.deleted_at ? (
            <StatusLabel
              className="blocked_label hover_label"
              onClick={handleBlockUnblock}
              name={USER_STATUS.blocked}
            >
              <BlockedIcon width="20" height="20" />
            </StatusLabel>
          ) : (
            <StatusLabel
              className="completed_label hover_label"
              onClick={handleBlockUnblock}
              name={USER_STATUS.active}
            >
              <UnBlockedIcon width="20" height="20" />
            </StatusLabel>
          )}
          <div className="actions_container">
            <Tooltip title={CONSTANTS.SUBSCRIPTIONS} arrow>
              <Button
                className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
                variant="contained"
                color="secondary"
                onClick={handleOpenSubscription}
              >
                <SubscriptionsOutlined
                  className="font-size-lg subscription_icon"
                  color="#F58C69"
                />
              </Button>
            </Tooltip>
            <Tooltip title={CONSTANTS.REMOVE} arrow>
              <Button
                className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                <DeleteIcon className="font-size-lg edit_icon" />
              </Button>
            </Tooltip>
          </div>
        </div>
      );
    },
  },
];
