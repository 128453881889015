import React from "react";
import { StatusLabel } from "components/StatusLable";
import { CheckIcon } from "components/icons";
import { LOCAL } from "local/localizations";
import { sanitize } from "dompurify";

export const OneOrMoreCorrectAnswers = ({ answers }) => {
  const getHighlight = (str) => {
    const htmlText = str?.replaceAll("{{", "<b>")?.replaceAll("}}", "</b>");
    return { __html: sanitize(htmlText) };
  };

  return (
    <div className="answer_container">
      {answers?.map((item, i) => {
        return item.is_correct ? (
          <div key={item.id} className="correct_answer">
            <span
              className="answer mr-2"
              dangerouslySetInnerHTML={getHighlight(`${i + 1}. ${item.answer}`)}
            ></span>
            <StatusLabel
              className="completed_label"
              name={LOCAL.QUESTION.VIEW.CORRECT}
            >
              <CheckIcon width="20" height="20" />
            </StatusLabel>
          </div>
        ) : (
          <span
            key={item.id}
            className="answer mr-1"
            dangerouslySetInnerHTML={getHighlight(`${i + 1}. ${item.answer}`)}
          ></span>
        );
      })}
    </div>
  );
};
