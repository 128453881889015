export const NOTIFY = {
  type: {
    success: "success",
    error: "error",
  },
  message: {
    add: "Successfully added",
    delete: "Successfully removed",
    update: "Successfully updated",
    wrong: "Something went wrong",
    old_password: "Old password is incorrect",
    status_change: "Status changed successfully",
  },
};

export const getNotificationMessages = (name, key) => {
  return name + " " + NOTIFY.message[key];
};
