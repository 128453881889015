import { createSlice } from "@reduxjs/toolkit";
import { getFixedQuizzes } from "actions/fixedQuizzesActions";

const initialState = {
  data: {},
  status: null,
  totalCount: 0,
  appliedFilters: [],
};

export const fixedQuizSlice = createSlice({
  name: "fixedQuiz",
  initialState,
  reducers: {
    setAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFixedQuizzes.pending, (state) => {
        state.status = true;
      })
      .addCase(getFixedQuizzes.fulfilled, (state, { payload }) => {
        state.data = payload.data.data.map((d) => ({
          ...d,
          title: d.category.title,
        }));
        state.totalCount = payload.data.total;
      })
      .addCase(getFixedQuizzes.rejected, (state) => {
        state.status = false;
      });
  },
});

export const { setAppliedFilters } = fixedQuizSlice.actions;

export default fixedQuizSlice.reducer;
