// MediaDialog.js
import React from "react";
import ReactDOM from "react-dom";
import { ResponsiveImage } from "components/ResponsiveImage";
import { ResponsiveVideo } from "components/ResponsiveVideo";
import { determineVideoType, isImage, isVideo } from "utiles";
import styles from "./MediaDialog.module.scss";

export const MediaDialog = ({ isOpen, dialogData, closeDialog }) => {
  if (!isOpen) return null;

  const mediaUrl = dialogData.url || dialogData.src || "";

  return ReactDOM.createPortal(
    <div className={styles.dialogOverlay} onClick={closeDialog}>
      <div
        className={styles.dialogContent}
        onClick={(e) => e.stopPropagation()}
      >
        {(isImage(mediaUrl) || isImage(dialogData.mediaUrlType)) && (
          <ResponsiveImage
            src={mediaUrl}
            alt="Dialog content"
            className={styles.media}
          />
        )}
        {(isVideo(mediaUrl) || isVideo(dialogData.mediaUrlType)) && (
          <ResponsiveVideo
            type={determineVideoType(mediaUrl) || "video/mp4"}
            src={mediaUrl}
            controls
            className={styles.media}
          >
            Your browser does not support the video tag.
          </ResponsiveVideo>
        )}
      </div>
    </div>,
    document.getElementById("media_dialog_root")
  );
};
