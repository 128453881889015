import { CONSTANTS, HELPER_TEXT } from "constants/general";
import * as Yup from "yup";

export const aboutSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
    helperText: HELPER_TEXT,
  },
  {
    id: "description",
    name: "description",
    label: CONSTANTS.DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_DESCRIPTION,
    backend_field: "description",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "subtitle",
    name: "subtitle",
    label: CONSTANTS.SUBTITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_SUBTITLE,
    backend_field: "subtitle",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "subtitleDescription",
    name: "subtitleDescription",
    label: CONSTANTS.SUBTITLE_DESCRIPTION,
    type: "textarea",
    placeholder: CONSTANTS.ENTER_SUBTITLE_DESC,
    backend_field: "subtitleDescription",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "items",
    name: "items",
    label: CONSTANTS.ITEMS,
    type: "array",
    placeholder: CONSTANTS.ENTER_ITEMS,
    backend_field: "items",
    validation: Yup.array().of(Yup.string().required(CONSTANTS.REQUIRED)),
  },
];
