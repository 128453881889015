import instance from "./baseRequest";

export const categoryApi = {
  getCategories: async () => {
    const res = await instance.get("/admin/categories");
    return res.data;
  },
  getCategoriesByTopicId: async (id, params) => {
    const res = await instance.get(`/admin/get-exam-subjects/${id}`, {
      params,
    });
    return res.data;
  },
  getCategoriesByParentId: async (id) => {
    const res = await instance.get(`/get-exam-subjects-by-parent-id/${id}`);
    return res.data;
  },
  getCategoriesById: async (id) => {
    const res = await instance.get(`/admin/categories/${id}`);
    return res.data;
  },
  createCategory: async (body) => {
    return await instance.post("/admin/categories", body);
  },
  deleteCategory: async (id) => {
    return await instance.delete(`/admin/categories/${id}`);
  },
};
