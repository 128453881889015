import { QUESTION_TYPES_VALUE, MIN_ANSWERS } from "constants/general";

export const answersCheck = (type, data) => {
  let isValid = false;
  let isntCorrect = true;

  if (data?.length < MIN_ANSWERS) {
    return true;
  }

  if(
    type === QUESTION_TYPES_VALUE.one_answer ||
    type === QUESTION_TYPES_VALUE.multiple_answers
  ) {
    data?.forEach((value) => {
      if (value.answer === "") {
        isValid = true;
      };
      if (value.is_correct && isntCorrect) {
        isntCorrect = false;
      }
    })
    return isValid || isntCorrect;
  } else if(
    type === QUESTION_TYPES_VALUE.pairing
  ) {
    data?.forEach((value) => {
      if(Array.isArray(value)) {
        value?.forEach((elem) => {
          if(elem.answer === "") {
            isValid = true;
          }
        })
      }
    })
    return isValid
  } else if (
    type === QUESTION_TYPES_VALUE.matching
  ) {
    data?.forEach((value) => {
      if (value.answer === "") {
        isValid = true;
      };
    })
    return isValid;
  }
}

export const isEmptyAllAnswers = ({ type, data }) => {
  let isValid = false;

  if(
    type === QUESTION_TYPES_VALUE.one_answer ||
    type === QUESTION_TYPES_VALUE.multiple_answers
  ) {
    data?.forEach((value) => {
      if (value.answer !== "") {
        isValid = true;
      };
    })
  } else if(
    type === QUESTION_TYPES_VALUE.pairing
  ) {
    data?.forEach((value) => {
      if(Array.isArray(value)) {
        value?.forEach((elem) => {
          if(elem.answer !== "") {
            isValid = true;
          }
        })
      }
    })
  } else if (
    type === QUESTION_TYPES_VALUE.matching
  ) {
    data?.forEach((value) => {
      if (value.answer !== "") {
        isValid = true;
      };
    })
  }
  return isValid;
}