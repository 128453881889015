import { createAsyncThunk } from "@reduxjs/toolkit";
import { diagnosticQuizApi } from "service/diagnosticQuizApi";
import { NOTIFY } from "constants/notifyType";
import Notify from "helpers/Notify";
import { defaultSortParams } from "constants/general";

export const getDiagnosticQuiz = createAsyncThunk(
  "/nordas/getDiagnosticQuiz",
  async (params, { rejectWithValue }) => {
    try {
      const res = await diagnosticQuizApi.getDiagnosticQuiz(params);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getDiagnosticQuizById = createAsyncThunk(
  "/nordas/getDiagnosticQuizById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await diagnosticQuizApi.getDiagnosticQuizById({ id });
      return res.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const createDiagnosticQuiz = createAsyncThunk(
  "/nordas/createDiagnosticQuiz",
  async ({ data, appliedFilterParams }, { rejectWithValue, dispatch }) => {
    const params = {
      topic_id: data.topic,
      category_id: data.category,
    };
    try {
      await diagnosticQuizApi.createDiagnosticQuiz(params);
      Notify(NOTIFY.type.success, NOTIFY.message.add);
      dispatch(
        getDiagnosticQuiz({ ...defaultSortParams, ...appliedFilterParams })
      );
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateDiagnosticQuiz = createAsyncThunk(
  "/nordas/updateDiagnosticQuiz",
  async ({ data }, { rejectWithValue, dispatch }) => {
    try {
      await diagnosticQuizApi.updateDiagnosticQuiz(data);
      Notify(NOTIFY.type.success, NOTIFY.message.update);
      dispatch(getDiagnosticQuiz(defaultSortParams));
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteDiagnosticQuiz = createAsyncThunk(
  "/nordas/deleteDiagnosticQuiz",
  async ({ id, page }, { rejectWithValue, dispatch }) => {
    try {
      await diagnosticQuizApi.deleteDiagnosticQuiz({ id });
      dispatch(getDiagnosticQuiz({ ...defaultSortParams, page }));
      Notify(NOTIFY.type.success, NOTIFY.message.delete);
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);

export const changeQuizActivation = createAsyncThunk(
  "/nordas/changeQuizActivation",
  async ({ id, page, body }, { rejectWithValue, dispatch }) => {
    try {
      await diagnosticQuizApi.changeQuizActivation({ id, body });
      dispatch(getDiagnosticQuiz({ ...defaultSortParams, page }));
      Notify(NOTIFY.type.success, NOTIFY.message.update);
    } catch (err) {
      rejectWithValue(err.message);
    }
  }
);
