import { createSlice } from "@reduxjs/toolkit";
import {
  getCategories,
  getCategoriesByTopicId,
  getCategoriesByParentId,
  getCategoriesById,
} from "actions/categoryActions";

const initialState = {
  data: {},
  categoriesByTopic: [],
  categoryById: {},
  categoriesByParentId: [],
  status: null,
  totalCount: 0,
};

export const categorySlice = createSlice({
  name: "diagnosticQuiz",
  initialState,
  reducers: {
    clearCategoriesList: (state) => {
      state.categoriesByTopic = [];
    },
    clearSelectedCategoryById: (state) => {
      state.categoryById = {};
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCategories.pending, (state) => {
        state.status = true;
      })
      .addCase(getCategories.fulfilled, (state, { payload }) => {
        state.data = payload.data.data;
        state.totalCount = payload.data.total;
        state.status = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.status = false;
      })
      .addCase(getCategoriesByTopicId.pending, (state) => {
        state.status = true;
      })
      .addCase(getCategoriesByTopicId.fulfilled, (state, { payload }) => {
        if (
          Array.isArray(payload.data) &&
          Array.isArray(state.categoriesByTopic.data) &&
          payload.isLoadMore
        ) {
          state.categoriesByTopic.data = [
            ...state.categoriesByTopic.data,
            ...payload.data,
          ];
        } else {
          state.categoriesByTopic = payload;
        }
      })

      .addCase(getCategoriesByTopicId.rejected, (state) => {
        state.status = false;
      })
      .addCase(getCategoriesByParentId.pending, (state) => {
        state.status = true;
      })
      .addCase(getCategoriesByParentId.fulfilled, (state, { payload }) => {
        state.categoriesByParentId = payload;
        state.status = false;
      })
      .addCase(getCategoriesByParentId.rejected, (state) => {
        state.status = false;
      })
      .addCase(getCategoriesById.pending, (state) => {
        state.status = true;
      })
      .addCase(getCategoriesById.fulfilled, (state, { payload }) => {
        state.categoryById = payload;
        state.status = false;
      })
      .addCase(getCategoriesById.rejected, (state) => {
        state.status = false;
      });
  },
});

export const { clearCategoriesList, clearSelectedCategoryById } =
  categorySlice.actions;

export default categorySlice.reducer;
