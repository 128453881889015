import styled from "@emotion/styled";
import { PaginationItem, TextField } from "@mui/material";
import { COLOR } from "constants/styleConstants";

export const StyledBox = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "20px",
  marginTop: "10px",
  paddingBottom: "16px",
});

export const StyledPaginationItem = styled(PaginationItem)(
  ({ page, selected }) => {
    return {
      backgroundColor: selected
        ? `${COLOR.primaryPurple} !important`
        : `${COLOR.white}`,
      color: selected ? `${COLOR.white} !important` : `${COLOR.black}`,
      "&:hover": {
        backgroundColor: `${COLOR.primaryPurple} !important`,
        color: `${COLOR.white} !important`,
      },
    };
  }
);

export const InnerBox = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const StyledTextField = styled(TextField)({
  backgroundColor: "white",
  width: "56px",
  height: "40px",
  boxSizing: "border-box",
  "& .MuiInputBase-input": {
    width: "100%",
    height: "100%",
    padding: "10px",
  },
  "& fieldset": {
    height: "calc(100% + 6px)",
    display: "flex",
    alignItems: "center",
    border: `2px solid ${COLOR.primaryPurple}`,
  },
  "&:hover fieldset": {
    borderColor: COLOR.primaryPurple,
  },
  "&.Mui-focused fieldset": {
    borderColor: COLOR.primaryPurple,
  },
});
