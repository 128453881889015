import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import { deleteQuestion, getQuestions } from "actions/questionsActions";
import { ComponentHeader } from "components";
import ModalWindow from "components/ModalWindow";
import { QuestionTable } from "components/QuestionTable";
import {
  CONSTANTS,
  STATUS_INCOMPLETE_VALUE,
  defaultSortParams,
} from "constants/general";
import FilterHelpers from "helpers/filter";
import { useSearchParams } from "hooks/useSearchParams";
import { clearQuestionById, setAppliedFilters } from "features/questionsSlice";
import { PATHNAME } from "constants/pathnames";
import { debounce } from "lodash";

export const DiagnosticQuizQuestions = () => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [page, setPage] = useState(state?.page || CONSTANTS.FIRST_PAGE);
  const [sort, setSort] = useState(defaultSortParams);
  const { search } = useSearchParams();

  const params = useRef({});
  const { topic_id, category_id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const questions = useSelector((state) => state.questions.questions);
  const totalCount = useSelector((state) => state.questions.total);
  const loading = useSelector((state) => state.questions.status);

  let appliedFilterParams = {};
  const appliedFiltersFromRedux = useSelector(
    (state) => state.questions.appliedFilters
  );

  const disableCreateButton = totalCount >= CONSTANTS.MAX_QUESTIONS_COUNT;

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleClearFilters,
    appliedFiltersToParams,
    appliedFilters,
  } = FilterHelpers(appliedFiltersFromRedux);

  const handleCreate = () => {
    dispatch(clearQuestionById());
    navigate("create");
  };

  const handleEdit = (e, id) => {
    e.stopPropagation();
    dispatch(clearQuestionById());
    navigate(`edit/${id}`);
  };

  useEffect(() => {
    dispatch(setAppliedFilters(appliedFilters));
  }, [appliedFilters, page, sort]);

  const debouncedFetchQuestions = useRef(
    debounce((params) => {
      dispatch(getQuestions({ params }));
    }, 300)
  ).current;

  useEffect(() => {
    appliedFilterParams = appliedFiltersToParams(appliedFiltersFromRedux);
    params.current = {
      search,
      page,
      ...sort,
      for_diagnostic_quiz: 1,
      filters: {
        category_id: appliedFilterParams.category_id || "",
        subject_id: category_id,
        topic_id,
        level: appliedFilterParams?.level || "",
        type: appliedFilterParams?.type || "",
        completed:
          appliedFilterParams?.status === STATUS_INCOMPLETE_VALUE
            ? STATUS_INCOMPLETE_VALUE
            : appliedFilterParams?.status || "",
      },
    };
    debouncedFetchQuestions(params.current);
  }, [appliedFiltersFromRedux, page, sort, search]);

  useEffect(() => {
    setPage(state?.page || CONSTANTS.FIRST_PAGE);
  }, [search]);

  return (
    <div className="table_page_body_container">
      <ComponentHeader
        create
        handleCreateEdit={handleCreate}
        back
        disableCreateButton={disableCreateButton}
        backPath={PATHNAME.diagnosticQuiz}
      />
      {open && (
        <ModalWindow
          open={open}
          setOpen={setOpen}
          id={deleteId}
          currentPage={page}
          message={CONSTANTS.DELETE_QUESTION_MESSAGE}
          deleteRequest={deleteQuestion}
          params={params.current}
        />
      )}
      <QuestionTable
        data={questions}
        setDeleteId={setDeleteId}
        setOpen={setOpen}
        totalCount={totalCount}
        handleApplyFilter={handleApplyFilter}
        handleRemoveFilter={handleRemoveFilter}
        handleClearFilters={handleClearFilters}
        appliedFilters={appliedFilters}
        page={page}
        setPage={setPage}
        sort={sort}
        setSort={setSort}
        handleEdit={handleEdit}
        isDiagnosticQuiz={true}
        loading={loading}
      />
    </div>
  );
};
