export const FILTER_CONSTANTS = {
  TOPIC_ID: "topic_id",
  TOPIC: "Section",
  CATEGORY: "Subject",
  CATEGORY_ID: "category_id",
  STATUS_KEY: "status",
  STATUS: "Status",
  ACTIVATION: "Activation",
  ACTIVATION_KEY: "is_active",
  TOPIC_KEY: "topic",
  CATEGORY_KEY: "category",
};

export const CATEGORIES_FILTER_KEYS = ["category_id", "subject_id"];

export const FILTER_VALUE_NAMES = {
  complete: "complete",
  active: "active",
};

export const FILTER_CHANGE = {
  topic_id: "Section",
  level: "Level",
  category_id: "Private Subject",
  subject_id: "Subject",
  status: "Status",
  type: "Type",
  is_active: "Activation",
};

export const FILTERS_VALUE_CHANGE = {
  topic_id: ({
    target,
    setSelected,
    newFilters,
    filterIndex,
    data,
    setPage,
  }) => {
    setSelected(target.value);
    setPage(1);
    const value =
      data.find((topic) => topic.id === newFilters[filterIndex]?.value).title ||
      "";
    return value;
  },
  category_id: ({ newFilters, filterIndex, data }) => {
    const value =
      (data &&
        data.find((category) => category.id === newFilters[filterIndex]?.value)
          ?.title) ||
      "";
    return value;
  },
  status: () => FILTER_VALUE_NAMES.complete,
  is_active: () => FILTER_VALUE_NAMES.active,
};
