export const pageCounts = [
  {
    label: "None",
    value: "",
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

export const isImage = (typeOrUrl) => {
  if (typeof typeOrUrl === "string") {
    return (
      typeOrUrl.startsWith("image/") || /\.(jpeg|jpg|gif|png)$/.test(typeOrUrl)
    );
  }
  return false;
};

export const isVideo = (typeOrUrl) => {
  if (typeof typeOrUrl === "string") {
    const videoMimeTypes = [
      "video/mp4",
      "video/mov",
      "video/avi",
      "video/wmv",
      "video/webm",
      "video/mkv",
      "video/x-msvideo", // For AVI
      "video/x-matroska", // For MKV
    ];

    const videoExtensions = /\.(mp4|mov|avi|wmv|webm|mkv)$/i;

    return (
      videoMimeTypes.includes(typeOrUrl.toLowerCase()) ||
      videoExtensions.test(typeOrUrl)
    );
  }
  return false;
};

export const determineVideoType = (url) => {
  const extension = url.split(".").pop().toLowerCase();
  switch (extension) {
    case "mp4":
      return "video/mp4";
    case "webm":
      return "video/webm";
    case "mkv":
      return "video/x-matroska";
    case "avi":
      return "video/x-msvideo";
    default:
      return "video/mp4";
  }
};
