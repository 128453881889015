import React, { useEffect, useState, useCallback } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";

export const EditorComponent = React.memo(({ data, setEditorValue }) => {
  const [editorState, setEditorState] = useState(() => {
    if (data) {
      const blocksFromHTML = convertFromHTML(data);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  });

  const onEditorStateChange = useCallback(
    (newEditorState) => {
      setEditorState(newEditorState);
      setEditorValue(
        draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
      );
    },
    [setEditorValue]
  );

  useEffect(() => {
    const currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (data && currentContent !== data) {
      const blocksFromHTML = convertFromHTML(data);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [data, editorState]);

  return (
    <Editor
      toolbar={{
        options: ["inline", "blockType", "list", "link", "textAlign"],
        inline: {
          inDropdown: false,
          options: ["bold", "italic", "underline", "strikethrough"],
        },
        textAlign: {
          inDropdown: false,
          options: ["left", "center", "right", "justify"],
        },
        list: { inDropdown: false, options: ["unordered", "ordered"] },
        link: { inDropdown: false, options: ["link"] },
      }}
      editorState={editorState}
      wrapperClassName="editor_wrapper"
      editorClassName="editor_container"
      onEditorStateChange={onEditorStateChange}
    />
  );
});
