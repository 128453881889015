import * as Yup from "yup";
import { CONSTANTS } from "constants/general";

export const subjectsSchema = [
  {
    id: "title",
    name: "title",
    label: CONSTANTS.TITLE,
    type: "text",
    placeholder: CONSTANTS.ENTER_TITLE,
    backend_field: "title",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
  {
    id: "quiz_type",
    name: "quiz_type",
    label: CONSTANTS.TYPE,
    type: "type",
    placeholder: CONSTANTS.SELECT_TYPE,
    backend_field: "quiz_type",
    validation: Yup.string().required(CONSTANTS.REQUIRED),
  },
];
