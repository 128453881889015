import Button from "@mui/material/Button";
import Notify from "helpers/Notify";
import { NOTIFY } from "constants/notifyType";
import { getError } from "helpers/getError";
import { CheckIcon, ClockIcon, DeleteIcon } from "components/icons";
import { DIAGNOSTIC_STATUS } from "service/constants";
import { StatusLabel } from "components/StatusLable";
import CustomizedSwitches from "components/toggle/activationToggle";
import { ACTIVE_TOOLTIP_MESSAGE_QUIZ, CONSTANTS } from "constants/general";
import { Tooltip } from "@mui/material";
import { IOSSwitch } from "pages/Questions/styledMui";

export const getColumns = (
  deleteDiagnosticQuiz,
  changeActivation,
  changeStatus
) => [
  {
    field: "title",
    headerName: "Subject",
    flex: 2,
    width: 100,
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "Added Date",
    flex: 1,
    width: 100,
    renderCell: (params) => {
      if (!params.value) return "-";
      const date = new Date(params.value);
      return date.toLocaleDateString();
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return (
        <div className="complete_status_field">
          <span onClick={(e) => e.stopPropagation()}>
            <IOSSwitch
              checked={row.completed}
              disabled={!row.can_completed}
              onChange={() => {
                changeStatus(row?.id, row?.completed);
              }}
            />
          </span>
          {row.completed ? (
            <StatusLabel
              className="completed_label"
              name={DIAGNOSTIC_STATUS.complete}
            >
              <CheckIcon width="20" height="20" />
            </StatusLabel>
          ) : (
            <StatusLabel
              className="incompleted_label"
              name={DIAGNOSTIC_STATUS.incomplete}
            >
              <ClockIcon width="20" height="20" />
            </StatusLabel>
          )}
        </div>
      );
    },
  },
  {
    field: "activation",
    headerName: "Activation",
    width: 100,
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      return (
        <div>
          <span onClick={(e) => e.stopPropagation()}>
            <CustomizedSwitches
              checked={row.completed && row?.is_active}
              disabled={!row.completed}
              onChange={() => changeActivation(row?.id, row?.is_active)}
              title={ACTIVE_TOOLTIP_MESSAGE_QUIZ}
            />
          </span>
        </div>
      );
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const handleDelete = async (event) => {
        event.stopPropagation();
        try {
          await deleteDiagnosticQuiz({ id: row.id });
        } catch (err) {
          Notify(NOTIFY.type.error, getError(err));
        }
      };

      return (
        <div>
          <Tooltip title={CONSTANTS.REMOVE} arrow>
            <Button
              className="action_button btn-neutral-first mx-1 rounded-sm shadow-none hover-scale-sm border-0 p-0 d-inline-flex align-items-center justify-content-center"
              variant="contained"
              color="secondary"
              onClick={handleDelete}
            >
              <DeleteIcon className="font-size-lg edit_icon" />
            </Button>
          </Tooltip>
        </div>
      );
    },
  },
];
