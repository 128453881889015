import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "features/auth";
import headerReducer from "features/headerSlice";
import sidebarReducer from "features/sidebarSlice";
import mainReducer from "features/mainSlice";
import footerReducer from "features/footerSlice";
import pageTitleReducer from "features/pageTitleSlice";
import aboutReducer from "features/aboutSlice";
import departmentReducer from "features/departmentSlice";
import subjectsReducer from "features/subjectSlice";
import usersReducer from "features/usersSlice";
import diagnosticQuizReducer from "features/diagnosticQuizSlice";
import fixedQuizReducer from "features/fixedQuizSlice";
import categoryReducer from "features/categorySlice";
import questionsReducer from "features/questionsSlice";

export const store = configureStore({
  reducer: {
    auth: adminReducer,
    sidebar: sidebarReducer,
    header: headerReducer,
    main: mainReducer,
    footer: footerReducer,
    page: pageTitleReducer,
    about: aboutReducer,
    topics: departmentReducer,
    users: usersReducer,
    subjects: subjectsReducer,
    diagnosticQuiz: diagnosticQuizReducer,
    fixedQuiz: fixedQuizReducer,
    category: categoryReducer,
    questions: questionsReducer,
  },
});
